import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { PlayerState } from '../../../store/types/playerTypes';
import { getPlayerStateSelector } from '../../../store/selectors/playerSelectors';
import classes from './LeftNav.module.css';
import fglToken from '../../../assets/images/general/fgl-token.png';
import medalImage from '../../../assets/images/general/icons/icon_trophy.png';
import profileImage from '../../../assets/images/general/icons/profile.png';
import rankingImage from '../../../assets/images/general/icons/rankings.png';
import battleImage from '../../../assets/images/general/icons/battle.png';
import buyImage from '../../../assets/images/general/icons/buy_cards.png';
import statsImage from '../../../assets/images/general/icons/stats.png';
import simulatorImage from '../../../assets/images/general/icons/simulation.png';
import historyImage from '../../../assets/images/general/icons/history.png';
import whitepaperImage from '../../../assets/images/general/icons/whitepaper.png';
import {ReactComponent as LogoutIcon} from '../../../assets/svg/exit.svg';
import { logout } from '../../../store/service/ualService';
import { setGameMode, setGeneralBusyMessage } from '../../../store/actions/gameActions';
import { getPlayerDataRequest } from '../../../store/actions/playerActions';
import {ReactComponent as RefreshIcon} from '../../../assets/svg/loop2.svg';
import {ReactComponent as DiscordIcon} from '../../../assets/svg/discord.svg';
import { GameState } from '../../../store/types/gameTypes';
import { getGameStateSelector } from '../../../store/selectors/gameSelectors';

import {ReactComponent as WeeklyMedalIcon} from '../../../assets/svg/medal.svg';

const LeftNav = () => { 
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
    
  const playerState:PlayerState = useSelector(getPlayerStateSelector);
  const gameState:GameState = useSelector(getGameStateSelector);

  let clicksEnabled:boolean = true;

  // Go to the atomic hub for this collection
  const buyCards = () => {
    window.open('https://wax.atomichub.io/market?collection_name=officialhero&data.rarity=%5B"common"%2C"uncommon"%2C"scarce"%2C"rare"%2C"epic"%2C"legendary"%2C"mythic"%2C"secret"%5D&order=asc&primary_chain=wax-mainnet&schema_name=series3.x&sort=price&symbol=WAX');
  };

  // Go buy some tokens on alcor
  const buyTokens = () => {
    window.open("https://wax.alcor.exchange/trade/fgl-fgltokenmgmt_wax-eosio.token")
  };

  // Whitepaper
  const viewWhitepaper = () => {
    window.open("https://www.youtube.com/watch?v=EvbYgL8qdHc")
  };

  // Help
  const clickHelp = () => {
    window.open("https://discord.com/invite/XPcBsVHY8W")
  };

  // Log the user out
  const clickLogout = () => {    
    // Call the logout function
    logout(history);    
  }

  // Change the game mode
  const clickGameMode = (mode:string) => {
    if (gameState.gameMode !== mode) {
      dispatch(setGameMode(mode));
    }
  }

  const refresh = () => {
    if (clicksEnabled){
      clicksEnabled=false;

      // Set a busy message
      dispatch(setGeneralBusyMessage("Synching data..."));

      dispatch(getPlayerDataRequest());      
    }
  }

  return (
    <div className={classes['left-nav']}>
      
      <div className={classes['player-block']}>
        {playerState.player && playerState.player.avatarId > 0 ?
          <img alt="Profile" src={'https://cdn.cosmicclash.io/avatars/avatar_' + playerState.player.avatarId + '.png'} className={classes['nav-icon-avatar']} />
        :
          <img alt="Profile" src={profileImage} className={classes['avatar-icon']} />
        }
        <div className={classes['player-info']}>
          <div className={classes['player-name']}>{playerState.player?.name}</div>
          <div className={classes['player-stats']}>
            <div className={classes['player-stats-box']}>            
              <img alt="FGL Tokens" src={fglToken} className={classes['token-image']} />                        
              {(playerState.player?.tokens)?.toFixed(0)}              
            </div>
            {gameState.gameMode === 'seasonal' ?
              <div className={classes['player-stats-box']}>                          
                <img alt="Medals" src={medalImage} className={classes['medal-image']} />            
                {playerState.player?.medals}&nbsp;<span className={classes['rank-text']}>(#{playerState.player?.seasonalRank})</span>
              </div> 
            :
              <div className={classes['player-stats-box']}>                          
                <WeeklyMedalIcon className={classes['medal-icon']} />           
                {playerState.player?.weeklyMedals}&nbsp;<span className={classes['rank-text']}>(#{playerState.player?.weeklyRank})</span>
              </div> 
            }
           
          </div>
        </div>
      </div>

      {/*
      <fieldset className={classes['mode-block']}>
        <legend className={classes['mode-title']}>Tournament</legend>
        <div className={classes['mode-radio-group']}>
          <div className={classes['mode-radio-holder']} onClick={() => clickGameMode('seasonal')}>          
            {gameState.gameMode === 'seasonal' ?
              <RadioCheckedIcon className={classes['radio-icon-gold']} />
            :
              <RadioUnCheckedIcon className={classes['radio-icon-gold']} />
            }          
            <div className={classes['mode-radio-text-gold']}>GOLD</div>
          </div>
          <div className={classes['mode-radio-holder']} onClick={() => clickGameMode('weekly')}>
            {gameState.gameMode === 'weekly' ?
              <RadioCheckedIcon className={classes['radio-icon-bronze']} />
            :
              <RadioUnCheckedIcon className={classes['radio-icon-bronze']} />
            }
            <div className={classes['mode-radio-text-bronze']}>BRONZE</div>
          </div>
        </div>
      </fieldset>   
      */}

      <div onClick={() => refresh()} className={classes['nav-block-outline']}>
        <RefreshIcon className={classes['refresh-icon-white']} />        
        <div className={classes['nav-title-white']}>Synchronize</div>
      </div> 
                      
      <Link className={classes['link']} to="/battle" >
        <div className={classes['nav-block']}>
          <img alt="Battle" src={battleImage} className={classes['nav-icon']} />
          <div className={location.pathname === '/' || location.pathname === '/battle' ? [classes['nav-title'],classes['nav-title-active']].join(' ') : classes['nav-title']}>
            Battle
          </div> 
        </div>
      </Link>

      <Link className={classes['link']} to="/rankings" >
        <div className={classes['nav-block']}>
          <img alt="Rankings" src={rankingImage} className={classes['nav-icon']} />
          <div className={location.pathname === '/rankings' ? [classes['nav-title'],classes['nav-title-active']].join(' ') : classes['nav-title']}>
            Rankings
          </div> 
        </div>
      </Link>
          
      <Link className={classes['link']} to="/stats" >
        <div className={classes['nav-block']}>
          <img alt="Stats" src={statsImage} className={classes['nav-icon']} />
          <div className={location.pathname === '/stats' ? [classes['nav-title'],classes['nav-title-active']].join(' ') : classes['nav-title']}>
            Card Stats
          </div> 
        </div>
      </Link>

      <Link className={classes['link']} to="/history" >
        <div className={classes['nav-block']}>
          <img alt="Rankings" src={historyImage} className={classes['nav-icon']} />
          <div className={location.pathname === '/history' ? [classes['nav-title'],classes['nav-title-active']].join(' ') : classes['nav-title']}>
            History
          </div> 
        </div>
      </Link>

      {playerState.player && playerState.player.isAdmin ?
      <Link className={classes['link']} to="/simulator" >
        <div className={classes['nav-block']}>
          <img alt="Simulator" src={simulatorImage} className={classes['nav-icon']} />
          <div className={location.pathname === '/simulator' ? [classes['nav-title'],classes['nav-title-active']].join(' ') : classes['nav-title']}>
            Simulator
          </div> 
        </div>
      </Link>
      : null }

      <div className={classes['nav-block-section-top']}>
      <div className={classes['nav-title-bold']}>External Links:</div>
      </div>
      
      <div onClick={() => viewWhitepaper()} className={classes['nav-block']}>
        <img alt="Whitepaper" src={whitepaperImage} className={classes['nav-icon']} />
        <div className={classes['nav-title']}>Tutorial</div>
      </div>

      <div onClick={() => buyCards()} className={classes['nav-block']}>
        <img alt="Buy Ships" src={buyImage} className={classes['nav-icon']} />
        <div className={classes['nav-title']}>Buy Cards</div>
      </div>
       
      <div onClick={() => buyTokens()}className={classes['nav-block']}>
        <img alt="Buy Tokens" src={fglToken} className={classes['token-icon']} />
        <div className={classes['nav-title']}>Buy Tokens</div>
      </div>  

      <div onClick={() => clickHelp()}className={classes['nav-block']}>
        <DiscordIcon className={classes['discord-icon']} />  
        <div className={classes['nav-title']}>Help</div>
      </div>
      
      <div onClick={() => clickLogout()} className={classes['logout-block']}>
        <LogoutIcon className={classes['logout-icon']} />      
        <div className={classes['nav-title']}>Logout</div>
      </div>           
    </div>
  );
};

export default LeftNav;
