import classes from './BattleRow.module.css';
import { useState } from 'react';

import { IPlayerBattleLogEntry } from '../../../store/types/playerTypes';
import fglToken from '../../../assets/images/general/fgl-token.png';
import projectileIcon from '../../../assets/images/general/icons/weapon-projectile.png';
import armorIcon from '../../../assets/images/general/icons/armor.png';
import shieldsIcon from '../../../assets/images/general/icons/shields.png';

import {ReactComponent as ArrowUp} from '../../../assets/svg/arrow-up.svg';
import {ReactComponent as ArrowDown} from '../../../assets/svg/arrow-down.svg';
import {ReactComponent as ZoomIn} from '../../../assets/svg/zoom-in.svg';
import {ReactComponent as ZoomOut} from '../../../assets/svg/zoom-out.svg';

import goldIcon from '../../../assets/images/general/512x512-gold-animated.gif';
import bronzeIcon from '../../../assets/images/general/512x512 bronze.png';
import BattleCardDisplay from './BattleCardDisplay/BattleCardDisplay';

interface BattleRowProps {
  battleResult:IPlayerBattleLogEntry;   
  rowIndex:number;
}

const BattleRow = ( { battleResult, rowIndex }:BattleRowProps ) => {     
    const [showSimpleView, setShowSimpleView] = useState(true);
    
    let localBattleDate:Date = new Date(battleResult.date);

    const toggleView = () => {
        if (showSimpleView) {
            setShowSimpleView(false);
        } else {
            setShowSimpleView(true);
        }
    }
    
    return (
      
        <div className={classes['battle-row']}>
            <div onClick={() => toggleView()} className={classes['battle-expand']}>                    
                {showSimpleView ?
                    <ZoomIn className={classes['zoom-icon']} />
                :
                    <ZoomOut className={classes['zoom-icon']} />
                }
            </div>
            {battleResult.battleType === 0 ?
                <div className={classes['battle-type']}>W</div>
            : battleResult.battleType === 1 ?
                <div className={classes['battle-type']}>S</div>
            : battleResult.battleType === 2 ?
                <img alt="Gold" src={goldIcon} className={classes['token-image']} />                  
            : battleResult.battleType === 3 ?
                <img alt="Bronze" src={bronzeIcon} className={classes['token-image']} /> 
            : null }
                                                
            {battleResult.bPaid ?
                <img alt="FGL Tokens" src={fglToken} className={classes['token-image']} />  
                :
                <img alt="FGL Tokens" src={fglToken} className={classes['token-image-bw']} />  
            }   

            {battleResult.powerBuff === 3 ?
                <img alt="Damage" src={projectileIcon} className={classes['token-image']} />  
            : battleResult.powerBuff === 2 ?
                <img alt="Shields" src={shieldsIcon} className={classes['token-image']} />  
            : battleResult.powerBuff === 1 ?
                <img alt="Armor" src={armorIcon} className={classes['token-image']} />  
            : <div className={classes['placeholder']}>&nbsp;</div>
            }   
            
            <div className={classes['battle-date']}>{localBattleDate.toLocaleDateString('en-US')}&nbsp;{localBattleDate.toLocaleTimeString()}</div>            
            
            {showSimpleView ?
                <div className={classes['simple-view']}>
                    <div className={classes['battle-vs']}>vs</div>
                    {battleResult.defenderAvatar > 0 ?
                        <img src={'https://cdn.cosmicclash.io/avatars/avatar_' + battleResult.defenderAvatar + '.png'} className={classes['token-image']} />
                    : null }    
                    <div className={classes['battle-name']}>              
                        {battleResult.defender}                
                    </div>
                    {battleResult.bRematch ?
                        <div className={classes['battle-rematch']}>REMATCH</div>
                    : null
                    }
                </div>
            : 
                <div className={classes['detailed-view']}>
                    <div className={classes['enemy-details']}>
                        <div className={classes['battle-vs']}>vs</div>
                        {battleResult.defenderAvatar > 0 ?
                            <img src={'https://cdn.cosmicclash.io/avatars/avatar_' + battleResult.defenderAvatar + '.png'} className={classes['token-image']} />
                        : null }    
                        <div className={classes['battle-name']}>              
                            {battleResult.defender}                
                        </div>
                        {battleResult.bRematch ?
                            <div className={classes['battle-rematch']}>REMATCH</div>
                        : null
                        }
                    </div>
                    <div className={classes['battle-fleets']}>
                        <div className={classes['battle-fleet']}>
                            {battleResult.attackerCards.map( ( card, index ) => (
                                <BattleCardDisplay key={index} rowIndex={rowIndex} cardIndex={index} 
                                    card={card} />
                            ))}                      
                        </div>
                        <div className={classes['battle-fleet']}>
                            {battleResult.defenderCards.map( ( card, index ) => (
                                <BattleCardDisplay key={index} rowIndex={rowIndex} cardIndex={index} 
                                card={card} />
                            ))}                      
                        </div>
                    </div>
                </div>
            }

            <div className={classes['battle-right']}>
                {battleResult.originalRank - battleResult.newRank < 0 ?
                    <div className={classes['battle-result']}>
                        <ArrowDown className={classes['arrow-down']} />
                        {Math.abs(battleResult.originalRank - battleResult.newRank)}
                    </div>
                : battleResult.originalRank - battleResult.newRank > 0 ?
                    <div className={classes['battle-result']}>
                        <ArrowUp className={classes['arrow-up']} />
                        {Math.abs(battleResult.originalRank - battleResult.newRank)}
                    </div>
                :
                    <div className={classes['battle-result']}>No Change</div>
                }
                {battleResult.bWin ?
                    <div className={classes['battle-win']}>WIN</div>
                :
                    <div className={classes['battle-loss']}>LOSS</div>
                }                                                               
            </div>                        
        </div>
    );
};

export default BattleRow;
