import classes from './PlayBattle.module.css';

import LeftNav from "../General/LeftNav/LeftNav";
import FleetManagementInterface from "../General/FleetManagementInterface/FleetManagementInterface";

const PlayBattle = () => {  
  return (    
    <div className={classes['main-content']}>
      <LeftNav />

      <div className={classes['center-content']}>
        <FleetManagementInterface currentModule="battle" />
      </div>
    </div>
  );
};

export default PlayBattle;