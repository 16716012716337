import classes from './Modal.module.css';
import Backdrop from './Backdrop';

const Modal = ({ className, show, children }: ModalProps) => {
    return (
        <>
            <Backdrop show={show} />
            <div
                className={className || classes.modal}
                style={{
                    transform: show ? 'translateY(0)' : 'translateY(-200vh)',
                    opacity: show ? '1' : '0'
                }}>
                {children}
            </div>
            
        </>
    )    
}

type ModalProps = {
  className?: string,
  show: boolean,
  children: JSX.Element
}

export default Modal;