import classes from './Announcement.module.css';
import {ReactComponent as CrossIcon} from '../../../assets/svg/cross.svg';
import { useDispatch, useSelector } from 'react-redux';
import { PlayerState } from '../../../store/types/playerTypes';
import { getPlayerStateSelector } from '../../../store/selectors/playerSelectors';
import { clearAnnouncement } from '../../../store/actions/playerActions';


const Announcement = () => {    
  const dispatch = useDispatch();
  
  const playerState:PlayerState = useSelector(getPlayerStateSelector);

  // Close this popup
  const closePopup = () => {
    dispatch(clearAnnouncement());
  }
    
  return (
    <div className={classes['main-content']} onClick={() => closePopup()}>           
      <div className={classes['popup-content-image']}>                                       
        <div className={classes['popup-title']}>{playerState.announcement ? playerState.announcement.title : null}</div>                       

        <div className={classes['content-section']}>        
          {playerState.announcement ? 
            playerState.announcement.rows.map( ( content, index ) => (
              <div key={index} className={classes['message-details-row']}>{content}</div>
            ))
          : null }
        </div>
                       
        <div className={classes['button-section']}>
          Click anywhere to close this panel
        </div>
      </div>      
    </div>
  );  
}

export default Announcement;