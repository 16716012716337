import { useDispatch } from 'react-redux';
import classes from './MobileWaxErrorModal.module.css';
import { setWaxErrorMessage } from '../../../store/actions/gameActions';
import {ReactComponent as CrossIcon} from '../../../assets/svg/cross.svg';
import { useHistory, useLocation } from 'react-router';

interface WaxErrorProps {
  message: string;
}

const MobileWaxErrorModal = ( { message }:WaxErrorProps ) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
    
  const clickOK = () => {
    // Hide this modal
    dispatch(setWaxErrorMessage("")); 

    // If we are on the show-battle screen, go home
    if (location.pathname === '/show-battle') {
      history.push("/");
    }
  }

 return(
  <div className={classes['main-content']}>           
    <div className={classes['popup-content-image']}>         
      <div onClick={() => clickOK()} className={classes['cross-holder']}>        
        <CrossIcon className={classes['cross-icon']} />        
      </div>                       
      <div className={classes['popup-title']}>WAX Client Error</div>  
      <div className={classes['message-area']}>            
        <div className={classes['message-row']}>An error has occurred attempting to sign + send your FGL token transfer.  Please check the following:</div>      
        <div className={classes['message-row']}>1.  You have enough CPU+Net staked to your account.  You also need a small amount of RAM the first time you send an FGL token.</div>      
        <div className={classes['message-row']}>2.  Check that you are logged in to your Wax Cloud Wallet</div>      
        <div className={classes['message-row']}>3.  No signing popup?  Make sure your browser did not block it. Check for an icon in the address bar of your browser for a blocked popup.</div>      
        <div className={classes['error-text']}>Error Details: {message}</div>  
      </div>    
      <div className={classes['button-section']}>                   
        <div onClick={() => clickOK()} className={classes['return-button']}>Close</div>
      </div>
    </div>
  </div>
  );
};

export default MobileWaxErrorModal;