import { useDispatch } from 'react-redux';
import classes from './MobileGeneralErrorModal.module.css';
import { clearGameError, setGeneralBusyMessage, setGeneralError } from '../../../store/actions/gameActions';
import {ReactComponent as CrossIcon} from '../../../assets/svg/cross.svg';
import { clearPlayerError } from '../../../store/actions/playerActions';
import { clearWaxError } from '../../../store/actions/waxActions';

interface GeneralErrorProps {
  message: string;
}

const MobileGeneralErrorModal = ( { message }:GeneralErrorProps ) => {
  const dispatch = useDispatch();
    
  const clickOK = () => {
    // Hide this modal
    dispatch(setGeneralError("")); 

     // Also clear out any other basic errors
     dispatch(clearGameError());
     dispatch(clearPlayerError());
     dispatch(clearWaxError());
     dispatch(setGeneralBusyMessage(""));
  }

 return(
  <div className={classes['main-content']} onClick={() => clickOK()}>           
    <div className={classes['popup-content-image']}>                                    
      <div className={classes['popup-title']}>Error</div>                             
      <div className={classes['message-text']}>{message}</div>           
    </div>
  </div>
  );
};

export default MobileGeneralErrorModal;