import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classes from './FleetManagementInterface.module.css';

import { setGeneralBusyMessage, setGeneralError, showBattleConfirmation, showBuyinConfirmation, viewCardDetails } from "../../../store/actions/gameActions";
import { getGameStateSelector } from "../../../store/selectors/gameSelectors";
import { GameState, IGame, ILeaderboard } from "../../../store/types/gameTypes";
import { ICardPreset, ICard, PlayerState } from "../../../store/types/playerTypes";
import { getPlayerStateSelector } from "../../../store/selectors/playerSelectors";
import {ReactComponent as TimeIcon} from '../../../assets/svg/clock.svg';
import {ReactComponent as TrashIcon} from '../../../assets/svg/bin.svg';
import LeaderboardRules from "../../LeaderboardDisplay/LeaderboardRules/LeaderboardRules";
import LeaderboardDisplay from "../../LeaderboardDisplay/LeaderboardDisplay";
import { presetDelete, presetSave } from "../../../store/actions/playerActions";
import ActiveCardsBlock from "../ActiveCardsBlock/ActiveCardsBlock";
import CardSection from "../CardSection/CardSection";

import { rarityToIndex, indexToRarity } from "../../../hoc/CommonFunctions";

import heroIcon from '../../../assets/images/general/icon_hero.png';
import villainIcon from '../../../assets/images/general/icon_villain.png';

interface FleetManagementProps {
  currentModule:string;
}

const FleetManagementInterface = ( {currentModule}:FleetManagementProps ) => {  
  const dispatch = useDispatch();  

  const [timeLeft, setTimeLeft] = useState(0);
  const [formattedTimeLeft, setFormattedTimeLeft] = useState("00:00:00");

  const gameState:GameState = useSelector(getGameStateSelector);
  const playerState:PlayerState = useSelector(getPlayerStateSelector);

  const [localActiveHeroes, setLocalActiveHeroes] = useState<ICard[] | []>([]);
  const [localActiveHeroRarities, setLocalActiveHeroRarities] = useState<string[] | []>([]);
  const [localActiveVillains, setLocalActiveVillains] = useState<ICard[] | []>([]);
  const [localActiveVillainRarities, setLocalActiveVillainRarities] = useState<string[] | []>([]);
  const [totalHeroPoints, setTotalHeroPoints] = useState(0);  
  const [totalVillainPoints, setTotalVillainPoints] = useState(0);  
  const [signedUp, setSignedUp] = useState(false);
  
  const [formattedBattleReset, setFormattedBattleReset] = useState("");
  const [leaderboardIndex, setLeaderboardIndex] = useState(0);
  const [showBattleButtons, setShowBattleButtons] = useState(true);

  const [currentPresetIndex, setCurrentPresetIndex] = useState(0);
  const [presetName, setPresetName] = useState("");  

  let clicksEnabled:boolean = true;

  // Whenever we get new cards from the server, overwrite the local one
  useEffect(() => {   
    if (playerState.player 
      && playerState.player.currentHeroes && playerState.player.currentHeroRarities && playerState.player.currentVillains && playerState.player.currentVillainRarities 
      && playerState.player.weeklyHeroes && playerState.player.weeklyHeroRarities && playerState.player.weeklyVillains && playerState.player.weeklyVillainRarities       
      && gameState.game) {                   

      // Which cards + rarities to us?
      let inputHeroes:any[] = playerState.player.currentHeroes;
      let inputHeroRarities:string[] = playerState.player.currentHeroRarities;
      let inputVillains:any[] = playerState.player.currentVillains;
      let inputVillainRarities:string[] = playerState.player.currentVillainRarities;

      if (gameState.gameMode === 'weekly') {
        inputHeroes = playerState.player.weeklyHeroes;
        inputHeroRarities = playerState.player.weeklyHeroRarities;
        inputVillains = playerState.player.weeklyVillains;
        inputVillainRarities = playerState.player.weeklyVillainRarities;
      }

      // If there is a practice deck set up, use that
      if (playerState.practiceHeroes && playerState.practiceHeroes.length > 0 &&
        ((gameState.gameMode === 'weekly' && !playerState.practiceSeasonal) ||
        (gameState.gameMode !== 'weekly' && playerState.practiceSeasonal))) {
          
        inputHeroes = playerState.practiceHeroes;
        inputHeroRarities = playerState.practiceHeroRarities;
        inputVillains = playerState.practiceVillains;
        inputVillainRarities = playerState.practiceVillainRarities;
      }
            
      // Setup all the points and stuff
      resetFleet(inputHeroes, inputHeroRarities, inputVillains, inputVillainRarities);      
    }

  }, [ 
      playerState.player?.currentHeroes,playerState.player?.currentHeroRarities, 
      playerState.player?.currentVillains,playerState.player?.currentVillainRarities, 
      playerState.player?.weeklyHeroes,playerState.player?.weeklyHeroRarities, 
      playerState.player?.weeklyVillains,playerState.player?.weeklyVillainRarities, 
      gameState.gameMode, gameState.game ]);

  // Keep track of if we have signed up or not
  useEffect(() => {           
    if (gameState.gameMode === 'seasonal' && playerState.player?.boughtInGold) {
      setSignedUp(true); 
    } else if (gameState.gameMode === 'weekly' && playerState.player?.boughtInBronze) {
      setSignedUp(true); 
    } else {
      setSignedUp(false); 
    }
         
  }, [ gameState.gameMode, playerState.player, playerState.player?.boughtInBronze, playerState.player?.boughtInGold ]);

  // If battle times change, re-update
  useEffect(() => {    
    if (playerState.player?.nextBattle) {
      
      // Update the time remaining
      let timeLeft:number = playerState.player?.nextBattle - Date.now();      
      setTimeLeft(timeLeft);        
    }
      
  }, [ playerState.player?.nextBattle ]);

  // Handle battle button visibility
  useEffect(() => {    
    // Default to visibile
    let bVisible:boolean = true;
    if (gameState.game && playerState.player) {
      let leaderboard:ILeaderboard;
      let boughtIn:boolean = false;
      if (gameState.gameMode === 'seasonal') {
        leaderboard = gameState.game?.leaderboards[0];
        boughtIn = playerState.player?.boughtInGold;
      } else {
        leaderboard = gameState.game?.leaderboards[1];
        boughtIn = playerState.player?.boughtInBronze;
      } 

      // Conditions for hiding during tournament season
      
      // Hasn't started yet or haven't bought in
      if (leaderboard.startTime > Date.now() || !boughtIn) {
        bVisible = false;
      }

      // Tournament is over
      if (Date.now() > leaderboard.endTime) {
        bVisible = false;
      }      
    }

    setShowBattleButtons(bVisible);
      
  }, [ gameState.gameMode, gameState.game?.leaderboards ]);

  // Update the current leaderbaord index with the mode
  useEffect(() => {    
    if (gameState.gameMode === 'seasonal') {
      setLeaderboardIndex(0);
    } else {
      setLeaderboardIndex(1);
    } 
      
  }, [ gameState.gameMode ]);

  // If last battle reset changes, update the time
  useEffect(() => {    
    if (gameState.game?.nextBattleReset) { 
      let nextReset:number = gameState.game?.nextBattleReset;
      let resetDate:string = new Date(nextReset).toLocaleTimeString();
      setFormattedBattleReset(resetDate);    
    }
      
  }, [ gameState.game?.nextBattleReset ]);

  useEffect(() => {
    let timer:any = setTimeout(updateTimeRemaining, 1000);
        
    // Clear timeout if the component is unmounted
    return () => {
      if (timer)
        clearTimeout(timer);
    }
  });

  const resetFleet = (heroCards:number[], heroRarities:string[], villainCards:number[], villainRarities:string[]) => {
    if (playerState.player && gameState.game) {       
      // Build the set of heroes
      let tempCards:any[] = [];
      let tempCard:any;
      let points:number = 0;

      for (let i = 0; i < heroCards.length; i++) {
        if (heroCards[i] > 0) {
          // Find this card in our card pool        
          for (let j = 0; j < playerState.player?.cardPool.length; j++) {
            if (playerState.player?.cardPool[j].id === heroCards[i]) {
              tempCard = JSON.parse(JSON.stringify(playerState.player?.cardPool[j]));
              tempCard.rarity = heroRarities[i];
              tempCards.push(tempCard);
              
                                          
              points += rarityToIndex(heroRarities[i])+1;                                                       
            }
          }
        } else {
          tempCards.push(null);          
        }
      }

      setLocalActiveHeroes(tempCards);
      setLocalActiveHeroRarities(heroRarities);
      setTotalHeroPoints(points);
      
      // Villains
      points = 0;
      tempCards = [];
      for (let i = 0; i < villainCards.length; i++) {
        if (villainCards[i] > 0) {
          // Find this card in our card pool        
          for (let j = 0; j < playerState.player?.cardPool.length; j++) {
            if (playerState.player?.cardPool[j].id === villainCards[i]) {
              tempCard = JSON.parse(JSON.stringify(playerState.player?.cardPool[j]));
              tempCard.rarity = villainRarities[i];
              tempCards.push(tempCard);
                                          
              points += rarityToIndex(villainRarities[i])+1;                                                       
            }
          }
        } else {
          tempCards.push(null);          
        }
      }

      setLocalActiveVillains(tempCards);
      setLocalActiveVillainRarities(villainRarities);
      setTotalVillainPoints(points);      
    }
  }

  const updateTimeRemaining = () => {
    if (timeLeft > 0 && playerState.player) {
      
      let timeLeft:number = playerState.player?.nextBattle - Date.now();           
      if (timeLeft < 0) {
        timeLeft = 0;
      }
      setTimeLeft(timeLeft);
      
      setFormattedTimeLeft(        
        formatTime(Math.floor((timeLeft / 1000 / 60) % 60).toString()) + ":" + 
        formatTime(Math.floor((timeLeft / 1000) % 60).toString())
      )      
    }
  };

  const formatTime = (time:string) => {
    if (time.length === 1) {
      return "0" + time;
    } else {
      return time;
    }
  }

  // Assign a card to the next open spot
  const addToSet = ( card:ICard ) => {
    if (clicksEnabled && gameState.game){
      clicksEnabled=false;

      let points:number = totalHeroPoints;
      let cardSet:ICard[] = localActiveHeroes;
      let raritySet:string[] = localActiveHeroRarities;
      if (card.faction === "villain") {
        cardSet = localActiveVillains;
        raritySet = localActiveVillainRarities;
        points = totalVillainPoints;
      }

      // Do we have a spot available?
      let nextSlot:number = -1;
      for (let i = 0; i < cardSet.length; i++) {
        if (!cardSet[i]) {
          nextSlot = i;
          break;
        }
      }

      // Make sure this card isn't already in there
      let bFound:boolean = false;
      for (let i = 0; i < cardSet.length; i++) {
        if (cardSet[i] && cardSet[i].id === card.id) {
          bFound = true;
          break;
        }
      }

      if (nextSlot > -1 && !bFound) {
        // Set the state
        let tempCards:ICard[] = [...cardSet];
        tempCards[nextSlot] = card;
        let tempRarities:string[] = [...raritySet];
        tempRarities[nextSlot] = card.rarity;
                       
        if (card.faction === 'hero') {
          setLocalActiveHeroes(tempCards);
          setLocalActiveHeroRarities(tempRarities);
          setTotalHeroPoints(points + rarityToIndex(card.rarity)+1);
        } else {
          setLocalActiveVillains(tempCards);
          setLocalActiveVillainRarities(tempRarities);
          setTotalVillainPoints(points + rarityToIndex(card.rarity)+1);
        }                

      } else if (!bFound) {
        // No slots available!
        dispatch(setGeneralError("No slots available, please remove a card first"));

        // Clicks OK again
        clicksEnabled = true;
      } else {
        // Ship already in fleet
        dispatch(setGeneralError("This card is already in your hand"));

        // Clicks OK again
        clicksEnabled = true;
      }
    }   
  }

  // Remove a card from the active set (set its slot to 0)
  const removeFromSet = ( slotId:number, card:ICard ) => {
    if (clicksEnabled && gameState.game){
      clicksEnabled=false;

      let points:number = totalHeroPoints;
      let tempCards:any[] = [...localActiveHeroes];
      let tempRarities:any[] = [...localActiveHeroRarities];
      if (card.faction === 'villain') {
        tempCards = [...localActiveVillains];
        tempRarities = [...localActiveVillainRarities];
        points = totalVillainPoints;
      }

      // Clear the slot
      tempCards[slotId] = null;
      tempRarities[slotId] = "";

      // Update the state
      if (card.faction === 'hero') {
        setLocalActiveHeroes(tempCards);
        setLocalActiveHeroRarities(tempRarities);
        setTotalHeroPoints(points - (rarityToIndex(card.rarity)+1));
      } else {
        setLocalActiveVillains(tempCards);
        setLocalActiveVillainRarities(tempRarities);
        setTotalVillainPoints(points - (rarityToIndex(card.rarity)+1));
      }             
    }
  }

  // Remove all cards
  const removeAllFromSet = () => {
    if (clicksEnabled && gameState.game){
      clicksEnabled=false;
      
      let tempCards:any[] = [null,null,null,null,null];
      let tempRarities:string[] = ["","","","",""];      
      
      // Zero out everything      
      setLocalActiveHeroes(tempCards);
      setLocalActiveHeroRarities(tempRarities);
      setTotalHeroPoints(0);    
      setLocalActiveVillains(tempCards);
      setLocalActiveVillainRarities(tempRarities);
      setTotalVillainPoints(0);
      
    }
  }


  // Move a card left or right in the array of cards
  const moveWithinSet = ( slotId:number, bLeft:boolean, card:ICard ) => {
    if (clicksEnabled){
      clicksEnabled=false;

      let tempCards:any[] = [...localActiveHeroes];
      let tempRarities:any[] = [...localActiveHeroRarities];
      if (card.faction === 'villain') {
        tempCards = [...localActiveVillains];
        tempRarities = [...localActiveVillainRarities];       
      }

      let newSlotId:number = slotId;
      if (bLeft) {
        newSlotId--;
        if (newSlotId < 0) {
          newSlotId = 4;
        }
      } else {
        newSlotId++;
        if (newSlotId > 4) {
          newSlotId = 0;
        }
      }
      
      tempCards.splice(newSlotId, 0, tempCards.splice(slotId, 1)[0]);
      tempRarities.splice(newSlotId, 0, tempRarities.splice(slotId, 1)[0]);

      if (card.faction === 'hero') {
        setLocalActiveHeroes(tempCards);
        setLocalActiveHeroRarities(tempRarities);        
      } else {
        setLocalActiveVillains(tempCards);
        setLocalActiveVillainRarities(tempRarities);        
      } 
    }
  }

  // Show detailed info about a card
  const showInfo = ( card:ICard ) => {    
    if (clicksEnabled){
      clicksEnabled=false;
      
      dispatch(viewCardDetails(card));
    }
  }

  // Change the rarity on a card
  const updateRarity = ( card:ICard, e:any ) => {        
    // Find this card in our cards
    let slot:number = 0;
    
    let tempCards:any[] = [...localActiveHeroes];
    let tempRarities:any[] = [...localActiveHeroRarities];
    if (card.faction === 'villain') {
      tempCards = [...localActiveVillains];
      tempRarities = [...localActiveVillainRarities];       
    }

    for (let i = 0; i < tempCards.length; i++) {
      if (tempCards[i] && tempCards[i].id === card.id) {
        slot = i;
      }      
    }

    // Update the rarity
    let rarities:string[] = [...tempRarities];
    rarities[slot] = indexToRarity(e.target.selectedIndex+1);
        
    // Reset the points
    let newPoints:number = 0;
    for (let i = 0; i < tempCards.length; i++) {
      newPoints += rarityToIndex(rarities[i])+1;
    }

    // Update it    
    if (card.faction === 'hero') {      
      setLocalActiveHeroRarities(rarities);  
      setTotalHeroPoints(newPoints);      
    } else {      
      setLocalActiveVillainRarities(rarities);        
      setTotalVillainPoints(newPoints);      
    } 
  }

  // TO BATTLE!
  const clickDeployForBattle = (rematch:boolean) => {
    if (clicksEnabled){
      clicksEnabled=false;

      // Make sure they have 5 heroes + 5 villains set
      let bError:boolean = false;
      for (let i = 0; i < localActiveHeroes.length; i++) {
        if (!localActiveHeroes[i]) {
          bError = true;
        }
      }
      for (let i = 0; i < localActiveVillains.length; i++) {
        if (!localActiveVillains[i]) {
          bError = true;
        }
      }

      if (bError) {
        dispatch(setGeneralError("Please assign all 5 Heroes AND Villains"));
        return;
      }   
             
      // Make sure all the ships in the fleet fit the params if weekly
      if (gameState.game) {         
        // Also check if this is a total points week
        if (gameState.game.leaderboards[leaderboardIndex].cardPoints > 0  && totalHeroPoints > gameState.game.leaderboards[leaderboardIndex].cardPoints) {
          dispatch(setGeneralError("Your Heroes are too powerful!  Please reduce the number of points you are using."));
          return;
        }   
        if (gameState.game.leaderboards[leaderboardIndex].cardPoints > 0  && totalVillainPoints > gameState.game.leaderboards[leaderboardIndex].cardPoints) {
          dispatch(setGeneralError("Your Villains are too powerful!  Please reduce the number of points you are using."));
          return;
        }       
      }
      
      // Make an array of Hero IDs
      let tempHeroes:number[] = [];
      let tempHeroRarities:string[] = [];
      for (let i = 0; i < localActiveHeroes.length; i++) {
        if (localActiveHeroes[i]) {         
          tempHeroes.push(localActiveHeroes[i].id);                    
          tempHeroRarities.push(localActiveHeroRarities[i]);
        } else {
          tempHeroes.push(0);
          tempHeroRarities.push("");
        }
      }

      // Make an array of Villain IDs
      let tempVillains:number[] = [];
      let tempVillainRarities:string[] = [];
      for (let i = 0; i < localActiveVillains.length; i++) {
        if (localActiveVillains[i]) {         
          tempVillains.push(localActiveVillains[i].id);                    
          tempVillainRarities.push(localActiveVillainRarities[i]);
        } else {
          tempVillains.push(0);
          tempVillainRarities.push("");
        }
      }
      
      // Send these to the confirmation popup
      dispatch(showBattleConfirmation(tempHeroes, tempHeroRarities, tempVillains, tempVillainRarities, rematch, false));        
    }
  }

  // TO PRACTICE!
  const clickPractice = () => {
    if (clicksEnabled){
      clicksEnabled=false;
    
      // Make sure they have 5 heroes + 5 villains set
      let bError:boolean = false;
      for (let i = 0; i < localActiveHeroes.length; i++) {
        if (!localActiveHeroes[i]) {
          bError = true;
        }
      }
      for (let i = 0; i < localActiveVillains.length; i++) {
        if (!localActiveVillains[i]) {
          bError = true;
        }
      }

      if (bError) {
        dispatch(setGeneralError("Please assign all 5 Heroes AND Villains"));
        return;
      }   
             
      // Make sure all the ships in the fleet fit the params if weekly
      if (gameState.game) {         
        // Also check if this is a total points week
        if (gameState.game.leaderboards[leaderboardIndex].cardPoints > 0  && totalHeroPoints > gameState.game.leaderboards[leaderboardIndex].cardPoints) {
          dispatch(setGeneralError("Your Heroes are too powerful!  Please reduce the number of points you are using."));
          return;
        }   
        if (gameState.game.leaderboards[leaderboardIndex].cardPoints > 0  && totalVillainPoints > gameState.game.leaderboards[leaderboardIndex].cardPoints) {
          dispatch(setGeneralError("Your Villains are too powerful!  Please reduce the number of points you are using."));
          return;
        }       
      }
      
      // Make an array of Hero IDs
      let tempHeroes:number[] = [];
      let tempHeroRarities:string[] = [];
      for (let i = 0; i < localActiveHeroes.length; i++) {
        if (localActiveHeroes[i]) {         
          tempHeroes.push(localActiveHeroes[i].id);                    
          tempHeroRarities.push(localActiveHeroRarities[i]);
        } else {
          tempHeroes.push(0);
          tempHeroRarities.push("");
        }
      }

      // Make an array of Villain IDs
      let tempVillains:number[] = [];
      let tempVillainRarities:string[] = [];
      for (let i = 0; i < localActiveVillains.length; i++) {
        if (localActiveVillains[i]) {         
          tempVillains.push(localActiveVillains[i].id);                    
          tempVillainRarities.push(localActiveVillainRarities[i]);
        } else {
          tempVillains.push(0);
          tempVillainRarities.push("");
        }
      }

      console.log("herorar", tempHeroRarities);
      console.log("villainrar", tempVillainRarities);
      
      // Send these to the confirmation popup
      dispatch(showBattleConfirmation(tempHeroes, tempHeroRarities, tempVillains, tempVillainRarities, false, true));              
    }
  }

  // Sign up for a tournament
  const clickSignUp = () => {
    if (clicksEnabled){
      clicksEnabled=false;

      // Make sure they have 5 heroes + 5 villains set
      let bError:boolean = false;
      for (let i = 0; i < localActiveHeroes.length; i++) {
        if (!localActiveHeroes[i]) {
          bError = true;
        }
      }
      for (let i = 0; i < localActiveVillains.length; i++) {
        if (!localActiveVillains[i]) {
          bError = true;
        }
      }

      if (bError) {
        dispatch(setGeneralError("Please assign all 5 Heroes AND Villains"));
        return;
      }   
             
      // Make sure all the ships in the fleet fit the params if weekly
      if (gameState.game) {         
        // Also check if this is a total points week
        if (gameState.game.leaderboards[leaderboardIndex].cardPoints > 0  && totalHeroPoints > gameState.game.leaderboards[leaderboardIndex].cardPoints) {
          dispatch(setGeneralError("Your Heroes are too powerful!  Please reduce the number of points you are using."));
          return;
        }   
        if (gameState.game.leaderboards[leaderboardIndex].cardPoints > 0  && totalVillainPoints > gameState.game.leaderboards[leaderboardIndex].cardPoints) {
          dispatch(setGeneralError("Your Villains are too powerful!  Please reduce the number of points you are using."));
          return;
        }       
      }
      
      // Make an array of Hero IDs
      let tempHeroes:number[] = [];
      let tempHeroRarities:string[] = [];
      for (let i = 0; i < localActiveHeroes.length; i++) {
        if (localActiveHeroes[i]) {         
          tempHeroes.push(localActiveHeroes[i].id);                    
          tempHeroRarities.push(localActiveHeroRarities[i]);
        } else {
          tempHeroes.push(0);
          tempHeroRarities.push("");
        }
      }

      // Make an array of Villain IDs
      let tempVillains:number[] = [];
      let tempVillainRarities:string[] = [];
      for (let i = 0; i < localActiveVillains.length; i++) {
        if (localActiveVillains[i]) {         
          tempVillains.push(localActiveVillains[i].id);                    
          tempVillainRarities.push(localActiveVillainRarities[i]);
        } else {
          tempVillains.push(0);
          tempVillainRarities.push("");
        }
      }
                  
      // Send these to the confirmation popup
      dispatch(showBuyinConfirmation(tempHeroes, tempHeroRarities, tempVillains, tempVillainRarities));
    }
  }

  const clickSponsor = (link:string | undefined) => {   
    if (link) {
      window.open(link);
    }
  }

  const updateSelectedPreset = (e:any) => {            
    setCurrentPresetIndex(e.target.selectedIndex);    
  }

  const clickLoadPreset = () => {    
    if (clicksEnabled && playerState.player) {
      clicksEnabled = false;

      try {
        // Setup all the costs, points + stuff
        resetFleet(playerState.player?.cardPresets[currentPresetIndex].heroes, playerState.player?.cardPresets[currentPresetIndex].heroRarities, 
          playerState.player?.cardPresets[currentPresetIndex].villains, playerState.player?.cardPresets[currentPresetIndex].villainRarities);
      
      } catch (e:any) {
        dispatch(setGeneralError("Error Loading Preset"));
      }
    }
  }

  const clickDeletePreset = () => {    
    if (clicksEnabled) {
      clicksEnabled = false;

      // Delete the currently selected preset
      dispatch(setGeneralBusyMessage("Deleting Preset...."));
      dispatch(presetDelete(currentPresetIndex));
    }
  }

  const clickSavePreset = () => {    
    if (clicksEnabled) {
      clicksEnabled = false;

      if (presetName === "") {
        dispatch(setGeneralError("Please enter a name for your preset"));
        return;
      }

      // Save it

      // Make an array of Hero IDs
      let tempHeroes:number[] = [];
      let tempHeroRarities:string[] = [];
      for (let i = 0; i < localActiveHeroes.length; i++) {
        if (localActiveHeroes[i]) {         
          tempHeroes.push(localActiveHeroes[i].id);                    
          tempHeroRarities.push(localActiveHeroRarities[i]);
        } else {
          tempHeroes.push(0);
          tempHeroRarities.push("");
        }
      }

      // Make an array of Villain IDs
      let tempVillains:number[] = [];
      let tempVillainRarities:string[] = [];
      for (let i = 0; i < localActiveVillains.length; i++) {
        if (localActiveVillains[i]) {         
          tempVillains.push(localActiveVillains[i].id);                    
          tempVillainRarities.push(localActiveVillainRarities[i]);
        } else {
          tempVillains.push(0);
          tempVillainRarities.push("");
        }
      } 

      dispatch(setGeneralBusyMessage("Saving Preset...."));
      dispatch(presetSave(presetName,tempHeroes, tempHeroRarities, tempVillains, tempVillainRarities));
    }
  }

  const changePresetName = (e:any) => {          
    setPresetName(e.target.value.replace(/[^a-z0-9 ]/gi,''));
  }

  return ( 
    <div className={classes['battle-display']}>
      {currentModule !== 'rankings' ?
      <>
      <div className={classes['mission-holder']}>
        <div className={classes['battle-top']}>   
          {showBattleButtons ?       
            timeLeft > 0 ?
              <>
                <button className={classes['battle-button-disabled']}>
                  <TimeIcon className={classes['time-icon']} />
                  <div className={classes['controls-text']}>{formattedTimeLeft}</div>
                </button> 
                {(gameState.gameMode === 'seasonal' && playerState.player?.rematch !== "" && gameState.game?.leaderboards[leaderboardIndex].rematchAllowed) ||
                  (gameState.gameMode === 'weekly' && playerState.player?.rematch_weekly !== "" && gameState.game?.leaderboards[leaderboardIndex].rematchAllowed) ?

                  <button className={classes['battle-button-disabled-second']}>
                    <TimeIcon className={classes['time-icon']} />
                    <div className={classes['controls-text']}>{formattedTimeLeft}</div>
                  </button>
                : null
                }
              </>
            :
              <>
                <div className={classes['link']} onClick={() => clickDeployForBattle(false)} >
                    <div className={classes['battle-button']}>Battle</div>
                </div>
                {gameState.gameMode === 'seasonal' && gameState.game?.leaderboards[leaderboardIndex].rematchAllowed ?
                  playerState.player?.rematch !== "" ?
                    <div className={classes['link']} onClick={() => clickDeployForBattle(true)} >
                        <div className={classes['rematch-button']}>Rematch: {playerState.player?.rematch_name}</div>
                    </div>
                  : null
                :
                  playerState.player?.rematch_weekly !== "" && gameState.game?.leaderboards[leaderboardIndex].rematchAllowed ?
                    <div className={classes['link']} onClick={() => clickDeployForBattle(true)} >
                        <div className={classes['rematch-button']}>Rematch: {playerState.player?.rematch_name_weekly}</div>
                    </div>
                  : null
                }
              </>
                  
          : null }   
                                     
          {currentModule === 'battle' ?    
            <>
              <div className={classes['link']} onClick={() => clickPractice()} >
                <div className={classes['practice-button']}>Practice</div>
              </div>                            
              <div className={classes['link']} onClick={() => removeAllFromSet()} >
                <div className={classes['mission-manage-button']}>Remove All</div>
              </div> 
            </>
          : 
            <div className={classes['link']} onClick={() => clickPractice()} >
              <div className={classes['practice-button-home']}>Practice</div>
            </div>            
          }
        </div>          
      </div>

      <div className={classes['card-setups']}>     
        <div className={classes['card-type-box']}>
          <div className={classes['card-type-title']}>Your Hero Lineup</div>
          {gameState.game && gameState.game?.leaderboards[leaderboardIndex].cardPoints > 0 ? 
            <div className={classes['points-container']}>Points: 
            
              {totalHeroPoints <= gameState.game?.leaderboards[leaderboardIndex].cardPoints ?
                <span className={classes['total-points-green']}>{totalHeroPoints}</span>
              :
                <span className={classes['total-points-red']}>{totalHeroPoints}</span>
              }
            </div>
            : null
          }
          <img className={classes['faction-hero']} src={heroIcon} alt='Hero' />  
        </div>     
        <div className={classes['card-setup-row']}>          
          <div className={classes['card-list']}>
            {localActiveHeroes.map( ( card, index ) => (
                <ActiveCardsBlock key={index} 
                  card={card}
                  slotIndex={index}
                  removeFunction={removeFromSet}
                  moveFunction={moveWithinSet} 
                  infoFunction={showInfo}
                  gameState={gameState}
                  configuration={currentModule}
                  updateRarityFunction={updateRarity}                
                  rarities={localActiveHeroRarities}              
                />
              ))
            }
          </div>   
        </div>
        <div className={classes['spacer-2rem']}>&nbsp;</div>
        <div className={classes['card-type-box']}>
          <div className={classes['card-type-title']}>Your Villain Lineup</div>
          {gameState.game && gameState.game?.leaderboards[leaderboardIndex].cardPoints > 0 ? 
            <div className={classes['points-container']}>Points: 
            
              {totalVillainPoints <= gameState.game?.leaderboards[leaderboardIndex].cardPoints ?
                <span className={classes['total-points-green']}>{totalVillainPoints}</span>
              :
                <span className={classes['total-points-red']}>{totalVillainPoints}</span>
              }
            </div>
            : null
          }
          <img className={classes['faction-hero']} src={villainIcon} alt='Villain' /> 
        </div>
        <div className={classes['card-setup-row']}>          
          <div className={classes['card-list']}>
            {localActiveVillains.map( ( card, index ) => (
                <ActiveCardsBlock key={index} 
                  card={card}
                  slotIndex={index}
                  removeFunction={removeFromSet}
                  moveFunction={moveWithinSet} 
                  infoFunction={showInfo}
                  gameState={gameState}
                  configuration={currentModule}
                  updateRarityFunction={updateRarity}                
                  rarities={localActiveVillainRarities}              
                />
              ))
            }
          </div>   
        </div>
      </div>
      
      <div className={classes['bottom-section']}>
       
        <div className={classes['deployment-cost-section-reset']}>            
          <div className={classes['deployment-reset']}>Battle Reset: {formattedBattleReset}</div> 
        </div>
                
        {gameState.gameMode === 'seasonal' ?
          <div className={classes['token-section-group']}>            
            <div className={classes['deployment-cost-title']}>Total Battles Remaining:&nbsp;{playerState.player?.battlesRemaining}</div>                        
          </div>
        :
          <div className={classes['token-section-group']}>            
            <div className={classes['deployment-cost-title']}>Total Battles Remaining:&nbsp;{playerState.player?.weeklyBattlesRemaining}</div>                        
          </div> 
        }
      </div>   
      </>
      : null }
              
      {currentModule !== 'rankings' ?
        <div className={classes['preset-row']}> 
          <div className={classes['preset-section']}>           
            <div className={classes['deployment-cost-title']}>
              <select className={classes['preset-select']} onChange={(e) => updateSelectedPreset(e)}>
                {playerState.player?.cardPresets.map( ( preset:ICardPreset, index:number ) => (
                  index === currentPresetIndex ? 
                  <option selected key={index} value={index}>{preset.name}</option>              
                  :
                  <option key={index} value={index}>{preset.name}</option>                    
                ))}           
              </select>
            </div>
            <div className={classes['link']} onClick={() => clickLoadPreset()} >
              <div className={classes['preset-button']}>Load</div>
            </div>
            <button className={classes['preset-button-delete']} onClick={() => clickDeletePreset()}>
              <TrashIcon className={classes['time-icon']} />              
            </button>

            <div className={classes['detail-value']}>
              <input type="text" name="name" value={presetName} onChange={changePresetName} maxLength={20} />     
            </div>

            <div className={classes['link']} onClick={() => clickSavePreset()} >
              <div className={classes['preset-button']}>Save</div>
            </div>
          </div>
        </div>
      : null }
        
      {currentModule !== 'battle' &&  gameState.game?.leaderboards[leaderboardIndex].clickLink !== "" ?       
          <img src={gameState.game?.leaderboards[leaderboardIndex].bannerDesktop} 
            onClick={() => clickSponsor(gameState.game?.leaderboards[leaderboardIndex].clickLink)}
            className={classes['banner-desktop']}
            />    
      : null }

      {gameState.game ? 
        <LeaderboardRules leaderboard={gameState.game?.leaderboards[leaderboardIndex]} />
      : null }

      {currentModule !== 'rankings' && gameState.game && (gameState.game?.leaderboards[leaderboardIndex].enterAnyTime || (
        Date.now() > gameState.game?.leaderboards[leaderboardIndex].signUpStartTime && 
        Date.now() < gameState.game?.leaderboards[leaderboardIndex].startTime)) && !signedUp ?   
        <div className={classes['tournament-button-group']}>
            <div className={classes['link']} onClick={() => clickSignUp()} >
              <div className={classes['tournament-sign-up-button']}>SIGN UP</div>
            </div>
        </div>      
      : null }

      {currentModule === 'battle' ?
        <CardSection playerState={playerState}             
          sendFunction={addToSet}
          infoFunction={showInfo}
          currentModule={currentModule}           
          currentHeroes={localActiveHeroes}
          currentVillains={localActiveVillains}  />  
      :
        <div className={classes['leaderboard-holder']}>           
          <LeaderboardDisplay leaderboard={gameState.game?.leaderboards[leaderboardIndex]} isWeekly={false}
            playerName={playerState.player?.name} />          
        </div>
      }
    </div>
  );
}

export default FleetManagementInterface;