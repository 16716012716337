import {
  GET_PLAYER_DATA_REQUEST,
  GET_PLAYER_DATA_SUCCESS,
  GET_PLAYER_DATA_FAILURE,
  UPDATE_PLAYER_DATA_REQUEST,
  UPDATE_PLAYER_DATA_SUCCESS,
  UPDATE_PLAYER_DATA_FAILURE,
  CLEAR_BATTLE,
  HIDE_BATTLE_LOG,
  SHOW_BATTLE_RESULTS,
  HIDE_BATTLE_RESULTS,
  SHOW_BATTLE_LOG,
  CLEAR_PLAYER_ERROR,
  CLEAR_ANNOUNCEMENT,
  COMMENCE_PRACTICE_BATTLE,
  COMMENCE_PRACTICE_SUCCESS,
  COMMENCE_BUYIN_PREFLIGHT,
  COMMENCE_BUYIN_PREFLIGHT_SUCCESS,
  COMMENCE_BUYIN_PREFLIGHT_FAILURE,
  COMMENCE_BUYIN,
  COMMENCE_BUYIN_SUCCESS,
  COMMENCE_BUYIN_FAILURE,
  COMMENCE_TOURNAMENT_BATTLE,
  COMMENCE_TOURNAMENT_SUCCESS,
  COMMENCE_SIMULATOR_BATTLE,
  COMMENCE_SIMULATOR_SUCCESS,
  HIDE_SIMULATOR_BATTLE,
  PRESET_SAVE,
  PRESET_SUCCESS,
  PRESET_DELETE,
  COMMENCE_BATTLE_FAILURE
} from "../actions/actionTypes";
import {   
  GetPlayerDataRequest,
  GetPlayerDataSuccess,
  GetPlayerDataFailure,
  GetPlayerSuccessPayload,
  GetPlayerFailurePayload,
  UpdatePlayerDataRequest,
  UpdatePlayerDataSuccess,
  UpdatePlayerDataFailure,  
  UpdatePlayerSuccessPayload,
  UpdatePlayerFailurePayload,
  IPlayer,
  ClearBattleRequest,
  HideBattleLogRequest,
  ShowBattleResultsRequest,
  HideBattleResultsRequest,
  ShowBattleLogRequest,
  ClearPlayerErrorRequest,
  ClearAnnouncementRequest,
  CommencePracticeBattleRequest,
  CommencePracticeBattleSuccess,
  CommencePracticeBattleSuccessPayload,
  CommenceBuyinPreFlightRequest,
  CommenceBuyinPreFlightSuccessPayload,
  CommenceBuyinPreFlightSuccess,
  CommenceBuyinFailurePayload,
  CommenceBuyinPreFlightFailure,
  CommenceBuyinRequest,
  CommenceBuyinSuccessPayload,
  CommenceBuyinSuccess,
  CommenceBuyinFailure,
  CommenceTournamentBattleRequest,
  CommenceTournamentBattleSuccess,
  CommenceTournamentBattleSuccessPayload,
  CommenceSimulatorBattleRequest,
  CommenceSimulatorBattleSuccessPayload,
  CommenceSimulatorBattleSuccess,
  HideSimulatorResultsRequest,
  PresetSaveRequest,
  PresetSuccessPayload,
  PresetSuccess,
  PresetDeleteRequest,
  CommenceBattleFailurePayload,
  CommenceBattleFailure,
} from "../types/playerTypes";

// Retrieving of Player State
export const getPlayerDataRequest = (): GetPlayerDataRequest => ({
  type: GET_PLAYER_DATA_REQUEST
});
export const getPlayerDataSuccess = (
  payload: GetPlayerSuccessPayload
): GetPlayerDataSuccess => ({
  type: GET_PLAYER_DATA_SUCCESS,
  payload,
});
export const getPlayerDataFailure = (
  payload: GetPlayerFailurePayload
): GetPlayerDataFailure => ({
  type: GET_PLAYER_DATA_FAILURE,
  payload,
});

// Updating of Player State
export const updatePlayerDataRequest = ( updatedPlayer: IPlayer ): UpdatePlayerDataRequest => ({
  type: UPDATE_PLAYER_DATA_REQUEST,
  player: updatedPlayer
});
export const updatePlayerDataSuccess = (
  payload: UpdatePlayerSuccessPayload
): UpdatePlayerDataSuccess => ({
  type: UPDATE_PLAYER_DATA_SUCCESS,
  payload,
});
export const updatePlayerDataFailure = (
  payload: UpdatePlayerFailurePayload
): UpdatePlayerDataFailure => ({
  type: UPDATE_PLAYER_DATA_FAILURE,
  payload,
});

// PreFlight Buyin Checks
export const commenceBuyinPreFlight = ( cost:number, heroes:number[], heroRarities:string[], villains:number[], villainRarities:string[], gold:boolean ): CommenceBuyinPreFlightRequest => ({
  type: COMMENCE_BUYIN_PREFLIGHT,  
  cost: cost,
  heroes: heroes,
  heroRarities: heroRarities,
  villains: villains,
  villainRarities: villainRarities,   
  gold: gold
});
export const commenceBuyinPreFlightSuccess = (
  payload: CommenceBuyinPreFlightSuccessPayload
): CommenceBuyinPreFlightSuccess => ({
  type: COMMENCE_BUYIN_PREFLIGHT_SUCCESS,
  payload
});
export const commenceBuyinPreFlightFailure = (
  payload: CommenceBuyinFailurePayload
): CommenceBuyinPreFlightFailure => ({
  type: COMMENCE_BUYIN_PREFLIGHT_FAILURE,
  payload,
});
export const commenceBattleFailure = (
  payload: CommenceBattleFailurePayload
): CommenceBattleFailure => ({
  type: COMMENCE_BATTLE_FAILURE,
  payload,
});

// To Battle!
export const commenceBuyin = ( transactionResult:any, heroes:number[], heroRarities:string[], villains:number[], villainRarities:string[], gold:boolean ): CommenceBuyinRequest => ({
  type: COMMENCE_BUYIN,
  transactionResult: transactionResult,
  heroes: heroes,
  heroRarities: heroRarities,
  villains: villains,
  villainRarities: villainRarities,  
  gold: gold
});
export const commenceBuyinSuccess = (
  payload: CommenceBuyinSuccessPayload
): CommenceBuyinSuccess => ({
  type: COMMENCE_BUYIN_SUCCESS,
  payload,
});
export const commenceBuyinFailure = (
  payload: CommenceBuyinFailurePayload
): CommenceBuyinFailure => ({
  type: COMMENCE_BUYIN_FAILURE,
  payload,
});

// Simulator battle
export const commenceSimulatorBattle = ( 
  simulations:number,
  playerHeroes: number[],
  playerHeroRarities: string[],
  opponentVillains: number[],
  opponentVillainRarities: string[],   
  history:any ): CommenceSimulatorBattleRequest => ({
  type: COMMENCE_SIMULATOR_BATTLE,  
  simulations,
  playerHeroes: playerHeroes,
  playerHeroRarities: playerHeroRarities, 
  opponentVillains: opponentVillains, 
  opponentVillainRarities: opponentVillainRarities,     
  history: history
});
export const commenceSimulatorSuccess = (
  payload: CommenceSimulatorBattleSuccessPayload
): CommenceSimulatorBattleSuccess => ({
  type: COMMENCE_SIMULATOR_SUCCESS,
  payload,
});

// Preset operations
export const presetSave = ( name:string, heroes:number[], heroRarities:string[], villains:number[], villainRarities:string[] ): PresetSaveRequest => ({
  type: PRESET_SAVE,
  name: name,
  heroes: heroes,
  heroRarities: heroRarities,
  villains: villains,
  villainRarities: villainRarities
});
export const presetDelete = ( index:number ): PresetDeleteRequest => ({
  type: PRESET_DELETE,
  index: index
});
export const presetSuccess = (
  payload: PresetSuccessPayload
): PresetSuccess => ({
  type: PRESET_SUCCESS,
  payload,
});

// Practice Battle
export const commencePracticeBattle = ( heroes:number[], heroRarities:string[], villains:number[], villainRarities:string[], skipSimulation:boolean, history:any, weekly:boolean ): CommencePracticeBattleRequest => ({
  type: COMMENCE_PRACTICE_BATTLE,    
  heroes: heroes,
  heroRarities: heroRarities,
  villains: villains,
  villainRarities: villainRarities,
  skipSimulation: skipSimulation,
  history: history,
  weekly: weekly  
});
export const commencePracticeBattleSuccess = (
  payload: CommencePracticeBattleSuccessPayload
): CommencePracticeBattleSuccess => ({
  type: COMMENCE_PRACTICE_SUCCESS,
  payload,
});

// Tournament Battle
export const commenceTournamentBattle = ( heroes:number[], heroRarities:string[], villains:number[], villainRarities:string[], rematch:boolean,
  skipSimulation:boolean, history:any, gold:boolean ): CommenceTournamentBattleRequest => ({
  
  type: COMMENCE_TOURNAMENT_BATTLE,    
  heroes: heroes,
  heroRarities: heroRarities,
  villains: villains,
  villainRarities: villainRarities,
  rematch: rematch,  
  skipSimulation: skipSimulation,
  history: history,
  gold: gold
});
export const commenceTournamentBattleSuccess = (
  payload: CommenceTournamentBattleSuccessPayload
): CommenceTournamentBattleSuccess => ({
  type: COMMENCE_TOURNAMENT_SUCCESS,
  payload,
});

export const clearBattleResults = (): ClearBattleRequest => ({
  type: CLEAR_BATTLE
});
export const showBattleLog = (): ShowBattleLogRequest => ({
  type: SHOW_BATTLE_LOG
});
export const hideBattleLog = (): HideBattleLogRequest => ({
  type: HIDE_BATTLE_LOG
});
export const showBattleResults = (): ShowBattleResultsRequest => ({
  type: SHOW_BATTLE_RESULTS
});
export const hideBattleResults = (): HideBattleResultsRequest => ({
  type: HIDE_BATTLE_RESULTS
});

export const hideSimulatorResults = (): HideSimulatorResultsRequest => ({
  type: HIDE_SIMULATOR_BATTLE
});

export const clearAnnouncement = (): ClearAnnouncementRequest => ({
  type: CLEAR_ANNOUNCEMENT
});

// Clear player state error
export const clearPlayerError = (): ClearPlayerErrorRequest => ({
  type: CLEAR_PLAYER_ERROR
});