import classes from './MobileLeaderboardCardSmall.module.css';
import { ICard } from '../../../../store/types/playerTypes';
import ReactTooltip from "react-tooltip";
import { rarityToIndex } from '../../../../hoc/CommonFunctions';
import { ILeaderboardCard } from '../../../../store/types/gameTypes';

interface LeaderboardCardSmallProps {
  card:ILeaderboardCard;
  rank:number;
  cardIndex:number;    
  faction:string;
}

const MobileLeaderboardCardSmall = ( { card, rank, cardIndex, faction }:LeaderboardCardSmallProps ) => {  
  return (
    card && card.id > 0 ?  
      <div data-tip data-for={"cardTip"+rank+cardIndex+faction} className={[classes['card-holder'],classes['card-holder-'+card.rarity]].join(' ')}>               
        <div className={classes['middle-row']}>
          <img className={classes['card-image']} src={card.image}></img>
        </div>          
        <ReactTooltip backgroundColor="rgba(113,7,116,0.8)" arrowColor="rgba(113,7,116,0.8)" className={classes['tip-text']} id={"cardTip"+rank+cardIndex+faction} place="top" effect="solid">
          {card.name}
        </ReactTooltip>  
      </div>    
    : null
  );
};

export default MobileLeaderboardCardSmall;
