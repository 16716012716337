import classes from './MobileBattleResults.module.css';
import medalImg from '../../../assets/images/general/icons/icon_trophy.png';
import {ReactComponent as CrossIcon} from '../../../assets/svg/cross.svg';
import { Link, useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { hideBattleResults, showBattleLog } from '../../../store/actions/playerActions';
import {ReactComponent as WeeklyMedalIcon} from '../../../assets/svg/medal.svg';
import { getGameStateSelector } from '../../../store/selectors/gameSelectors';
import { getPlayerStateSelector } from '../../../store/selectors/playerSelectors';

const MobileBattleResults = (props) => {    
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const gameState = useSelector(getGameStateSelector);
  const playerState = useSelector(getPlayerStateSelector);

  // Close this popup
  const showBattleLogClick = () => {
    dispatch(showBattleLog());
  }

  const hideResultsClick = () => {
    dispatch(hideBattleResults());

    // If we are on the show-battle page, go to the home page
    //  - unless this was a simulator battle, then go there
    if (playerState.isSimulatorGame) {
      history.push("/simulator");
    }  else {
      if (location.pathname === '/show-battle' ) {
        history.push("/");
      } 
    }
  }

  let output = null;
  
  if (props.showResult && props.battleResult) {
    output = 
    <div className={classes['main-content']}>           
      <div className={classes['popup-content-image']}>                                       
        {props.battleResult.battleWon ?
          <>
            <div className={classes['popup-title-green']}>VICTORY!</div>    
            <div className={classes['winner-text']}>You have defeated {props.battleResult.enemyName}!</div>
            <div className={classes['winner-result']}>
              <div className={classes['winner-text']}>+{props.medalsWon}</div>
              
              {gameState.gameMode === 'seasonal' ?
                <img alt="Medals" src={medalImg} className={classes['medal-image']} />     
              :
                <WeeklyMedalIcon className={classes['medal-icon']} />     
              }
            </div>
          </>
        :
          <>
            <div className={classes['popup-title-red']}>DEFEAT</div>  
            <div className={classes['winner-text']}>You were defeated by {props.battleResult.enemyName}</div>
            <div className={classes['winner-result']}>
              <div className={classes['winner-text']}>{props.medalsWon}</div>
              {gameState.gameMode === 'seasonal' ?
                <img alt="Medals" src={medalImg} className={classes['medal-image']} />     
              :
                <WeeklyMedalIcon className={classes['medal-icon']} />     
              } 
            </div>
          </>
        }
        
        <div className={classes['button-section']}>                    
          <div onClick={() => showBattleLogClick()} className={classes['return-button-log']}>Show Battle Log</div>
          <div onClick={() => hideResultsClick()} className={classes['return-button']}>Return Home</div>          
        </div>
      </div>
    </div>;
  }
  
  return output;
};

export default MobileBattleResults;