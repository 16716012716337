import Phaser from "phaser";

class Card extends Phaser.Physics.Arcade.Sprite {    
  constructor(config, cardWidth, cardSpacer, card, baseScale, isEnemy, targetY, shieldSprite, armorSprite, shieldText, armorText, shieldDisplay, timeScale, game, frameAttacking, frameTarget) {      
    super(config.scene, config.x, config.y, config.key, config.frame);            
    this.cardWidth = cardWidth;
    this.cardSpacer = cardSpacer;
    this.game = game;
    this.card = card;  
    this.isEnemy = isEnemy;  
    this.shieldSprite = shieldSprite;    
    this.armorSprite = armorSprite;    
    this.shieldText = shieldText;
    this.armorText = armorText;
    this.frameAttacking = frameAttacking;
    this.frameAttacking.alpha = 0;
    this.frameTarget = frameTarget;
    this.frameTarget.alpha = 0;
    this.shieldDisplay = shieldDisplay;    
    this.turnCompleteFunction = null;
    this.fireCompleteFunction = null;
    this.destroyCompleteFunction = null;
    this.moveCompleteFunction = null;
    this.frameShownFunction = null;
    this.deathCompleteFunction = null;
    this.baseScale = baseScale / 2;
    this.timeScale = timeScale;    
    this.isDebuffed = false;
    this.isFrozen = false;
    this.baseAnimationName = config.key;
    this.y = targetY;
   
    this.floatingArmorText = null;
    this.floatingShieldText = null;
    this.floatingSuperText = null;
    this.floatingTextOffset = 40;
       
    config.scene.add.existing(this);
        
    this.scale = this.baseScale;  
    this.alpha = 0;     

    // IF this is an enemy ship, flip the shields
    if (isEnemy) {      
      this.shieldDisplay.setFlipY(true);      
    } 
            
    // Init bars to full
    this.shieldSprite.setFrame("barrier_" + this.card.rarity + "_10.png");
    this.armorSprite.setFrame("health_" + this.card.rarity + "_10.png");      

    // Set stats sprites to 0 if needed
    if (this.card.originalBarrier === 0) {
      this.shieldSprite.setFrame("barrier_" + this.card.rarity + "_01.png");
    } 
    if (this.card.originalHealth === 0) {
      this.armorSprite.setFrame("health_" + this.card.rarity + "_01.png");      
    } 

    // Init the texts
    this.shieldText.text = this.card.originalBarrier.toString().padStart(3,'0');
    this.armorText.text = this.card.originalHealth.toString().padStart(3,'0');    

    // Init the "previous" settings
    this.previousBarrier = this.card.originalBarrier;
    this.previousHealth = this.card.originalHealth;

    // Hide the shields
    this.showingShields = true;
    this.shieldDisplay.alpha = 0;
    this.shieldDisplay.on('animationcomplete', () => this.shieldAnimationComplete());

    // Some shields scale up a bit bigger than others
    if (this.card.galaxy === 2 || this.card.galaxy === 6) {
      this.shieldDisplay.scale = this.scale * 1.5;
    } else {
      this.shieldDisplay.scale = this.scale * 1.2;
    }    
  }

  addSlashSprite(slashSprite) {
    this.slashDisplay = slashSprite;

    // Hide the slash
    this.slashDisplay.alpha = 0;
    this.slashDisplay.on('animationcomplete', () => this.slashAnimationComplete());
    this.slashDisplay.scale = this.scale * 0.5;
  }

  addFloatingTexts(floatingShieldText, floatingArmorText, floatingSuperText) {
    this.floatingArmorText = floatingArmorText;
    this.floatingArmorText.alpha = 0;

    this.floatingShieldText = floatingShieldText;
    this.floatingShieldText.alpha = 0;

    this.floatingSuperText = floatingSuperText;
    this.floatingSuperText.alpha = 0;
  }
  

  changeSlot(currentCard, moveCompleteFunction) {        
    this.moveCompleteFunction = moveCompleteFunction;   
                         
    let tempX = (.5 * this.cardWidth) + (currentCard.currentBattleSlot * this.cardWidth) + ((currentCard.currentBattleSlot+1) * this.cardSpacer);

    // Tween it to the new position
    this.scene.tweens.add(
      {
        targets: [this, this.shieldDisplay, this.frameAttacking, this.frameTarget, this.slashDisplay],
        x: tempX,				
        ease: Phaser.Math.Easing.Quadratic.In,
        duration: 500 * this.timeScale, 
        delay: 0,
        onComplete: () => this.moveComplete()  
      }
    );    

    // Tween displays
    this.scene.tweens.add(
      {
        targets: [this.shieldSprite, this.armorSprite],
        x:  tempX - (this.baseScale * 50),				
        ease: Phaser.Math.Easing.Quadratic.In,
        duration: 500 * this.timeScale, 
        delay: 0 
      }
    );  

    // Tween texts
    this.scene.tweens.add(
      {
        targets: [this.shieldText, this.armorText],
        x: (tempX - (this.baseScale * 50)) + (this.baseScale * 100),				
        ease: Phaser.Math.Easing.Quadratic.In,
        duration: 500 * this.timeScale, 
        delay: 0 
      }
    );

    // Tween texts
    this.scene.tweens.add(
      {
        targets: [this.floatingArmorText, this.floatingShieldText],
        x: tempX - (this.baseScale * this.floatingTextOffset),			
        ease: Phaser.Math.Easing.Quadratic.In,
        duration: 500 * this.timeScale, 
        delay: 0 
      }
    );

    // Tween texts
    this.scene.tweens.add(
      {
        targets: [this.floatingSuperText],
        x: tempX,			
        ease: Phaser.Math.Easing.Quadratic.In,
        duration: 500 * this.timeScale, 
        delay: 0 
      }
    );
  }

  // This ship is dead, but lets move it anyways in case queen resurrects it
  changeSlotInstant(currentCard) {
    /*
    this.x = (.5 * this.cardWidth) + (currentCard.currentBattleSlot * this.cardWidth) + ((currentCard.currentBattleSlot+1) * this.cardSpacer);
    this.shieldSprite.x = this.x - (this.baseScale * 50);
    this.armorSprite.x = this.x - (this.baseScale * 50);   
    this.shieldText.x = (this.x - (this.baseScale * 50)) + (this.baseScale * 150);
    this.armorText.x = (this.x - (this.baseScale * 50)) + (this.baseScale * 150);
    this.shieldDisplay.x = this.x;    
    this.floatingArmorText.x = this.x - (this.baseScale * this.floatingTextOffset);
    this.floatingShieldText.x = this.x - (this.baseScale * this.floatingTextOffset);   
    this.frameAttacking.x = this.x;
    this.frameTarget.x = this.x;
    */
  }

  initSlot() {
    this.x = (.5 * this.cardWidth) + (this.card.initialBattleSlot * this.cardWidth) + ((this.card.initialBattleSlot+1) * this.cardSpacer);
    this.shieldDisplay.x = this.x;   
    this.slashDisplay.x = this.x; 
    this.floatingArmorText.x = this.x - (this.baseScale * this.floatingTextOffset);
    this.floatingShieldText.x = this.x - (this.baseScale * this.floatingTextOffset);   
    this.floatingSuperText.x = this.x;
    this.frameAttacking.x = this.x;
    this.frameTarget.x = this.x; 
  }

  tintComplete() { 
    if (this.isDebuffed) {
      // tint it back to yellow
      this.scene.tweens.addCounter({
        from: 0,
        to: 255,
        duration: 500 * this.timeScale,
        onUpdate: (tween) =>
        {
            const value = Math.floor(tween.getValue());

            this.setTint(Phaser.Display.Color.GetColor(value, value, 0));
        }
      });   
    } else if (this.isFrozen) {
        // tint it to blue
        this.setTint(Phaser.Display.Color.GetColor(123, 194, 223));
        
    }  else {    
      // Tint it back to normal
      this.scene.tweens.addCounter({
        from: 0,
        to: 255,
        duration: 500 * this.timeScale,
        onUpdate: (tween) =>
        {
            const value = Math.floor(tween.getValue());

            this.setTint(Phaser.Display.Color.GetColor(value, value, value));
        }
      });   
    }    
  }

  moveComplete() {    
    this.moveCompleteFunction();
  }

  showStatus(bAttacking, attackingCard, frameShownFunction, turnCompleteFunction) {
    this.turnCompleteFunction = turnCompleteFunction;
    this.frameShownFunction = frameShownFunction;

    if (bAttacking) {
      // Show the attacking frame
      this.scene.tweens.add(
        {
          targets: this.frameAttacking,
          alpha: 1,				
				  ease: 'linear',
          duration: 500 * this.timeScale, 
          delay: 0  
        }
      ); 

      // Show the animated powerup frame if needed
      if (attackingCard.showSuperPower) {
        this.frameAttacking.play("superFrame");

        // Show a powerup text   
        this.floatingSuperText.text = attackingCard.currentSpecialTitle;
        this.floatingSuperText.y = this.y;
        this.floatingSuperText.alpha = 1;

        this.scene.tweens.add(
          {
            targets: this.floatingSuperText,
            alpha: 0,				
            ease: Phaser.Math.Easing.Quadratic.In,
            duration: 1000 * this.timeScale, 
            delay: 500
          }
        );       
    
        try {
          this.game.sound.play("superpower");
        } catch (e) {} 

      } else {        
        this.frameAttacking.stop();
        this.frameAttacking.setFrame('attacking.png');
      }
    } else {      
      // Show the target frame
      this.scene.tweens.add(
        {
          targets: this.frameTarget,
          alpha: 1,				
				  ease: 'linear',
          duration: 500 * this.timeScale, 
          delay: 300,
          onComplete: () => this.frameShownFunction()  
        }
      ); 
    }

  } 

  hideStatus(bAttacking, attackingCard, frameShownFunction, turnCompleteFunction) {
    this.turnCompleteFunction = turnCompleteFunction;
    this.frameShownFunction = frameShownFunction;

    if (bAttacking) {
      // Hide the attacking frame
      this.scene.tweens.add(
        {
          targets: this.frameAttacking,
          alpha: 0,				
				  ease: 'linear',
          duration: 1000 * this.timeScale, 
          delay: 0,
          onComplete: () => this.frameShownFunction()  
        }
      ); 
    } else {
      // Hide the target frame
      this.scene.tweens.add(
        {
          targets: this.frameTarget,
          alpha: 0,				
				  ease: 'linear',
          duration: 1000 * this.timeScale, 
          delay: 0,
          onComplete: () => this.frameShownFunction()  
        }
      ); 
    }

  } 

  fireAtCard(targetCard, completeFunction) {    
    this.fireCompleteFunction = completeFunction;   
    
    // Tween it
    this.scene.tweens.add(
			{
				targets: this,
				rotation: 0,				
				ease: 'Power3',
				duration: 1000 * this.timeScale, 
				delay: 0,
				onComplete: () => this.fireComplete()  
			}
    ); 
  }

  fireComplete() {    
    this.fireCompleteFunction();
  }

  showStatsDisplays() {
    // Place the armor + shield sprites
    this.shieldSprite.x = this.x - (this.baseScale * 50);
    this.armorSprite.x = this.x - (this.baseScale * 50);
    
    if (this.isEnemy) {
      this.shieldSprite.y = this.y - (this.baseScale * 200) - (this.baseScale * 40);
      this.armorSprite.y = this.y - (this.baseScale * 200);    
    } else {
      this.shieldSprite.y = this.y + (this.baseScale * 200);
      this.armorSprite.y = this.y + (this.baseScale * 200) + (this.baseScale * 40);      
    }    

    // Start at 0 alpha and fade in
    this.shieldSprite.alpha = 0;
    this.armorSprite.alpha = 0;
    
    // Tween it
    this.scene.tweens.add(
			{
				targets: [this.shieldSprite, this.armorSprite],
				alpha: 1,				
				ease: 'linear',
				duration: 1000 * this.timeScale, 
				delay: 0
			}
    ); 

    // Repeat for the texts
    this.shieldText.x = this.shieldSprite.x + (this.baseScale * 100);
    this.shieldText.y = this.shieldSprite.y - (this.baseScale * 20);
    this.armorText.x = this.armorSprite.x + (this.baseScale * 100);
    this.armorText.y = this.armorSprite.y - (this.baseScale * 20);
   
    // Start at 0 alpha and fade in
    this.shieldText.alpha = 0;
    this.armorText.alpha = 0;

    // Tween it
    this.scene.tweens.add(
			{
				targets: [this.shieldText, this.armorText],
				alpha: 1,				
				ease: 'linear',
				duration: 1000 * this.timeScale, 
				delay: 0
			}
    ); 

    // Place the shield display
    this.shieldDisplay.alpha = 0;
    this.shieldDisplay.x = this.x;
    this.shieldDisplay.y = this.y;

    if (this.isEnemy) {
      this.shieldDisplay.y += (this.scale * 170);
    } else {
      this.shieldDisplay.y -= (this.scale * 170);
    }

    // Place the slash display
    this.slashDisplay.alpha = 0;
    this.slashDisplay.x = this.x;
    this.slashDisplay.y = this.y;

    // Place the floating texts
    this.floatingArmorText.alpha = 0;
    this.floatingArmorText.x = this.x - (this.baseScale * this.floatingTextOffset);
    this.floatingArmorText.y = this.y;
    this.floatingShieldText.alpha = 0;
    this.floatingShieldText.x = this.x - (this.baseScale * this.floatingTextOffset);
    this.floatingShieldText.y = this.y;
    this.floatingSuperText.alpha = 0;
    this.floatingSuperText.x = this.x;
    this.floatingSuperText.y = this.y;

    // Place and hide the frames
    this.frameAttacking.x = this.x;
    this.frameAttacking.y = this.y + (this.scale * 15);
    this.frameTarget.x = this.x; 
    this.frameTarget.y = this.y + (this.scale * 15);

    if (this.isEnemy) {
      this.frameAttacking.y = this.y - (this.scale * 66);
      this.frameTarget.y = this.y - (this.scale * 65);
    }
  }

  triggerShields() {         
    // Show the shields, we use this for 2 part shield animations
    this.showingShields = true;

    // Milky way shields + sound
    this.shieldDisplay.play("animationShieldFadeIn");

    // Play the sound
    try {
      this.game.sound.play("barrier");
    } catch (e) {}
        
    this.shieldDisplay.alpha = 1;         
  }

  shieldAnimationComplete() {
    // If we were showing the shields, now hide them
    if (this.showingShields) {
      this.showingShields = false;
      
      this.shieldDisplay.play("animationShieldFadeOut");
    } else {
      // Hide them
      this.shieldDisplay.alpha = 0;
    } 
  }

  triggerSlash() {                
    this.slashDisplay.play("animationSlash");   
    this.slashDisplay.alpha = 1;         
  }

  slashAnimationComplete() {    
    this.slashDisplay.alpha = 0;
  }

  updateStatsDisplay(currentState) {    
    // If my shields or armor have increased past my initial values, update the initial values
    if (currentState.currentBarrier > this.card.originalBarrier) {
      this.card.originalBarrier = currentState.currentBarrier;
    }
    if (currentState.currentHealth > this.card.originalHealth) {
      this.card.originalHealth = currentState.currentHealth;
    }
    
    let myShieldRatio = Math.ceil((currentState.currentBarrier / this.card.originalBarrier) * 10);
    let myArmorRatio = Math.ceil((currentState.currentHealth / this.card.originalHealth) * 10);    
    
    if (myShieldRatio) {
      this.shieldSprite.setFrame(`barrier_${this.card.rarity}_${myShieldRatio.toString().padStart(2,'0')}.png`);
    } else {
      this.shieldSprite.setFrame("barrier_" + this.card.rarity + "_01.png")
    }
    if (myArmorRatio) {
      this.armorSprite.setFrame(`health_${this.card.rarity}_${myArmorRatio.toString().padStart(2,'0')}.png`);

      // If its still alive always show at least one bar
      if (myArmorRatio === 1 && currentState.currentHealth > 0) {
        this.armorSprite.setFrame("health_" + this.card.rarity + "_02.png");
      }
    } else {
      this.armorSprite.setFrame("health_" + this.card.rarity + "_01.png")
    }
    
    // Update the texts
    this.shieldText.text = currentState.currentBarrier.toString().padStart(3,'0');
    this.armorText.text = currentState.currentHealth.toString().padStart(3,'0');

  
    // Other variables    
    this.isDebuffed = currentState.turnsDebuffed> 0 ? true : false;         
    this.isConfused = currentState.isConfused;

    // If I am debuffed, tint me
    if (this.isDebuffed) {
      // Yellow ish
      this.setTint(Phaser.Display.Color.GetColor(255, 255, 0));
    } else if (this.isFrozen) {
        // Blue ish
        this.setTint(Phaser.Display.Color.GetColor(123, 194, 223));
    } else if (this.isConfused) {
        // Purple ish
        this.setTint(Phaser.Display.Color.GetColor(114, 39, 133));
    } else {
      // Back to normal
      this.setTint(Phaser.Display.Color.GetColor(255, 255, 255));
    }
    
    // Floating targets
    let bShowArmorText = false;
    let bShowShieldText = false;
      
    // Show floating text normally
  
    // Show a text for shield change + armor change
    if (currentState.currentHealth > this.previousHealth) {
      // Show armor text in green
      this.floatingArmorText.setTint(Phaser.Display.Color.GetColor(144, 238, 144));
      this.floatingArmorText.text = "+" + Math.floor(currentState.currentHealth - this.previousHealth);
      bShowArmorText = true;

    } else if (currentState.currentHealth < this.previousHealth) {
      // Show armor text in red
      this.floatingArmorText.setTint(Phaser.Display.Color.GetColor(255, 0, 0));
      this.floatingArmorText.text = "-" + Math.floor(this.previousHealth - currentState.currentHealth);
      bShowArmorText = true;      

      // Show the slash animation
      this.triggerSlash();
    }

    if (currentState.currentBarrier > this.previousBarrier) {
      // Show shield text in green
      this.floatingShieldText.setTint(Phaser.Display.Color.GetColor(144, 238, 144));
      this.floatingShieldText.text = "+" + Math.floor(currentState.currentBarrier - this.previousBarrier);
      bShowShieldText = true;

    } else if (currentState.currentBarrier < this.previousBarrier) {
      // Show shield text in blue
      this.floatingShieldText.setTint(Phaser.Display.Color.GetColor(253, 255, 68));      
      this.floatingShieldText.text = "-" + Math.floor(this.previousBarrier - currentState.currentBarrier);      
      bShowShieldText = true;
    }    

    // Update previous values
    this.previousHealth = currentState.currentHealth;
    this.previousBarrier = currentState.currentBarrier; 

    // Tween them up and fade out
    if (bShowShieldText) {    
      this.floatingShieldText.y = this.y;
      this.floatingShieldText.alpha = 1;

      this.scene.tweens.add(
        {
          targets: this.floatingShieldText,
          y: this.y - (this.baseScale * 300),				
          ease: Phaser.Math.Easing.Quadratic.In,
          duration: 2000 * this.timeScale, 
          delay: 0      
        }
      ); 
      this.scene.tweens.add(
        {
          targets: this.floatingShieldText,
          alpha: 0,				
          ease: Phaser.Math.Easing.Quadratic.In,
          duration: 2000 * this.timeScale, 
          delay: 0
        }
      ); 
    }
    if (bShowArmorText) {    
      this.floatingArmorText.y = this.y;
      this.floatingArmorText.alpha = 1;

      this.scene.tweens.add(
        {
          targets: this.floatingArmorText,
          y: this.y - (this.baseScale * 300),				
          ease: Phaser.Math.Easing.Quadratic.In,
          duration: 4000 * this.timeScale, 
          delay: 0      
        }
      ); 
      this.scene.tweens.add(
        {
          targets: this.floatingArmorText,
          alpha: 0,				
          ease: Phaser.Math.Easing.Quadratic.In,
          duration: 4000 * this.timeScale, 
          delay: 0
        }
      ); 
    }         
  }
 
  startDestroy(onDeathComplete) { 
    this.deathCompleteFunction = onDeathComplete;   
    this.shieldSprite.alpha = 0;
    this.armorSprite.alpha = 0;    
    this.shieldText.alpha = 0;
    this.armorText.alpha = 0;
    
    this.scene.tweens.add(
			{
				targets: this,
				alpha: 0,				
				ease: 'linear',
				duration: 300 * this.timeScale, 
				delay: 0,
        onComplete: () => this.deathCompleteFunction() 
			}
    );     
  }

  flashRed() {
    // Tween a tint on and off
    this.scene.tweens.addCounter({
      from: 0,
      to: 254,
      duration: 200 * this.timeScale,
      onUpdate: (tween) =>
      {
          const value = Math.floor(tween.getValue());

          this.setTint(Phaser.Display.Color.GetColor(value, 0, 0));
      },   
      onComplete: () => this.tintComplete()  
    });
  }

  flashGreen() {
    // Tween a tint on and off
    this.scene.tweens.addCounter({
      from: 0,
      to: 254,
      duration: 200 * this.timeScale,
      onUpdate: (tween) =>
      {
          const value = Math.floor(tween.getValue());

          this.setTint(Phaser.Display.Color.GetColor(0, value, 0));
      },   
      onComplete: () => this.tintComplete()  
    });
  }

  flashYellow() {
    // Tween a tint on and off
    this.scene.tweens.addCounter({
      from: 0,
      to: 254,
      duration: 200 * this.timeScale,
      onUpdate: (tween) =>
      {
          const value = Math.floor(tween.getValue());

          this.setTint(Phaser.Display.Color.GetColor(value, value, 0));
      },   
      onComplete: () => this.tintComplete()  
    });
  }
}

export default Card;