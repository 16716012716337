import classes from './MobileLeaderboardDisplay.module.css';
import { ILeaderboard } from '../../../store/types/gameTypes';
import medalImage from '../../../assets/images/general/icons/icon_trophy.png';

import fglImage from '../../../assets/images/general/fgl-token.png';
import heroIcon from '../../../assets/images/general/icon_hero.png';
import villainIcon from '../../../assets/images/general/icon_villain.png';

import MobileLeaderboardCardSmall from './MobileLeaderboardCardSmall/MobileLeaderboardCardSmall';

interface LeaderboardDisplayProps {
  leaderboard:ILeaderboard | undefined;
  playerName:string | undefined;
  isWeekly:boolean | undefined;  
}

const MobileLeaderboardDisplay = ( {leaderboard, playerName, isWeekly}:LeaderboardDisplayProps ) => {  
  let output:any = <div>Loading Leaderboard</div>;
  if (leaderboard) {
    output = 
      leaderboard.entries.map( ( entry, index)  => (
        <div key={'leaderboard' + index} className={playerName === entry.name ? classes['leaderboard-item-highlight'] : classes['leaderboard-item']}>
          <div className={classes['leaderboard-top-row']}>
            <div className={classes['leaderboard-top-row-left']}>
              <div className={classes['leaderboard-number']}>{index+1}.</div>
              <div className={classes['leaderboard-value']}>
                {!isWeekly ?
                  <img src={medalImage} className={classes['medal-image']} />
                : 
                  <img src={medalImage} className={classes['medal-image']} /> 
                }
                <div className={classes['leaderboard-medal-count']}>{entry.value}</div>                
              </div>
            </div>
            <div className={classes['leaderboard-name']}>{entry.name}
              {entry.name !== entry.wallet ?
                <>&nbsp;({entry.wallet})</>
              : null }
            </div>
          </div>
          <div className={classes['leaderboard-sections']}>     
            {leaderboard.startTime < Date.now() ?
              <div className={classes['leaderboard-bottom-row']}>
                <div className={classes['leaderboard-fleet']}>
                  <img className={classes['faction-hero']} src={heroIcon} alt='Hero' />   
                  {entry.heroes.map( ( card, cardIndex ) => (
                      <MobileLeaderboardCardSmall key={cardIndex} rank={index} cardIndex={cardIndex}
                        card={card} faction="heroes" />
                    ))
                  }                      
                </div>
                <div className={classes['leaderboard-fleet']}>
                  <img className={classes['faction-villain']} src={villainIcon} alt='Villain' />
                  {entry.villains.map( ( card, cardIndex ) => (
                      <MobileLeaderboardCardSmall key={cardIndex} rank={index} cardIndex={cardIndex}
                        card={card} faction='villains' />
                    ))
                  }                      
                </div>               
              </div>     
            : null } 
            <div className={classes['leaderboard-prizes']}>               
              {entry.prize.fgl > 0 ?
                <div className={classes['leaderboard-prizes-top']}>  
                  <div className={classes['leaderboard-prize-holder-horizontal']}>
                    <div className={classes['leaderboard-prize-text-flex-fgl']}>{entry.prize.fgl}</div>              
                    <img alt="Wax" src={fglImage} className={classes['fgl-image']} />                            
                  </div>
                </div>
              : null
              }                    
              {entry.prize.tournamentPrizes.length > 0 ?
                entry.prize.tournamentPrizes.map( ( item, index ) => (                             
                  <div key={index} className={classes['leaderboard-prize-holder']}>                                   
                    <img alt="Prize" src={item.url} className={classes['ship-image']} />                    
                    <div className={classes['leaderboard-prize-text']}>{item.count}</div>                                                   
                  </div>
                ))
              : null }              
            </div>                  
          </div>  
        </div>
      ));
  }  
  
  return output;  
};

export default MobileLeaderboardDisplay;
