import classes from './LeaderboardDisplay.module.css';
import { ILeaderboard } from '../../store/types/gameTypes';
import medalImage from '../../assets/images/general/icons/icon_trophy.png';

import {ReactComponent as WeeklyMedalIcon} from '../../assets/svg/medal.svg';
import fglImage from '../../assets/images/general/fgl-token.png';

import heroIcon from '../../assets/images/general/icon_hero.png';
import villainIcon from '../../assets/images/general/icon_villain.png';

import LeaderboardCardSmall from './LeaderboardFleetSmall/LeaderboardCardSmall';


interface LeaderboardDisplayProps {
  leaderboard:ILeaderboard | undefined;
  playerName:string | undefined;
  isWeekly:boolean | undefined;  
}

const LeaderboardDisplay = ( {leaderboard, playerName, isWeekly}:LeaderboardDisplayProps ) => {  
  let output:any = <div>Loading Leaderboard</div>;
  if (leaderboard) {
    output = 
      leaderboard.entries.map( ( entry, rankIndex)  => (
        <div key={'leaderboard' + rankIndex} className={playerName === entry.name ? classes['leaderboard-item-highlight'] : classes['leaderboard-item']}>
          <div className={classes['leaderboard-number']}>{rankIndex+1}</div>                    
          {entry.avatarId > 0 ?
            <img alt={entry.avatarClass} src={'https://cdn.cosmicclash.io/avatars/avatar_' + entry.avatarId + '.png'} className={classes['avatar-icon']} />
          :
            <div className={classes['avatar-icon-placeholder']}>&nbsp;</div>
          }        
          <div className={classes['leaderboard-value']}>
            <div className={classes['leaderboard-medal-count']}>{entry.value}</div>
            {isWeekly ?
              <WeeklyMedalIcon className={classes['medal-icon']} />   
            :
              <img alt="Medals" src={medalImage} className={classes['medal-image']} />
            }
          </div>
        <div className={classes['leaderboard-fleet-holder']}>  
          {entry.name.length > 15 ?
            <div className={classes['leaderboard-name-tiny']}>{entry.name}</div>  
          :
            <div className={classes['leaderboard-name']}>{entry.name}</div>  
          }
          <div className={classes['leaderboard-name-tiny']}>({entry.wallet})</div>  
        </div>
        <div className={classes['leaderboard-fleet-holder']}>
          <div className={classes['leaderboard-fleet']}>
            <img className={classes['faction-hero']} src={heroIcon} alt='Hero' />   
            {entry.heroes.map( ( card, cardIndex ) => (
                <LeaderboardCardSmall key={cardIndex} rank={rankIndex} cardIndex={cardIndex}
                  card={card} faction="heroes" />
              ))
            }                      
          </div>
          <div className={classes['leaderboard-fleet']}>
            <img className={classes['faction-villain']} src={villainIcon} alt='Villain' />
            {entry.villains.map( ( card, cardIndex ) => (
                <LeaderboardCardSmall key={cardIndex} rank={rankIndex} cardIndex={cardIndex}
                  card={card} faction='villains' />
              ))
            }                      
          </div>
        </div>
        
        <div className={classes['leaderboard-prizes-vertical']}> 

          {entry.prize.fgl > 0 ?
            <div className={classes['leaderboard-prizes-top']}>  
              <div className={classes['leaderboard-prize-holder-horizontal']}>
                <div className={classes['leaderboard-prize-text-flex-fgl']}>{entry.prize.fgl}</div>              
                <img alt="Wax" src={fglImage} className={classes['fgl-image']} />                            
              </div>
            </div>
          : null
          } 

          <div className={classes['leaderboard-prizes']}>                       
            {entry.prize.tournamentPrizes.length > 0 ?
              entry.prize.tournamentPrizes.map( ( item, index ) => (                             
                <div key={index} className={classes['leaderboard-prize-holder']}>                                   
                  <img alt="Prize" src={item.url} className={classes['ship-image']} />                    
                  <div className={classes['leaderboard-prize-text']}>{item.count}</div>                                                   
                </div>
              ))
            : null }            
            </div>
          </div>
        </div>
      ));
  }  
  
  return output;  
};

export default LeaderboardDisplay;
