import classes from './SimulatorCardDetail.module.css';
import ReactTooltip from "react-tooltip";

import projectileIcon from '../../../assets/images/general/icons/weapon-projectile.png';
import missileIcon from '../../../assets/images/general/icons/weapon-missile.png';
import armorIcon from '../../../assets/images/general/icons/icon_health.png';
import shieldsIcon from '../../../assets/images/general/icons/icon_barrier.png';
import { ICard } from '../../../store/types/playerTypes';
import { useEffect, useState } from 'react';
import specialIcon from '../../../assets/images/general/icons/special.png';
import {ReactComponent as RocketIcon} from '../../../assets/svg/user-secret.svg';

import arrowImage from '../../../assets/images/general/arrow-left.png';
import {ReactComponent as RemoveIcon} from '../../../assets/svg/cancel-circle.svg';
import { capitalize, indexToRarity, rarityToIndex } from '../../../hoc/CommonFunctions';

interface CardDetailProps {
  card:ICard;  
  slot:number;
  isPlayer:boolean;  
  updateSelectedRarity: Function;
  moveFunction: Function;
  removeFunction: Function;
}

const SimulatorCardDetail = ({ card, slot, isPlayer, updateSelectedRarity, moveFunction, removeFunction }:CardDetailProps) => {
  const [selectedRarity, setSelectedRarity] = useState("common");

  // When initially mounted populate with the cards rarity
  useEffect(() => {     
    if (card) {    
      setSelectedRarity(card.rarity);
    }

  }, [card]);
  
  const updateRarity = (e:any) => {            
    card.rarity = indexToRarity(e.target.selectedIndex);
    updateSelectedRarity(card.rarity);
    setSelectedRarity(card.rarity);
  }

  let weaponImage = projectileIcon;
  let weaponTipText = "Common beam weapon";

  if (card) {
    switch (card.weapon_type) {
      case "physical":
        weaponImage = projectileIcon;
        weaponTipText = "A physical attack";
        break;
      case "energy":        
        weaponImage = missileIcon;
        weaponTipText = "An Energy Weapon";
        break;                  
    }
  }

  let raritiesArray:string[] = ['common','uncommon','scarce','rare','epic','legendary','mythic','secret'];

  return (
    card ? 
    <div className={classes['wrapper']}>
      <div className={[classes['popup-content-image'],classes['card-holder-' + selectedRarity]].join(' ')}>  
        <div className={[classes['top-row'],classes['top-row-'+selectedRarity]].join(' ')}>                 
          <div className={classes['card-name']}>{card.name}</div>       
          
          <div className={classes['second-title-row']}>
            <img src={arrowImage} alt="Move Left" className={classes['move-button-left']} onClick={() => moveFunction(true, slot, isPlayer)} />
            
            <div className={classes['rarity-select']} >  
            <select onChange={(e) => updateRarity(e)}>
              {raritiesArray.map( ( rarity:string, index:number ) => (
                selectedRarity === rarity ? 
                  <option selected key={index} value={rarity}>{capitalize(rarity)}</option>              
                :                                               
                  <option key={index} value={rarity}>{capitalize(rarity)}</option>                    
              ))}                             
            </select>
            </div>

            <img src={arrowImage} alt="Move Right" className={classes['move-button-right']} onClick={() => moveFunction(false, slot, isPlayer)} />
          </div>
        </div>

        <div className={classes['card-section']}>                     
          <div className={classes['card-image']}>
            <img className={classes['card-image-inner']}  src={card.rarity_stats[rarityToIndex(selectedRarity)].image}></img>
          </div>                    
         
          <div className={classes['right-side']}>                             
            <div className={classes['stat-block']}>
              <img data-tip data-for={"weaponTip"+card.id} className={classes['stat-image']} src={weaponImage} />
              <div className={classes['stat-details']}>
                <div className={classes['stat-base-text']}>{card.rarity_stats[rarityToIndex(selectedRarity)].base_attack}</div>                                  
              </div>
              <ReactTooltip backgroundColor="rgba(113,7,116,0.8)" arrowColor="rgba(113,7,116,0.8)" className={classes['tip-text']} id={"weaponTip"+card.id} place="top" effect="solid">
                {weaponTipText}
              </ReactTooltip>
            </div>
            <div className={classes['stat-block']}>
              <img                  
                data-tip data-for="shieldTip"     
                className={classes['stat-image']} 
                src={shieldsIcon} />
              <div className={classes['stat-details']}>
                <div className={classes['stat-base-text']}>{card.rarity_stats[rarityToIndex(selectedRarity)].base_barrier}</div>                  
              </div>
              <ReactTooltip backgroundColor="rgba(113,7,116,0.8)" arrowColor="rgba(113,7,116,0.8)" className={classes['tip-text']} id="shieldTip" place="top" effect="solid">
                Barriers block energy weapons
              </ReactTooltip>
            </div>
            <div className={classes['stat-block']}>
              <img 
                data-tip data-for="armorTip"   
                className={classes['stat-image']} 
                src={armorIcon} />
              <div className={classes['stat-details']}>
                <div className={classes['stat-base-text']}>{card.rarity_stats[rarityToIndex(selectedRarity)].base_health}</div>                  
              </div>
              <ReactTooltip  backgroundColor="rgba(113,7,116,0.8)" arrowColor="rgba(113,7,116,0.8)" className={classes['tip-text']} id="armorTip" place="top" effect="solid">
                When your health reaches 0 you are defeated
              </ReactTooltip>
            </div>   
            <div className={classes['stat-block']}>
              <img 
                data-tip data-for={"specialTip"+card.id}   
                className={classes['stat-image']} 
                src={specialIcon} />
              <div className={classes['stat-details']}>
                <div className={classes['stat-base-text']}>
                  {card.rarity_stats[rarityToIndex(selectedRarity)].special_name !== 'none' ?
                    "Special"
                  :
                    "None"
                  }
                </div>                 
              </div>
              <ReactTooltip backgroundColor="rgba(113,7,116,0.8)" arrowColor="rgba(113,7,116,0.8)" className={classes['tip-text']} id={"specialTip"+card.id}  place="top" effect="solid">
                <span className={classes['stat-details-small-bold']}>Special:</span><br/>{card.rarity_stats[rarityToIndex(selectedRarity)].special_description}
              </ReactTooltip>
            </div>                 
          </div>                                       
        </div>     

        <RemoveIcon onClick={() => removeFunction(slot, isPlayer)} className={classes['remove-icon']} />            
      </div>                               
    </div>  
    :

      <div className={classes['slot-empty']}>
        <div className={classes['slot-empty-text']}>- Unassigned -</div>
        <RocketIcon  className={classes['rocket-icon']} />
      </div>
  );    
};

export default SimulatorCardDetail;