import classes from './BuyinConfirmation.module.css';
import {ReactComponent as CrossIcon} from '../../assets/svg/cross.svg';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { commenceBuyinPreFlight } from '../../store/actions/playerActions';
import { useEffect, useState } from 'react';
import { hideBuyinConfirmation, setGeneralBusyMessage } from '../../store/actions/gameActions';

import { GameState } from '../../store/types/gameTypes';
import { getGameStateSelector } from '../../store/selectors/gameSelectors';
import { PlayerState } from '../../store/types/playerTypes';
import { getPlayerStateSelector } from '../../store/selectors/playerSelectors';
import fglToken from '../../assets/images/general/fgl-token.png';

const BuyinConfirmation = () => {    
  const dispatch = useDispatch();

  const gameState:GameState = useSelector(getGameStateSelector);  
  const [leaderboardIndex, setLeaderboardIndex] = useState(0);

  let clicksEnabled = true;

  // Update the current leaderbaord index with the mode
  useEffect(() => {    
    if (gameState.gameMode === 'seasonal') {
      setLeaderboardIndex(0);
    } else {
      setLeaderboardIndex(1);
    }      
      
  }, [ gameState.gameMode ]);
 
  // Sign up!
  const clickConfirm = () => {
    if (clicksEnabled && gameState.game){
      clicksEnabled=false;

      // Show a "waiting" message until we receive a response
      dispatch(setGeneralBusyMessage("Validating Submission..."));

      // OT Station owners get free bronze
      let entryFee:number = gameState.game?.leaderboards[leaderboardIndex].entryFee;
                  
      // Do the pre-flight check      
      dispatch(commenceBuyinPreFlight(parseFloat(entryFee.toFixed(4)),gameState.heroes, gameState.heroRarities, gameState.villains, gameState.villainRarities, 
        gameState.gameMode === 'weekly' ? false : true));      
       
      // Hide this popup
      dispatch(hideBuyinConfirmation());    
    }
  }

  // Close this popup
  const closePopup = () => {
    dispatch(hideBuyinConfirmation());
  }

  return (
    <div className={classes['main-content']}>           
      <div className={classes['popup-content-image']}>                
        <div className={classes['popup-title']}>Tournament Sign Up</div>                       
               
       
        <div className={classes['message-details-row']}>
          <div className={classes['message-details-title']}>Entry Fee:</div>
          
          <div className={classes['message-details-value-token']}>
            <div className={classes['message-details-value']}>
              {gameState.game?.leaderboards[leaderboardIndex].entryFee}              
            </div>
            <img alt="FGL Tokens" src={fglToken} className={classes['token-image']} /> 
          </div>            
        </div>   
                                                 
        <div className={classes['confirm-popup-button-holder']}>
          <button className={classes['popup-button-cancel']} onClick={() => closePopup()}>Cancel</button>          
          <button className={classes['popup-button']} onClick={() => clickConfirm()}>Sign Up</button>
        </div>
      </div>      
    </div>
  );  
}

export default BuyinConfirmation;