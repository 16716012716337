import classes from './PlayRankings.module.css';

import LeftNav from "../General/LeftNav/LeftNav";
import FleetManagementInterface from "../General/FleetManagementInterface/FleetManagementInterface";

const PlayRankings = () => {
 
  return (    
    <div className={classes['main-content']}>
      <LeftNav />
      <div className={classes['center-content']}>  
        <FleetManagementInterface currentModule="rankings" />                             
      </div>
    </div>           
  );
};

export default PlayRankings;