import classes from './SimulatorResultsPopup.module.css';
import {ReactComponent as CrossIcon} from '../../../assets/svg/cross.svg';
import { Link, useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';

import { hideSimulatorResults } from '../../../store/actions/playerActions';

interface SimulatorResultsProps {
  wins:number;
  losses:number;
  averageRounds:number;
  averageSteps:number;
}

const SimulatorResultsPopup = ({ wins, losses, averageRounds, averageSteps }:SimulatorResultsProps) => {    
  const dispatch = useDispatch();

  // Close this popup
  const hideResultsClick = () => {
    dispatch(hideSimulatorResults());    
  }

  return (
    <div onClick={() => hideResultsClick()} className={classes['main-content']}>           
      <div className={classes['popup-content-image']}>                                      
        <div className={classes['popup-title']}>Simulation Complete</div>                       
       
        <div className={classes['winner-text']}>Win Percentage:</div>
        <div className={classes['winner-text-white']}>{((wins/(wins+losses))*100).toFixed(2)}%</div>
        
        <div className={classes['winner-group']}>
          <div className={classes['winner-result']}>Wins:&nbsp;</div>
          <div className={classes['winner-result']}>{wins}</div>
        </div>

        <div className={classes['winner-group']}>
          <div className={classes['winner-result']}>Losses:&nbsp;</div>
          <div className={classes['winner-result']}>{losses}</div>
        </div>  

        <div className={classes['winner-group']}>
          <div className={classes['winner-result']}>Average Rounds:&nbsp;</div>
          <div className={classes['winner-result']}>{averageRounds}</div>
        </div>  

        <div className={classes['winner-group']}>
          <div className={classes['winner-result']}>Average Steps:&nbsp;</div>
          <div className={classes['winner-result']}>{averageSteps}</div>
        </div>         
      </div>
    </div>
  );
};

export default SimulatorResultsPopup;