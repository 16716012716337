import classes from './FatalErrorModal.module.css';
import {ReactComponent as CrossIcon} from '../../../assets/svg/cross.svg';

interface FatalErrorProps {
  message: string;
}

const FatalErrorModal = ( { message }:FatalErrorProps ) => {
    
  const clickOK = () => {
    window.location.href = '/';
  }

 return(
  <div className={classes['main-content']} onClick={() => clickOK()}>           
    <div className={classes['popup-content-image']}>                                           
      <div className={classes['popup-title']}>Fatal Error</div>                                   
      <div className={classes['message-text']}>{message}</div>      
      <div className={classes['button-section']}>                   
        Click anywhere to close this panel
      </div>
    </div>
  </div>
  );
};

export default FatalErrorModal;