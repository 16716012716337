import axios from "../../axios-cc";
import { AxiosResponse } from "axios";
import { all, call, put, takeLatest } from "redux-saga/effects";

import { getPlayerDataSuccess, getPlayerDataFailure, hideBattleResults, showBattleResults, commencePracticeBattleSuccess, commenceBuyin, commenceBuyinSuccess, commenceTournamentBattleSuccess, commenceSimulatorSuccess, presetSuccess, commenceBattleFailure } from "../actions/playerActions"
import { COMMENCE_BUYIN, COMMENCE_BUYIN_PREFLIGHT, COMMENCE_PRACTICE_BATTLE, COMMENCE_SIMULATOR_BATTLE, COMMENCE_TOURNAMENT_BATTLE, GET_PLAYER_DATA_REQUEST, PRESET_DELETE, PRESET_SAVE } from "../actions/actionTypes";
import { IBattlePayload, IPlayerPayload, IPreFlightPayload, CommencePracticeBattleRequest, IPracticeBattlePayload, CommenceBuyinPreFlightRequest, CommenceBuyinRequest, CommenceTournamentBattleRequest, CommenceSimulatorBattleRequest, ISimulatorPayload, PresetSaveRequest, IPresetPayload, PresetDeleteRequest } from "../types/playerTypes";
import { getGameDataSuccess, setGeneralBusyMessage, setGeneralError, setSigningTransaction, setWaxErrorMessage } from "../actions/gameActions";
import { buyinTournament } from "../service/ualService";

const getPlayerDetails = () =>
  axios.get<IPlayerPayload>("/api/bch-player-data");
const commenceBuyinPreFlightCall = ( cost:number, heroes:number[], heroRarities:string[], villains:number[], villainRarities:string[], gold:boolean ) =>
  axios.post<IPreFlightPayload>("/api/bch-buyin-preflight", { cost:cost, heroes: heroes, heroRarities:heroRarities, villains: villains, villainRarities: villainRarities, gold:gold });  
const commenceBuyinCall = ( transactionId:any, heroes:number[], heroRarities:string[], villains:number[], villainRarities:string[], gold:boolean ) =>
  axios.post<IBattlePayload>("/api/bch-buyin", { transactionId: transactionId, heroes: heroes, heroRarities:heroRarities, villains: villains, villainRarities: villainRarities, gold:gold });  
const commenceTournamentBattleCall = ( heroes:number[], heroRarities:string[], villains:number[], villainRarities:string[], rematch:boolean, gold: boolean) =>
  axios.post<IBattlePayload>("/api/bch-tournament-battle", { heroes: heroes, heroRarities:heroRarities, villains: villains, villainRarities: villainRarities, rematch:rematch, gold:gold});  
  
const commenceSimulatorBattleCall = ( 
  simulations: number,
  playerHeroes: number[],
  playerRarities: string[],
  opponentVillains: number[],
  opponentRarities: string[]  
) =>
  axios.post<ISimulatorPayload>("/api/bch-commence-simulator-battle", { 
    simulations: simulations,
    playerHeroes: playerHeroes,
    playerRarities: playerRarities, 
    opponentVillains: opponentVillains, 
    opponentRarities: opponentRarities
  }); 

const commencePracticeBattleCall = ( heroes:number[], heroRarities:string[], villains:number[], villainRarities:string[], weekly: boolean) =>
  axios.post<IPracticeBattlePayload>("/api/bch-commence-practice-battle", { heroes: heroes, heroRarities:heroRarities, villains: villains, villainRarities: villainRarities, weekly:weekly });    

const presetSaveCall = ( name:string, heroes:number[], heroRarities:string[], villains:number[], villainRarities:string[]) =>
  axios.post<IPresetPayload>("/api/bch-preset-operation", { operation: "save", name: name, heroes: heroes, heroRarities:heroRarities, villains: villains, villainRarities: villainRarities });    
const presetDeleteCall = ( index: number ) =>
  axios.post<IPresetPayload>("/api/bch-preset-operation", { operation: "delete", index: index });    

/*
  Worker Saga: Fired on GET_GAME_DATA_REQUEST action
    playerId - the WAX ID for the player to retrieve (TODO - change this to playfab ID?)
*/
function* getPlayerDetailsSaga() {
  try {
    const response: AxiosResponse<IPlayerPayload> = yield call(getPlayerDetails);

    // Clear the general message
    yield put (setGeneralBusyMessage(""));

    if (response.data.error || response.data.fatalError) {
      yield put(
        getPlayerDataFailure({
          error: response.data.error,
          fatalError: response.data.fatalError,
        })
      );
    } else {
      yield put(
        getPlayerDataSuccess({
          playerDetails: response.data.player,
        })
      );
      yield put(
        getGameDataSuccess({
          gameDetails: response.data.game,
        })
      );
    }
  } catch (e:any) {
    yield put(
      getPlayerDataFailure({
        error: "",
        fatalError: e.message,
      })
    );
  }
}

// Commence the battle!
function* commenceTournamentBattleSaga( action: CommenceTournamentBattleRequest ) {
  // Send this result to the server to start the battle!
  try {
    const response: AxiosResponse<IBattlePayload> = yield call(commenceTournamentBattleCall, action.heroes, 
      action.heroRarities, action.villains, action.villainRarities, action.rematch, action.gold);    
    if (response.data.message && response.data.message === "success") {
      // Success!

      // Navigate to showing the battle if not skipping
      if (action.skipSimulation === false) {
        action.history.push('/show-battle');
      } else {
        // Just show the results
        yield put(showBattleResults());
      }

      // Clear out message
      yield put(setGeneralBusyMessage(""));

      // Update our player data
      yield put(
        commenceTournamentBattleSuccess({
          playerDetails: response.data.player,          
          battleResult: response.data.result,
          medalsWon: response.data.medalsWon         
        })          
      );

      // Also update the game
      yield put(
        getGameDataSuccess({
          gameDetails: response.data.game,
        })
      );  
      
    } else {
      // Clear out the busy
      yield put(
        setGeneralBusyMessage("")
      ); 
    
      // Fatal or regular error?
      if (response.data.fatalError) {
        yield put(
          commenceBattleFailure({
            fatalError: response.data.fatalError,
            error: "",        
          })
        ); 
      } else {
        // This is just a minor error, just pop up a warning
        yield put(setGeneralError(response.data.error)); 
      }
      
      // Hide battle results
      yield put(
        hideBattleResults()
      ); 
    }
  } catch (e) {     
    yield put(
      commenceBattleFailure({
        fatalError: "Error starting the battle, please contact support [502a]",
        error: "",        
      })
    ); 
  }    
}

// Commence the buyin!
function* commenceBuyinPreFlightSaga( action: CommenceBuyinPreFlightRequest ) {
  // Send this result to the server to buyin to the tournament
  try {
    const response: AxiosResponse<IPreFlightPayload> = yield call(commenceBuyinPreFlightCall,action.cost, action.heroes, 
      action.heroRarities, action.villains, action.villainRarities, action.gold);    
    if (response.data.message && response.data.message === "success") {
      // Success!
      
      // Clear out the busy
      yield put(
        setGeneralBusyMessage("")
      ); 

      // Pop up transaction  
      let transactionResult = "0";   
      if (action.cost > 0) {
        // Show a new cancellable message about signing
        yield put(setSigningTransaction("Signing Transaction..."));

        // Now send to WAX
        let { message, error, result } = yield new Promise(resolve => {
          resolve(buyinTournament( action.cost ));        
        });

        // Clear the signing message
        yield put(setSigningTransaction(""));

        // If it fails, show an error message      
        if (!result || message !== "success") {        
          // This is just a minor error, just pop up a warning
          yield put(setWaxErrorMessage(error)); 

          // Clear out the busy
          yield put(
            setGeneralBusyMessage("")
          ); 

          // Hide battle results
          yield put(
            hideBattleResults()
          ); 

          // Return
          return;
        }

        // Set the result
        transactionResult = result;
      } 
      
      // Change the message to buying in
      yield put(setGeneralBusyMessage("Signing Up..."));

      // Now send the "real" commence buyin with the transaction result
      yield put(commenceBuyin(transactionResult, action.heroes, 
        action.heroRarities, action.villains, action.villainRarities, action.gold));
      
    } else {
      // Clear out the busy
      yield put(
        setGeneralBusyMessage("")
      ); 
    
      // Fatal or regular error?
      if (response.data.fatalError) {
        yield put(
          commenceBattleFailure({
            fatalError: response.data.fatalError,
            error: "",        
          })
        ); 
      } else {
        // This is just a minor error, just pop up a warning
        yield put(setGeneralError(response.data.error)); 
      }
      
      // Hide battle results
      yield put(
        hideBattleResults()
      ); 
    }
  } catch (e) {     
    yield put(
      commenceBattleFailure({
        fatalError: "Error starting the battle, please contact support [502a]",
        error: "",        
      })
    ); 
  }    
}

// Commence the buy in!
function* commenceBuyinSaga( action: CommenceBuyinRequest ) {
  // Send this result to the server 
  try {
    const response: AxiosResponse<IBattlePayload> = yield call(commenceBuyinCall,action.transactionResult, action.heroes, 
      action.heroRarities, action.villains, action.villainRarities, action.gold);        
    if (response.data.message && response.data.message === "success") {
      // Success!
    
      // Clear out the "busy" flag
      yield put(
        setGeneralBusyMessage("")
      );
       
      // Update our player data
      yield put(
        commenceBuyinSuccess({
          playerDetails: response.data.player          
        })          
      );

      // Also update the game
      yield put(
        getGameDataSuccess({
          gameDetails: response.data.game,
        })
      );      

    } else {        
      yield put(
        commenceBattleFailure({
          error: response.data.error, 
          fatalError: response.data.fatalError,         
        })
      ); 
    }
  } catch (e) {   
    console.log("err", e);  
    yield put(
      commenceBattleFailure({
        fatalError: "Error entering the tournament, please contact support [502b]",        
        error: ""
      })
    ); 
  }    
}

// Commence the simulator battle!
function* commenceSimulatorBattleSaga( action: CommenceSimulatorBattleRequest ) {
  // Send this result to the server to start the battle!
  try {
    const response: AxiosResponse<ISimulatorPayload> = yield call(commenceSimulatorBattleCall,
      action.simulations,
      action.playerHeroes, 
      action.playerHeroRarities, 
      action.opponentVillains, 
      action.opponentVillainRarities);        
    if (response.data.message && response.data.message === "success") {
      // Success!

      // Navigate to showing the battle if they are watching one
      let showResultPopup:boolean = true;
      if (action.simulations === 1) {     
        action.history.push('/show-battle');      
        showResultPopup = false;
      }
    
      // Clear out the "busy" flag
      yield put(
        setGeneralBusyMessage("")
      );

      // Update our results
      yield put(
        commenceSimulatorSuccess({    
          playerDetails: response.data.player,
          game: response.data.game,          
          battleResult: response.data.result,
          wins: response.data.wins,
          losses: response.data.losses,          
          showSimulationResults: showResultPopup,
          averageRounds: response.data.averageRounds,
          averageSteps: response.data.averageSteps
        })          
      );
         
    } else {        
      yield put(
        commenceBattleFailure({
          error: response.data.error, 
          fatalError: response.data.fatalError,         
        })
      ); 
    }
  } catch (e) {   
    console.log("err", e);  
    yield put(
      commenceBattleFailure({
        fatalError: "Error starting the battle, please contact support [502c]",        
        error: ""
      })
    ); 
  }    
}

// Commence the practice battle!
function* commencePracticeBattleSaga( action: CommencePracticeBattleRequest ) {
  // Send this result to the server to start the battle!
  try {
    const response: AxiosResponse<IPracticeBattlePayload> = yield call(commencePracticeBattleCall, action.heroes, 
      action.heroRarities, action.villains, action.villainRarities, action.weekly);        
    if (response.data.message && response.data.message === "success") {
      // Success!

      // Navigate to showing the battle if not skipping
      if (action.skipSimulation === false) {
        action.history.push('/show-battle');
      } else {
        // Just show the results
        yield put(showBattleResults());
      }  
    
      // Clear out the "busy" flag
      yield put(
        setGeneralBusyMessage("")
      );

      // Update our player data
      yield put(commencePracticeBattleSuccess({ 
        battleResult: response.data.result,
        heroes: response.data.heroes,
        heroRarities: response.data.heroRarities,
        villains: response.data.villains,
        villainRarities: response.data.villainRarities,
        practiceSeasonal: response.data.practiceSeasonal  
      }));
      
    } else {        
      yield put(
        commenceBattleFailure({
          error: response.data.error, 
          fatalError: response.data.fatalError,         
        })
      ); 
    }
  } catch (e) {   
    console.log("err", e);  
    yield put(
      commenceBattleFailure({
        fatalError: "Error starting the battle, please contact support [502p]",        
        error: ""
      })
    ); 
  }    
}

// Save a preset
function* presetSaveSaga( action: PresetSaveRequest) {  
  try {
    const response: AxiosResponse<IPresetPayload> = yield call(presetSaveCall, action.name, action.heroes, 
      action.heroRarities, action.villains, action.villainRarities);        
    if (response.data.message && response.data.message === "success") {
      // Success!
            
      // Clear out the "busy" flag
      yield put(
        setGeneralBusyMessage("")
      );

      // Update our player data
      yield put(presetSuccess({ 
        playerDetails: response.data.player 
      }));
      
    } else {        
      yield put(
        commenceBattleFailure({
          error: "Unable to Save Preset", 
          fatalError: "",         
        })
      ); 
    }
  } catch (e) {   
    console.log("err", e);  
    yield put(
      commenceBattleFailure({
        error: "Unable to Save Preset", 
        fatalError: "",         
      })
    ); 
  }    
}

// Save a preset
function* presetDeleteSaga( action: PresetDeleteRequest) {  
  try {
    const response: AxiosResponse<IPresetPayload> = yield call(presetDeleteCall, action.index);        
    if (response.data.message && response.data.message === "success") {
      // Success!
            
      // Clear out the "busy" flag
      yield put(
        setGeneralBusyMessage("")
      );

      // Update our player data
      yield put(presetSuccess({ 
        playerDetails: response.data.player 
      }));
      
    } else {        
      yield put(
        commenceBattleFailure({
          error: "Unable to Save Preset", 
          fatalError: "",         
        })
      ); 
    }
  } catch (e) {   
    console.log("err", e);  
    yield put(
      commenceBattleFailure({
        error: "Unable to Save Preset", 
        fatalError: "",         
      })
    ); 
  }    
}

/*
  Starts worker saga on latest dispatched `FETCH_TODO_REQUEST` action.
  Allows concurrent increments.
*/
function* playerSaga() {
  yield all([takeLatest(GET_PLAYER_DATA_REQUEST, getPlayerDetailsSaga)]);
  yield all([takeLatest(COMMENCE_PRACTICE_BATTLE, commencePracticeBattleSaga)]);
  yield all([takeLatest(COMMENCE_BUYIN_PREFLIGHT, commenceBuyinPreFlightSaga)]);
  yield all([takeLatest(COMMENCE_BUYIN, commenceBuyinSaga)]);
  yield all([takeLatest(COMMENCE_TOURNAMENT_BATTLE, commenceTournamentBattleSaga)]);
  yield all([takeLatest(COMMENCE_SIMULATOR_BATTLE, commenceSimulatorBattleSaga)]);
  yield all([takeLatest(PRESET_SAVE, presetSaveSaga)]);
  yield all([takeLatest(PRESET_DELETE, presetDeleteSaga)]);
}

export default playerSaga;