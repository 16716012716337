import { combineReducers } from "redux";

import userReducer from "../reducers/userReducer";
import gameReducer from "./gameReducer";
import playerReducer from "./playerReducer";

const rootReducer = combineReducers({
  user: userReducer,
  game: gameReducer,
  player: playerReducer
});

export type AppState = ReturnType<typeof rootReducer>;

export default rootReducer;