import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import "./index.css";
import store from "./store";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

ReactDOM.render(
  <React.StrictMode>
    <GoogleReCaptchaProvider reCaptchaKey="6LdsgVweAAAAAIglXcjkQBjQIMLKX4Y4L7cvZRaw">
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </GoogleReCaptchaProvider>
  </React.StrictMode>,
  document.getElementById("root")
);