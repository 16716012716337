import classes from './MobileSimulatorResultsPopup.module.css';
import {ReactComponent as CrossIcon} from '../../../../assets/svg/cross.svg';
import { Link, useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';

import { hideSimulatorResults } from '../../../../store/actions/playerActions';

interface SimulatorResultsProps {
  wins:number;
  losses:number;
}

const MobileSimulatorResultsPopup = ({ wins, losses }:SimulatorResultsProps) => {    
  const dispatch = useDispatch();

  // Close this popup
  const hideResultsClick = () => {
    dispatch(hideSimulatorResults());    
  }

  return (
    <div className={classes['main-content']}>           
      <div className={classes['popup-content-image']}>         
        <div onClick={() => hideResultsClick()} className={classes['cross-holder']}>         
          <CrossIcon className={classes['cross-icon']} />         
        </div>                       
        <div className={classes['popup-title']}>Simulation Complete</div>                       
       
        <div className={classes['winner-text']}>Win Percentage:</div>
        <div className={classes['winner-text-white']}>{((wins/(wins+losses))*100).toFixed(2)}%</div>
        
        <div className={classes['winner-group']}>
          <div className={classes['winner-result']}>Wins:</div>
          <div className={classes['winner-result']}>{wins}</div>
        </div>

        <div className={classes['winner-group']}>
          <div className={classes['winner-result']}>Losses:</div>
          <div className={classes['winner-result']}>{losses}</div>
        </div>
      
        <div className={classes['button-section']}>                              
          <div onClick={() => hideResultsClick()} className={classes['return-button']}>Close</div>          
        </div>
      </div>
    </div>
  );
};

export default MobileSimulatorResultsPopup;