import { useDispatch, useSelector } from "react-redux";
import classes from './Simulator.module.css';

import { getGameStateSelector } from "../../store/selectors/gameSelectors";
import { GameState, ILeaderboardEntry } from "../../store/types/gameTypes";
import { getPlayerStateSelector } from "../../store/selectors/playerSelectors";
import LeftNav from "../General/LeftNav/LeftNav";
import { ICard, ISimulationEntry, ISimulationEntryPopulated, PlayerState } from "../../store/types/playerTypes";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { setGeneralBusyMessage } from "../../store/actions/gameActions";
import { clearBattleResults, commenceSimulatorBattle } from "../../store/actions/playerActions";
import SimulatorHistoryDetail from "./SimulationHistoryDetail/SimulatorHistoryDetail";
import SimulatorCardDetail from "./SimulatorCardDetail/SimulatorCardDetail";



const Simulator = () => {
  const dispatch = useDispatch();
  let history = useHistory();

  const gameState:GameState = useSelector(getGameStateSelector);
  const playerState:PlayerState = useSelector(getPlayerStateSelector);

  const [enemyVillains, setEnemyVillains] = useState<ICard[] | null[]>([null,null,null,null,null]);
  const [friendlyHeroes, setFriendlyHeroes] = useState<ICard[] | null[]>([null,null,null,null,null]);

  const [cardsSeries3, setCardsSeries3] = useState<ICard[] | []>([]);
  
  const [enemyCount, setEnemyCount] = useState(0);
  const [friendlyCount, setFriendlyCount] = useState(0);

  const [isInitialized, setIsInitialized] = useState(false);

  const [viewingHistory, setViewingHistory] = useState(false);
  const [simulationHistory, setSimulationHistory] = useState<ISimulationEntryPopulated[]>([]);

  const [showRematchHigh, setShowRematchHigh] = useState(false);
  const [showRematchLow, setShowRematchLow] = useState(false);  

  const [currentEnemySeasonalIndex, setCurrentEnemySeasonalIndex] = useState(-1);
  const [currentEnemyWeeklyIndex, setCurrentEnemyWeeklyIndex] = useState(-1);

  // When initially mounted populate with the last run simulation
  useEffect(() => {         
    if (!isInitialized && playerState.player && playerState.player.simulationHistoryBCH && playerState.player.simulationHistoryBCH.length > 0 
        && gameState.game && gameState.game.cardDefinitions) {
      let simList:ISimulationEntry[] = [...playerState.player?.simulationHistoryBCH];
      simList.sort((a, b) => (a.date > b.date) ? 1 : -1);
      
      // Populate using the last one
      let latestSim:ISimulationEntry = simList[simList.length-1];
      let fCards:any[] = [];
      let eCards:any[] = [];

      for (let i:number = 0; i < latestSim.friendlyHeroes.length; i++) {
        if (latestSim.friendlyHeroes[i] > 0 && latestSim.friendlyHeroRarities[i] !== "") {
          for (let j:number = 0; j < gameState.game?.cardDefinitions.length; j++) {
            let card:ICard = gameState.game?.cardDefinitions[j];
            if (card.id === latestSim.friendlyHeroes[i]) {
              let cardCopy:any = JSON.parse(JSON.stringify(gameState.game?.cardDefinitions[j]));
              cardCopy.rarity = latestSim.friendlyHeroRarities[i];
              fCards.push(cardCopy);
            }
          }
        }
      }

      for (let i:number = 0; i < latestSim.enemyVillains.length; i++) {
        if (latestSim.enemyVillains[i] > 0 && latestSim.enemyVillainRarities[i] !== "") {
          for (let j:number = 0; j < gameState.game?.cardDefinitions.length; j++) {
            let card:ICard = gameState.game?.cardDefinitions[j];
            if (card.id === latestSim.enemyVillains[i]) {
              let cardCopy:any = JSON.parse(JSON.stringify(gameState.game?.cardDefinitions[j]));
              cardCopy.rarity = latestSim.enemyVillainRarities[i];
              eCards.push(cardCopy);
            }
          }
        }
      }

      // Add nulls if the sets are not full
      for (let i:number = fCards.length; i < 5; i++) {
        fCards.push(null);
      }
      for (let i:number = eCards.length; i < 5; i++) {
        eCards.push(null);
      }

      // Convert the sim history to full cards
      buildSimulationHistory();
           
      // Set the single entry
      setFriendlyHeroes(fCards);
      setFriendlyCount(fCards.length);
      setEnemyVillains(eCards);
      setEnemyCount(eCards.length);
      
      setIsInitialized(true);
    }
  }, [ playerState.player?.simulationHistoryBCH, gameState.game?.cardDefinitions ]);

  // Update the sim history when needed
  useEffect(() => {             
    // Convert the sim history to full cards
    buildSimulationHistory();
               
  }, [ playerState.player?.simulationHistoryBCH ]);

  // See if we need to populate rematch buttons  
  useEffect(() => {   
    if (playerState.player) {
      if (playerState.player.rematch !== "") {
        setShowRematchHigh(true);
      } else {
        setShowRematchHigh(false);
      }

      if (playerState.player.rematch_weekly !== "") {
        setShowRematchLow(true);
      } else {
        setShowRematchLow(false);
      }
    }

  }, [ playerState.player?.rematch, playerState.player?.rematch_weekly ]);

  // Setup all the card lists by series
  useEffect(() => {   
    let tempSeries3:ICard[] = [];    
    if (gameState.game?.cardDefinitions) {
      for (let i:number = 0; i < gameState.game?.cardDefinitions.length; i++) {
        let card:ICard = gameState.game?.cardDefinitions[i];
        tempSeries3.push(card);
      }      
    }
    
    // Set them
    setCardsSeries3(tempSeries3);    

  }, [ gameState.game?.cardDefinitions ]);

  // Setup all the card lists by rarity
  useEffect(() => {   
    // Count how many of each cards have been set up
    let tempFriendlyCount:number = 0;
    for (let i:number = 0; i < friendlyHeroes.length; i++) {
      if (friendlyHeroes[i]) {
        tempFriendlyCount++;
      } 
    }

    let tempEnemyCount:number = 0;
    for (let i:number = 0; i < enemyVillains.length; i++) {
      if (enemyVillains[i]) {
        tempEnemyCount++;
      } 
    }

    setFriendlyCount(tempFriendlyCount);
    setEnemyCount(tempEnemyCount);

  }, [ friendlyHeroes, enemyVillains ]);

  const buildSimulationHistory = () => {
    if (playerState.player && playerState.player.simulationHistoryBCH && playerState.player.simulationHistoryBCH.length > 0 
      && gameState.game && gameState.game.cardDefinitions) {
      
      let simList:ISimulationEntry[] = [...playerState.player?.simulationHistoryBCH];
      simList.sort((a, b) => (a.date > b.date) ? 1 : -1);
      
      let populatedSimList:ISimulationEntryPopulated[] = [];
      let entry:ISimulationEntryPopulated;
      for (let i: number = 0; i < simList.length; i++) {
        // Start a new entry
        entry = {date: simList[i].date, friendlyHeroes: [], enemyVillains: []};

        // Build the friendly cards
        for (let j:number = 0; j < simList[i].friendlyHeroes.length; j++) {
          if (simList[i].friendlyHeroes[j] > 0 && simList[i].friendlyHeroRarities[j] !== "") {
            for (let k:number = 0; k < gameState.game?.cardDefinitions.length; k++) {
              let card:ICard = gameState.game?.cardDefinitions[k];
              if (card.id === simList[i].friendlyHeroes[j]) {
                let cardCopy:any = JSON.parse(JSON.stringify(card));
                cardCopy.rarity = simList[i].friendlyHeroRarities[j];
                entry.friendlyHeroes.push(cardCopy);
              }
            }
          }
        }
  
        // Build the enemy cards
        for (let j:number = 0; j < simList[i].enemyVillains.length; j++) {
          if (simList[i].enemyVillains[j] > 0 && simList[i].enemyVillainRarities[j] !== "") {
            for (let k:number = 0; k < gameState.game?.cardDefinitions.length; k++) {
              let card:ICard = gameState.game?.cardDefinitions[k];
              if (card.id === simList[i].enemyVillains[j]) {
                let cardCopy:any = JSON.parse(JSON.stringify(card));
                cardCopy.rarity = simList[i].enemyVillainRarities[j];
                entry.enemyVillains.push(cardCopy);
              }
            }
          }
        }

        // Add this entry
        populatedSimList.push(entry);
      }

      // Set the full history
      setSimulationHistory(populatedSimList);
    }
  }

  const viewHistory = (bShow:boolean) => {    
    // Watch a single battle    
    setViewingHistory(bShow);    
  }

  const resetCards = (isPlayer:boolean) => {
    if (isPlayer) {
      setFriendlyHeroes([null,null,null,null,null]);
    } else {
      setEnemyVillains([null,null,null,null,null]);
    }
  }

  const loadWeekly = (isPlayer:boolean) => {
    if (playerState.player && gameState.game) {
      // Loop through the weekly cards + add it to this one
      let tempCards:any[] = [];
      let bFound:boolean;
      let cardCopy:ICard;

      // Load the heroes into the friendly area
      if (isPlayer) {
        for (let i:number = 0; i < playerState.player.weeklyHeroes.length; i++) {        
          // Find it in the defs
          bFound = false;
          for (let j:number = 0; j < gameState.game?.cardDefinitions.length; j++) {
            if (playerState.player.weeklyHeroes[i] === gameState.game?.cardDefinitions[j].id) {
              cardCopy = JSON.parse(JSON.stringify(gameState.game?.cardDefinitions[j]));
              cardCopy.rarity = playerState.player.weeklyHeroRarities[i];
              tempCards.push(cardCopy);
              bFound = true;
            }
          }                 
  
          if (!bFound) {
            tempCards.push(null);
          }
        }     
               
        setFriendlyHeroes(tempCards);                
      } else {
        // Load the villains into the enemy area
        for (let i:number = 0; i < playerState.player.weeklyVillains.length; i++) {        
          // Find it in the defs
          bFound = false;
          for (let j:number = 0; j < gameState.game?.cardDefinitions.length; j++) {
            if (playerState.player.weeklyVillains[i] === gameState.game?.cardDefinitions[j].id) {
              cardCopy = JSON.parse(JSON.stringify(gameState.game?.cardDefinitions[j]));
              cardCopy.rarity = playerState.player.weeklyVillainRarities[i];
              tempCards.push(cardCopy);
              bFound = true;
            }
          }                 
  
          if (!bFound) {
            tempCards.push(null);
          }
        } 

        setEnemyVillains(tempCards);    
      }      
    }
  }

  const loadSeasonal = (isPlayer:boolean) => {
    if (playerState.player && gameState.game) {
      // Loop through the weekly cards + add it to this one
      let tempCards:any[] = [];
      let bFound:boolean;
      let cardCopy:ICard;
      
      // Load the heroes into the friendly area
      if (isPlayer) {
        for (let i:number = 0; i < playerState.player.currentHeroes.length; i++) {        
          // Find it in the defs
          bFound = false;
          for (let j:number = 0; j < gameState.game?.cardDefinitions.length; j++) {
            if (playerState.player.currentHeroes[i] === gameState.game?.cardDefinitions[j].id) {
              cardCopy = JSON.parse(JSON.stringify(gameState.game?.cardDefinitions[j]));
              cardCopy.rarity = playerState.player.currentHeroRarities[i];
              tempCards.push(cardCopy);
              bFound = true;
            }
          }                 
  
          if (!bFound) {
            tempCards.push(null);
          }
        }     
               
        setFriendlyHeroes(tempCards);                
      } else {
        // Load the villains into the enemy area
        for (let i:number = 0; i < playerState.player.currentVillains.length; i++) {        
          // Find it in the defs
          bFound = false;
          for (let j:number = 0; j < gameState.game?.cardDefinitions.length; j++) {
            if (playerState.player.currentVillains[i] === gameState.game?.cardDefinitions[j].id) {
              cardCopy = JSON.parse(JSON.stringify(gameState.game?.cardDefinitions[j]));
              cardCopy.rarity = playerState.player.currentVillainRarities[i];
              tempCards.push(cardCopy);
              bFound = true;
            }
          }                 
  
          if (!bFound) {
            tempCards.push(null);
          }
        } 

        setEnemyVillains(tempCards);    
      }      
    }
  }

  const loadRematch = (isHigh:boolean) => {
    if (playerState.player && gameState.game) {
      // Find the players cards in the leaderboard
      let entry:ILeaderboardEntry;
      let rematch:string = "";
      let boardIndex:number = 0;
      let bFound:boolean;

      if (isHigh && playerState.player.rematch !== "") {
        rematch = playerState.player.rematch;
        boardIndex = 0;
      }
      if (!isHigh && playerState.player.rematch_weekly !== "") {
        rematch = playerState.player.rematch_weekly;
        boardIndex = 1;
      }
      
      if (rematch !== "") {
        for (let i:number = 0; i < gameState.game.leaderboards[boardIndex].entries.length; i++) {
          entry = gameState.game.leaderboards[boardIndex].entries[i];
          if (entry.wallet === rematch) {
            // Find it in the defs

            // Loop through the cards + populate enemy cards 
            let tempCards:any[] = [];            
            let cardCopy:ICard;
            for (let x:number = 0; x < entry.villains.length; x++) {
              bFound = false;
              for (let j:number = 0; j < gameState.game?.cardDefinitions.length; j++) {
                if (entry.villains[x].id === gameState.game?.cardDefinitions[j].id) {
                 
                  cardCopy = JSON.parse(JSON.stringify(gameState.game?.cardDefinitions[j]));                 
                  cardCopy.rarity = entry.villains[x].rarity;
                  
                  tempCards.push(cardCopy);
                  bFound = true;
                }
              }                 
  
              if (!bFound) {
                tempCards.push(null);
              }                                     
            }     
                                  
            setEnemyVillains(tempCards);                          
          }
        }
      }
    }
  }

  const addCard = (isPlayer:boolean, card:ICard) => {
    // Add this card to the given set
    let pushed:boolean = false;    
    let tempCards:any[];
    let tempResult:any[] = [];
    let cardCopy:ICard | null = JSON.parse(JSON.stringify(card));
    if (cardCopy) {
      cardCopy.rarity = "common";
    }
    if (isPlayer) {
      tempCards = friendlyHeroes;
    } else {
      tempCards = enemyVillains;
    }

    // Make sure this card isn't already in there
    let bFound:boolean = false;
    for (let i:number = 0; i < tempCards.length; i++) {
      if (tempCards[i] && tempCards[i].id === card.id) {
        bFound = true;
      }
    }
    
    if (!bFound) {
      // Make sure its not full
      for (let i:number = 0; i < tempCards.length; i++) {
        if (tempCards[i]) {
          tempResult.push(tempCards[i]);
        } else {
          if (pushed) {
            tempResult.push(null);
          } else {
            tempResult.push(cardCopy);
            pushed = true;
          }
        }
      }
        
      if (isPlayer) {
        setFriendlyHeroes(tempResult);
      } else {
        setEnemyVillains(tempResult);        
      }
    }
  }

  const updateCardRarity = ( rarity:string, slot:number, isPlayer:boolean) => {
    let tempCards:any[] = [];
    let card:ICard | null;

    // Update the given cards
    if (isPlayer) {      
      for (let i:number = 0; i < friendlyHeroes.length; i++) {
        card = friendlyHeroes[i];
        if (i === slot && card !== null) {          
          card.rarity = rarity;          
        }

        tempCards.push(card);
      }

      setFriendlyHeroes(tempCards);
    } else {
      for (let i:number = 0; i < enemyVillains.length; i++) {        
        card = enemyVillains[i];
        if (i === slot && card !== null) {          
          card.rarity = rarity;          
        }

        tempCards.push(card);
      }

      setEnemyVillains(tempCards);
    }
  }

  const moveFunction = ( bLeft:boolean, slot:number, isPlayer:boolean) => {
    let tempCards:any[];
    if (isPlayer) {
      tempCards = [...friendlyHeroes];
    } else {
      tempCards = [...enemyVillains];
    }
        
    let newSlotId:number = slot;
    if (bLeft) {
      newSlotId--;
      if (newSlotId < 0) {
        newSlotId = 4;
      }
    } else {
      newSlotId++;
      if (newSlotId > 4) {
        newSlotId = 0;
      }
    }
    
    tempCards.splice(newSlotId, 0, tempCards.splice(slot, 1)[0]);
    
    if (isPlayer) {
      setFriendlyHeroes(tempCards);
    } else {
      setEnemyVillains(tempCards);
    }
  }

  const removeFunction = ( slot:number, isPlayer:boolean) => {
    let tempCards:any[] = [];
    if (isPlayer) {
      tempCards = [...friendlyHeroes];
    } else {
      tempCards = [...enemyVillains];
    }

    tempCards[slot] = null;
            
    if (isPlayer) {
      setFriendlyHeroes(tempCards);
    } else {
      setEnemyVillains(tempCards);
    }
  }

  const clickBattle = (sims:number) => {    
    // Show a "waiting" message until we receive a response
    dispatch(setGeneralBusyMessage("Starting Simulation ..."));

    // Clear the previous battle
    dispatch(clearBattleResults());

    // Convert cards to numbers
    let playerCards:number[] = [];
    let playerRarities:string[] = []
    let enemyCards:number[] = [];
    let enemyRarities:string[] = [];

    for (let i:number = 0; i < friendlyHeroes.length; i++) {
      let tempCard:any = friendlyHeroes[i];
      if (tempCard !== null) {
        playerCards.push(tempCard.id);
        if (tempCard.rarity) {
          playerRarities.push(tempCard.rarity);
        } else {
          playerRarities.push("common");
        }
      } else {
        playerCards.push(0);
        playerRarities.push("");
      }
    }

    for (let i:number = 0; i < enemyVillains.length; i++) {
      let tempCard:any = enemyVillains[i];
      if (tempCard) {
        enemyCards.push(tempCard.id);        
        if (tempCard.rarity) {
          enemyRarities.push(tempCard.rarity);
        } else {
          enemyRarities.push("common");
        }
      } else {
        enemyCards.push(0);
        enemyRarities.push("");
      }
    }
        
    // Start the battle
    dispatch(commenceSimulatorBattle(
      sims,
      playerCards,
      playerRarities, 
      enemyCards, 
      enemyRarities,    
      history));              
    
  }

  const loadHistory = (entry:ISimulationEntryPopulated) => {
    // Set the single entry
    setFriendlyHeroes(entry.friendlyHeroes);
    setFriendlyCount(entry.friendlyHeroes.length);
    setEnemyVillains(entry.enemyVillains);
    setEnemyCount(entry.enemyVillains.length);

    // Go back to main view
    setViewingHistory(false);
  }

  const loadLeaderboardCards = (e:any, bSeasonal:boolean) => {        
    if (playerState.player && gameState.game) {
      let selectedIndex:number = e.target.selectedIndex - 1;
      
      let tempCards:ICard[] = [];
      let entry:ILeaderboardEntry;      

      if (bSeasonal) {
        setCurrentEnemySeasonalIndex(selectedIndex);      
      } else {
        setCurrentEnemyWeeklyIndex(selectedIndex);
      }
      
      if (selectedIndex > -1) {
        if (bSeasonal) {
          entry = gameState.game.leaderboards[0].entries[selectedIndex];
        } else {
          entry = gameState.game.leaderboards[1].entries[selectedIndex];
        }
                
        // Use the card defs to build the set      
        let cardCopy:ICard;
        for (let x:number = 0; x < entry.villains.length; x++) {        
          for (let j:number = 0; j < gameState.game?.cardDefinitions.length; j++) {
            if (entry.villains[x].id === gameState.game?.cardDefinitions[j].id) {
            
              cardCopy = JSON.parse(JSON.stringify(gameState.game?.cardDefinitions[j]));                 
              cardCopy.rarity = entry.villains[x].rarity;
              
              tempCards.push(cardCopy);            
            }
          }                         
        }     
                
        // Load it into the enemy ones
        setEnemyVillains(tempCards);
        setEnemyCount(tempCards.length);        
      }
    }
  }

  return (    
    <div className={classes['main-content']}>
      <LeftNav />

      { viewingHistory ?
        <div className={classes['center-content']}>  
          <div className={classes['sim-title']}>Simulator History</div>  
          <div className={classes['sim-actions']}>            
            <div className={classes['action-button']} 
              onClick={() => viewHistory(false)}>Back</div>                        
          </div> 

          <div className={classes['sim-list']}> 
            {simulationHistory.map( ( entry:any, index:number)  => (
                  <SimulatorHistoryDetail key={index} 
                    entry={entry}
                    rowIndex={index}                     
                    loadFunction={loadHistory}
                  />
                ))}
          </div> 

        </div>

      :
        <div className={classes['center-content']}>  
          <div className={classes['sim-title']}>Battle Simulator</div>  
          <div className={classes['sim-actions']}>                      
            <div className={classes['action-button']} 
              onClick={() => clickBattle(100)}>Simulate 100</div>

            <div className={classes['action-button']} 
              onClick={() => clickBattle(1000)}>Simulate 1000</div>

            <div className={classes['action-button']} 
              onClick={() => clickBattle(1)}>Watch 1</div>

            <div className={classes['action-button']} 
              onClick={() => viewHistory(true)}>History</div>
              
          </div>  
          
          <div className={classes['player-holder']}>                            
            <fieldset className={classes['cards-block']}>
              <legend className={classes['cards-title']}>Enemy Villains</legend>
            
              <div className={classes['cards-holder']}>  
                {enemyVillains.map( ( card:any, index:number)  => (
                  <SimulatorCardDetail key={index} card={card} 
                    slot={index}
                    isPlayer={false}
                    updateSelectedRarity={updateCardRarity}
                    moveFunction={moveFunction}
                    removeFunction={removeFunction} />
                ))}
              </div>

              <div className={classes['button-row']}>                  
                <div className={classes['button-white']} 
                  onClick={() => resetCards(false)}>Reset</div>

                <div className={classes['button-white']} 
                  onClick={() => loadSeasonal(false)}>Load Current Lineup</div>
                
                {showRematchHigh  ? 
                  <div className={classes['button-white']} 
                    onClick={() => loadRematch(true)}>Load Rematch: Gold</div>
                : null}

                {showRematchLow  ? 
                  <div className={classes['button-white']} 
                    onClick={() => loadRematch(false)}>Load Rematch: Bronze</div>
                : null}

                {gameState.game && gameState.game?.leaderboards[0].startTime < Date.now() ?
                  <div className={classes['dropdown-section']}>                  
                    <select className={classes['cards-select']} onChange={(e) => loadLeaderboardCards(e, true)}>
                      {currentEnemySeasonalIndex === -1 ?
                        <option selected key={-1} value={-1}>Load Gold Villains:</option>
                      :
                        <option key={-1} value={-1}>Load Gold Villains:</option>
                      }
                      {gameState.game?.leaderboards[0].entries.map( ( entry:ILeaderboardEntry, index:number ) => (
                        index === currentEnemySeasonalIndex ? 
                          <option selected key={index} value={index}>{index+1}. {entry.name}</option>              
                        :                                               
                          <option key={index} value={index}>{index+1}. {entry.name}</option>                    
                      ))}           
                    </select>
                  </div>
                : null }
                
                {gameState.game && gameState.game?.leaderboards[1].startTime < Date.now() ?
                  <div className={classes['dropdown-section']}>                  
                    <select className={classes['cards-select']} onChange={(e) => loadLeaderboardCards(e, false)}>
                      {currentEnemyWeeklyIndex === -1 ?
                        <option selected key={-1} value={-1}>Load Bronze Villains:</option>
                      :
                        <option key={-1} value={-1}>Load Bronze Villains:</option>
                      }
                      {gameState.game?.leaderboards[1].entries.map( ( entry:ILeaderboardEntry, index:number ) => (
                        index === currentEnemyWeeklyIndex ? 
                          <option selected key={index} value={index}>{index+1}. {entry.name}</option>                                    
                        :
                          <option key={index} value={index}>{index+1}. {entry.name}</option>                    
                      ))}           
                    </select>
                  </div>
                : null }
              </div>

              {enemyCount < 5 ?
              <>
                <div className={classes['button-row']}>   
                  {cardsSeries3.map( ( card:ICard, index:number)  => (  
                    card.faction === 'villain' ?
                      <div key={index} 
                        className={[classes['button-white'],classes['button-' + card.rarity]].join(' ')} 
                        onClick={() => addCard(false, card)}>{card.name}</div>
                    : null
                  ))}
                </div>               
              </>
              : null }
            </fieldset>
          </div>

          <div className={classes['player-holder']}>                            
            <fieldset className={classes['cards-block']}>
              <legend className={classes['cards-title']}>Player Heroes</legend>
            
              <div className={classes['cards-holder']}>  
                {friendlyHeroes.map( ( card:any, index:number)  => (
                  <SimulatorCardDetail key={index} card={card} 
                    slot={index}
                    isPlayer={true}
                    updateSelectedRarity={updateCardRarity}
                    moveFunction={moveFunction}
                    removeFunction={removeFunction} />
                ))}
              </div>

              <div className={classes['button-row']}>                  
                <div className={classes['button-white']} 
                  onClick={() => resetCards(true)}>Reset</div>

                <div className={classes['button-white']} 
                  onClick={() => loadSeasonal(true)}>Load Current Lineup</div>
                                
              </div>
              {friendlyCount < 5 ?
                <>

                <div className={classes['button-row']}>   
                  {cardsSeries3.map( ( card:ICard, index:number)  => (               
                    card.faction === 'hero' ?
                      <div key={index} 
                        className={[classes['button-white'],classes['button-' + card.rarity]].join(' ')} 
                        onClick={() => addCard(true, card)}>{card.name}</div>
                    : null
                  ))}
                </div>               
                </>
              : null }
            </fieldset>
          </div>
        </div>
      }
    </div>           
  );
};

export default Simulator;