import classes from './MobileCardStatsDetail.module.css';
import ReactTooltip from "react-tooltip";

import rarityIcon from '../../../../assets/images/general/icons/icon_rarity.png';
import projectileIcon from '../../../../assets/images/general/icons/weapon-projectile.png';
import missileIcon from '../../../../assets/images/general/icons/weapon-missile.png';
import armorIcon from '../../../../assets/images/general/icons/icon_health.png';
import shieldsIcon from '../../../../assets/images/general/icons/icon_barrier.png';
import heroIcon from '../../../../assets/images/general/icon_hero.png';
import villainIcon from '../../../../assets/images/general/icon_villain.png';

import { ICard } from '../../../../store/types/playerTypes';
import { useEffect, useState } from 'react';
import { IGame } from '../../../../store/types/gameTypes';
import { indexToRarity, rarityToIndex } from '../../../../hoc/CommonFunctions';

interface CardDetailProps {
  card:ICard;
  game:IGame | null;
}

const MobileCardStatsDetail = ({ card, game }:CardDetailProps) => {
  const [selectedRarity, setSelectedRarity] = useState("common");
  const [updatedCard, setUpdatedCard] = useState<ICard | null>(null);
  
  // Update the deploy + earn stats on the card, whenever the level changes also
  useEffect(() => {   
    if (game && card) {
      let cardCopy:ICard = JSON.parse(JSON.stringify(card));
      
      // Update the stats for the new level
      cardCopy.rarity = selectedRarity;
      
      setUpdatedCard(cardCopy);
    }

  }, [ card, selectedRarity, game ]);

  const buyCard = () => {
    window.open("https://wax.atomichub.io/market?collection_name=officialhero&schema_name=series3.x&sort=price&symbol=WAX&order=asc&data:text.cardid=" + card.id + "&data:text.rarity=" + selectedRarity);
  }

  const updateRarity = (e:any) => {            
    setSelectedRarity(indexToRarity(e.target.selectedIndex));
  }

  // Configure the image
  let imgStyle = {};
  imgStyle = {
    "height": '10.5rem'    
  };

  let weaponImage = projectileIcon;
  let weaponTipText = "Common beam weapon";
  switch (card.weapon_type) {
    case "physical":
      weaponImage = projectileIcon;
      weaponTipText = "A physical attack";
      break;
    case "energy":        
      weaponImage = missileIcon;
      weaponTipText = "An Energy Weapon";
      break;                  
  }

  return (
    updatedCard ? 
    <div className={classes['wrapper']}>
      <div className={[classes['popup-content-image'],classes['card-holder-' + card.rarity_stats[rarityToIndex(selectedRarity)].rarity]].join(' ')}>  
        <div className={[classes['top-row'],classes['top-row-'+card.rarity_stats[rarityToIndex(selectedRarity)].rarity]].join(' ')}>       
          {card.faction === 'hero' ?
            <img className={classes['faction-hero']} src={heroIcon} alt='Hero' />          
          :  
            <img className={classes['faction-villain']} src={villainIcon} alt='Villain' />
          }
          <div className={classes['card-name']}>{card.name}</div>                
        </div>           
        <div className={classes['bottom-row']}>   
          <div className={classes['left-column']}>                        
            <div  className={classes['card-image']}>
              <img style={imgStyle} src={card.rarity_stats[rarityToIndex(selectedRarity)].image}></img>
            </div>                                                                                                    
          </div>
          <div className={classes['right-side']}>
            <div className={classes['stats-block']}>              
              <div className={classes['stat-row']}>
                <img data-tip data-for="levelTip" className={classes['stat-image']} src={rarityIcon} />
                <div className={classes['stat-details']}>
                  <div className={classes['stat-base-text']}>
                    <div className={classes['level-select']} >  
                      <select onChange={(e) => updateRarity(e)}>
                        {selectedRarity === 'common' ?
                          <option selected value="common">Common</option>
                        :
                          <option value="common">Common</option>
                        }
                        {selectedRarity === 'uncommon' ?
                          <option selected value="uncommon">Uncommon</option>
                        :
                          <option value="uncommon">Uncommon</option>
                        }
                        {selectedRarity === 'scarce' ?
                          <option selected value="scarce">Scarce</option>
                        :
                          <option value="scarce">Scarce</option>
                        }
                        {selectedRarity === 'rare' ?
                          <option selected value="rare">Rare</option>
                        :
                          <option value="rare">Rare</option>
                        }
                        {selectedRarity === 'epic' ?
                          <option selected value="epic">Epic</option>
                        :
                          <option value="epic">Epic</option>
                        }
                        {selectedRarity === 'legendary' ?
                          <option selected value="legendary">Legendary</option>
                        :
                          <option value="legendary">Legendary</option>
                        }
                        {selectedRarity === 'mythic' ?
                          <option selected value="mythic">Mythic</option>
                        :
                          <option value="mythic">Mythic</option>
                        }
                        {selectedRarity === 'secret' ?
                          <option selected value="secret">Secret</option>
                        :
                          <option value="secret">Secret</option>
                        }                       
                      </select>
                    </div>
                  </div>                      
                </div>   
                <ReactTooltip backgroundColor="rgba(113,7,116,0.8)" arrowColor="rgba(113,7,116,0.8)" className={classes['tip-text']} id="levelTip" place="top" effect="solid">
                  The Rarity of the Card
                </ReactTooltip>               
              </div>
              
              <div className={classes['stat-row']}>
                <img data-tip data-for={"weaponTip"+card.id} className={classes['stat-image']} src={weaponImage} />
                <div className={classes['stat-details']}>
                  <div className={classes['stat-base-text']}>{card.rarity_stats[rarityToIndex(selectedRarity)].base_attack}</div>                                      
                </div>
                <ReactTooltip backgroundColor="rgba(113,7,116,0.8)" arrowColor="rgba(113,7,116,0.8)" className={classes['tip-text']} id={"weaponTip"+card.id} place="top" effect="solid">
                  {weaponTipText}
                </ReactTooltip>
              </div>
              <div className={classes['stat-row']}>
                <img                  
                  data-tip data-for="shieldTip"     
                  className={classes['stat-image']} 
                  src={shieldsIcon} />
                <div className={classes['stat-details']}>
                  <div className={classes['stat-base-text']}>{card.rarity_stats[rarityToIndex(selectedRarity)].base_barrier}</div>                    
                </div>
                <ReactTooltip backgroundColor="rgba(113,7,116,0.8)" arrowColor="rgba(113,7,116,0.8)" className={classes['tip-text']} id="shieldTip" place="top" effect="solid">
                  Barriers block energy weapons
                </ReactTooltip>
              </div>
              <div className={classes['stat-row']}>
                <img 
                  data-tip data-for="armorTip"   
                  className={classes['stat-image']} 
                  src={armorIcon} />
                <div className={classes['stat-details']}>
                  <div className={classes['stat-base-text']}>{card.rarity_stats[rarityToIndex(selectedRarity)].base_health}</div>                   
                </div>
                <ReactTooltip  backgroundColor="rgba(113,7,116,0.8)" arrowColor="rgba(113,7,116,0.8)" className={classes['tip-text']} id="armorTip" place="top" effect="solid">
                  The health of a character
                </ReactTooltip>
              </div>                                                       
            </div>
          </div>
        </div> 
        
        <div className={classes['special-row']}>        
          <div className={classes['special-title']}>Special:</div>  
        </div>
        <div className={classes['special-row']}>                  
          <div className={classes['special-description']}>{card.rarity_stats[rarityToIndex(selectedRarity)].special_description}</div>                                                
        </div>
        
        <div onClick={() => buyCard()} className={classes['find-reward-button']}>
            Buy
        </div>                   
      </div>        
    </div>  
    :null
  );    
};

export default MobileCardStatsDetail;