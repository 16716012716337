import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import logger from "redux-logger";

import rootReducer from "./reducers/rootReducer";
import { rootSaga } from "./sagas/rootSaga";

// Create the saga middleware
const sagaMiddleware = createSagaMiddleware();

// Mount it on the Store
let store:any;
if (process.env.NODE_ENV === 'development') {
  store = createStore(rootReducer, applyMiddleware(sagaMiddleware, logger));
} else {
  store = createStore(rootReducer, applyMiddleware(sagaMiddleware));
}

// Run the saga
sagaMiddleware.run(rootSaga);

// Turn off console logs for production
if (process.env.NODE_ENV !== "development") {
  console.log = () => {};
}

export default store;