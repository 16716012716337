import classes from './BusyAutoLoginModal.module.css';
import spinnerImage from '../../../assets/images/general/loading-animation.png';
import { setBusyAutoLogin } from '../../../store/actions/gameActions';
import { useDispatch } from 'react-redux';

const BusyAutoLoginModal = () => {
  const dispatch = useDispatch();

  const close = () => { 
    // Hide the auto login
    dispatch(setBusyAutoLogin(false));
  }

  return (
    <div className={classes['wrapper']} onClick={() => close()}>
      <div className={classes['section']}>                
        <div className={classes['spritesheet-container']}>
          <img alt="Busy" className={classes['spritesheet']} src={spinnerImage}></img>
        </div>
        <div className={classes['description']}>Checking for Auto Login...</div>
        <div className={classes['description-tiny']}>(click to skip)</div>
      </div>  
    </div>                  
  );
};

export default BusyAutoLoginModal;