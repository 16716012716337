export function rarityToIndex (rarity:string) {
  let index:number = 0;
  switch (rarity) {
    case "common":        
      index = 0;
      break;  
    case "uncommon":        
      index = 1;
      break;
    case "scarce":        
      index = 2;
      break;
    case "rare":      
      index = 3;
      break;
    case "epic":
      index = 4;
      break;
    case "legendary":        
      index = 5;
      break;
    case "mythic":
      index = 6;
      break;
    case "secret":        
      index = 7;
      break;
  }

  return index;
}

export function indexToRarity (index:number) {
  let rarity:string = "";
  switch (index) {
    case 0:
      rarity = "common";
      break;  
    case 1:
      rarity = "uncommon";
      break;
    case 2:
      rarity = "scarce";
      break;
    case 3:
      rarity = "rare";
      break;
    case 4:
      rarity = "epic";
      break;
    case 5:
      rarity = "legendary";
      break;
    case 6:
      rarity = "mythic";
      break;
    case 7:
      rarity = "secret";
      break;
  }

  return rarity;
}

export function capitalize (value:string) {
  const firstLetter = value.charAt(0)
  const firstLetterCap = firstLetter.toUpperCase()
  const remainingLetters = value.slice(1)
  const capitalizedWord = firstLetterCap + remainingLetters

  return capitalizedWord;
}

