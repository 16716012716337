import classes from './LeaderboardCardSmall.module.css';
import {ReactComponent as RocketIcon} from '../../../assets/svg/user-secret.svg';
import { ICard } from '../../../store/types/playerTypes';
import ReactTooltip from "react-tooltip";
import { rarityToIndex } from '../../../hoc/CommonFunctions';
import { ILeaderboardCard } from '../../../store/types/gameTypes';

interface LeaderboardCardSmallProps {
  card:ILeaderboardCard;      
  rank:number;
  cardIndex:number;
  faction:string;
}

const LeaderboardCardSmall = ( { card, rank, cardIndex, faction }:LeaderboardCardSmallProps ) => {  
  let imgStyle = {};  
  if (card && card.id > 0) {    
    let scale:number = 1;    
    imgStyle = {
      "max-width": (scale*100) + '%'
    };    
  }

  return (
    card && card.id > 0 ?  
      <div data-tip data-for={"cardTip"+rank+cardIndex+faction} className={[classes['card-holder'],classes['card-holder-'+card.rarity]].join(' ')}>             
        <div  className={classes['middle-row']}>
          <img alt="Card" style={imgStyle} src={card.image}></img>
        </div>                        
        <ReactTooltip backgroundColor="rgba(113,7,116,0.8)" arrowColor="rgba(113,7,116,0.8)" className={classes['tip-text']} id={"cardTip"+rank+cardIndex+faction} place="top" effect="solid">
          {card.name}
        </ReactTooltip>  
      </div>    
    :
      <div className={classes['slot-empty']}>        
        <RocketIcon  className={classes['rocket-icon']} />
      </div>
  );
};

export default LeaderboardCardSmall;
