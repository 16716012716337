import { ICard, PlayerState } from '../../../store/types/playerTypes';
import classes from './CardSection.module.css';
import CardDisplay from './CardDisplay/CardDisplay';
import { useEffect, useState } from 'react';
import CardFilter from '../CardFilter/CardFilter';

interface CardSectionProps {
  playerState:PlayerState;  
  infoFunction:Function;
  sendFunction:Function;
  currentModule:string;  
  currentHeroes:ICard[];
  currentVillains:ICard[];
}

const CardSection = ( { playerState, infoFunction, 
  sendFunction, currentModule, currentHeroes, currentVillains }:CardSectionProps ) => {
  
    const [filteredCardList, setFilteredCardList] = useState<ICard[] | []>([]);  
    const [factions, setFactions] = useState<string[] | []>(['hero','villain']);
    const [weaponTypes, setWeaponTypes] = useState<string[] | []>(['physical','energy']);

  // Allow the list to be filtered
  useEffect(() => {   
    let localCards:ICard[] = [];
    if (playerState.player?.cardPool) {
      for (let i:number = 0; i < playerState.player?.cardPool.length; i++) {
        let card:ICard = playerState.player?.cardPool[i];
               
        // Does it pass all the filters?               
        let passFaction:any = factions.find(element => element === card.faction);
        let passType:any = weaponTypes.find(element => element === card.weapon_type);
        
        // Add this card if it passes all the filters
        if (passFaction && passType) {
          localCards.push(card);
        }
      }      
    }
    
    setFilteredCardList(localCards);

  }, [ playerState.player?.cardPool, factions, weaponTypes ]);

  const clickFilterChange = (factionsSelected:string[], owned:string[], weapons:string[]) => {               
    setFactions(factionsSelected);
    setWeaponTypes(weapons);
  }

  return (
    <div className={classes['card-section-wrapper']}>      
      <CardFilter setFilterFunction={clickFilterChange} showOwnership={false} />          
      <div className={classes['player-cards']}>
        {filteredCardList.map( card => (
            <CardDisplay key={card.id} card={card} 
              initialRarity={card.rarities_owned[0]}
              missionFunction={sendFunction}
              infoFunction={infoFunction}
              currentModule={currentModule}      
              currentHeroes={currentHeroes}        
              currentVillains={currentVillains} />
          ))
        }
      </div>     
    </div>
  );
};

export default CardSection;
