import {
  GET_GAME_DATA_SUCCESS,
  GET_GAME_DATA_FAILURE,  
  SET_GENERAL_ERROR,
  SET_GENERAL_BUSY_MESSAGE,
  SHOW_BATTLE_CONFIRMATION,
  HIDE_BATTLE_CONFIRMATION,
  SET_WAX_ERROR,
  UPDATE_MOBILE_MODE,
  SET_SIGNING_TRANSACTION,
  SET_BUSY_AUTO_LOGIN,
  CLEAR_GAME_ERROR,
  SET_GAME_MODE,
  HIDE_BUYIN_CONFIRMATION,
  SHOW_BUYIN_CONFIRMATION,
  VIEW_CARD_DETAILS,
  CLEAR_CARD_DETAILS
} from "../actions/actionTypes";
import {    
  GetGameDataSuccess,
  GetGameDataFailure,
  GetGameSuccessPayload,
  GetGameFailurePayload,
  SetGeneralErrorRequest,
  SetGeneralBusyMessageRequest,
  ShowBattleConfirmationRequest,
  HideBattleConfirmationRequest,
  SetWaxErrorMessageRequest,
  UpdateMobileModeRequest,
  SetSigningTransactionRequest,
  SetBusyAutoLoginRequest,
  ClearGameErrorRequest,
  SetGameModeRequest,  
  ShowBuyinConfirmationRequest,
  HideBuyinConfirmationRequest,
  ViewCardDetailsRequest,
  ClearCardDetailsRequest,  
} from "../types/gameTypes";
import { ICard } from "../types/playerTypes";

// Retrieving of Game State
export const getGameDataSuccess = (
  payload: GetGameSuccessPayload
): GetGameDataSuccess => ({
  type: GET_GAME_DATA_SUCCESS,
  payload,
});
export const getGameDataFailure = (
  payload: GetGameFailurePayload
): GetGameDataFailure => ({
  type: GET_GAME_DATA_FAILURE,
  payload,
});

// Viewing the detail of a ship
export const viewCardDetails = ( card: ICard ): ViewCardDetailsRequest => ({
  type: VIEW_CARD_DETAILS,
  payload: card
});
export const clearCardDetails = (): ClearCardDetailsRequest => ({
  type: CLEAR_CARD_DETAILS
});

// Showing the battle confirmation
export const showBattleConfirmation = ( heroes:number[], heroRarities:string[], villains:number[], villainRarities:string[], rematch:boolean, practice:boolean ): ShowBattleConfirmationRequest => ({
  type: SHOW_BATTLE_CONFIRMATION,
  heroes: heroes,
  heroRarities: heroRarities,
  villains: villains,
  villainRarities: villainRarities,    
  rematch: rematch,  
  practice: practice
});
export const hideBattleConfirmation = (): HideBattleConfirmationRequest => ({
  type: HIDE_BATTLE_CONFIRMATION
});


// Showing the buyin confirmation
export const showBuyinConfirmation = ( heroes:number[], heroRarities:string[], villains:number[], villainRarities:string[] ): ShowBuyinConfirmationRequest => ({
  type: SHOW_BUYIN_CONFIRMATION,
  heroes: heroes,
  heroRarities: heroRarities,
  villains: villains,
  villainRarities: villainRarities
});
export const hideBuyinConfirmation = (): HideBuyinConfirmationRequest => ({
  type: HIDE_BUYIN_CONFIRMATION
});


// Setting a general error popup
export const setGeneralError = ( message: string ): SetGeneralErrorRequest => ({
  type: SET_GENERAL_ERROR,
  payload: message
});

// Setting a general busy message
export const setWaxErrorMessage = ( message: string ): SetWaxErrorMessageRequest => ({
  type: SET_WAX_ERROR,
  payload: message
});

// Setting a general busy message
export const setGeneralBusyMessage = ( message: string ): SetGeneralBusyMessageRequest => ({
  type: SET_GENERAL_BUSY_MESSAGE,
  payload: message
});

// Show the signing transaction popup
export const setSigningTransaction = ( message: string ): SetSigningTransactionRequest => ({
  type: SET_SIGNING_TRANSACTION,
  payload: message
});

// Setting mobile mode
export const updateMobileMode = ( mobile: boolean ): UpdateMobileModeRequest => ({
  type: UPDATE_MOBILE_MODE,
  payload: mobile
});

// Setting auto login busy modal
export const setBusyAutoLogin = ( show: boolean ): SetBusyAutoLoginRequest => ({
  type: SET_BUSY_AUTO_LOGIN,
  payload: show
});

// Clear game state error
export const clearGameError = (): ClearGameErrorRequest => ({
  type: CLEAR_GAME_ERROR
});

// Set the game view mode
export const setGameMode = ( mode: string ): SetGameModeRequest => ({
  type: SET_GAME_MODE,
  payload: mode
});