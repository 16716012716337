import Phaser from "phaser";

class BattleDisplay  {    
  constructor(scene, game, playerHeroes, enemyVillains, battleResult, baseScale, timeScale, completeFunction) {      
    this.scene = scene;
    this.game = game;
    this.baseScale = baseScale;
    this.playerHeroSprites = playerHeroes;
    this.enemyVillainSprites = enemyVillains;
    this.battleResult = battleResult
    this.battleStarted = false;
    this.battleStep = -1;
    this.statusReady = 0;
    this.previousBattleState = "";
    this.battleState = "start_next_step"
    this.attackingCard = null;
    this.defendingCard = null;
    this.attackingSprite = null;
    this.defendingSprite = null;    
    this.playerTurn = false;
    this.timeScale = timeScale;
    this.completeFunction = completeFunction;

    // Build some re-used sprites
    this.explosionLarge = this.scene.add.sprite(-1000, 0, "explosionLarge");
    this.explosionLarge.on('animationcomplete', () => this.onAnimationComplete('explosionLarge'));  
    this.explosionLarge.alpha = 0;    
    this.explosionLarge.scale = this.baseScale;    
    this.projectileLaser = this.scene.add.sprite(-1000, 0, "projectileLaser");    
    this.projectileLaser.scale = this.baseScale * 0.125;
    

    // Add win / lose animations
    this.winAnimation = this.scene.add.sprite(0, 0, "winAnimation");  
    this.winAnimation.alpha = 0;
    this.winAnimation.x = this.game.canvas.clientWidth / 2;
    this.winAnimation.y = this.game.canvas.clientHeight / 2;
    this.winAnimation.scale = this.baseScale;
    this.loseAnimation = this.scene.add.sprite(0, 0, "loseAnimation");     
    this.loseAnimation.alpha = 0;
    this.loseAnimation.x = this.game.canvas.clientWidth / 2;
    this.loseAnimation.y = this.game.canvas.clientHeight / 2;
    this.loseAnimation.scale = this.baseScale;

    this.currentWeapon = null;    
  }

  // Start battle!
  update() {    
    if (this.battleStarted) {
      if (this.battleState != this.previousBattleState) {        
        this.previousBattleState = this.battleState;
      }
      
      switch (this.battleState) {
        case "start_next_step":
          // Get the next step
          this.battleStep++;

          // If we are in fast forward mode, find the next step with a death or a move
          if (this.scene.isFastForward) {            
            this.fastForward();                        
          }  
        
          // Stop if we are done
          if (this.battleStep >= this.battleResult.battleSteps.length) {
            break;
          }

          // Set some easy getters
          this.playerTurn = this.battleResult.battleSteps[this.battleStep].bPlayer;
          this.attackingCard = this.battleResult.battleSteps[this.battleStep].attackingCard;
          this.defendingCard = this.battleResult.battleSteps[this.battleStep].defendingCard;
          this.attackingSprite = this.findSpriteByIndex(this.playerTurn, this.attackingCard);
          
          // Some special abilities target friendly cards
          if (this.defendingCard.isPlayerCard === this.playerTurn) {
            // Target a friendly  
            this.defendingSprite = this.findSpriteByIndex(this.playerTurn, this.defendingCard);
          } else {
            // Target an enemy card normally
            this.defendingSprite = this.findSpriteByIndex(!this.playerTurn, this.defendingCard);
          }
                   
          console.log(`Starting step ${this.battleStep} ${this.playerTurn ? 'Player' : 'Opponent'} ${this.attackingCard.name} targeting ${this.defendingCard.name}`);          
                   
          // Is this a "move" event?
          if (this.attackingCard.slotChanged) {
            // We are moving a card from slot to slot
            this.battleState = "card_move_start";
          
          } else {
            // Show attacker/defender
            this.battleState = "card_show_attacker_start";  
          }          
          break;
        case "card_show_attacker_start":
          // Now in the waiting state
          this.battleState = "card_show_attacker_wait";

          // Clear out status ready
          this.statusReady = 0;
          
          // Tell the cards to show their attacker/defender
          this.attackingSprite.showStatus(true, this.attackingCard, () => this.statusShown(), () => this.turnComplete());          
          this.defendingSprite.showStatus(false, this.defendingCard, () => this.statusShown(), () => this.turnComplete());          
          break;
        case "card_fire_target_start":                    
          // Now in the waiting state
          this.battleState = "card_fire_target_wait";

          // Fire!
          this.fireAtTarget();
          break;
        case "card_hide_attacker_start":
          // Now in the waiting state
          this.battleState = "card_hide_attacker_wait";

          // Tell the cards to show their attacker/defender
          this.attackingSprite.hideStatus(true, this.attackingCard, () => this.statusHidden(), () => this.turnComplete());          
          this.defendingSprite.hideStatus(false, this.defendingCard, () => this.statusHidden(), () => this.turnComplete()); 

          
          break;
        case "card_move_start":
          // Now in the waiting state
          this.battleState = "card_move_wait";
          
          // Tell the attacking card to rotate
          this.attackingSprite.changeSlot(this.attackingCard, () => this.moveComplete());
          
          // The "defending" sprite is the dead card we are taking the place of, move it invisible
          this.defendingSprite.changeSlotInstant(this.defendingCard);          
          break;       
      }

      if (this.battleStep >= this.battleResult.battleSteps.length) {        
        this.battleStarted = false;

        // Call the complete function on the parent
        this.completeFunction();

        // Show win / lose
        this.showFinalAnimation();
      }      
    }
  }

  skipToEnd() {
    if (this.battleStarted) {
      // Show all cards in their final locations
      this.battleStarted = false;

      // Wait a second for anything in progress to stop
      setTimeout(() => this.continueSkip(), 2000);
    }
  }

  continueSkip() {
    // Update player cards
    for (let i = 0; i < this.playerHeroSprites.length; i++) {
      // Find it in the final fleet
      for (let j = 0; j < this.battleResult.playerHeroesFinal.length; j++) {
        if (this.playerHeroSprites[i].card.id === this.battleResult.playerHeroesFinal[j].id) {
          // Update the stats on it
          this.playerHeroSprites[i].updateStatsDisplay(this.battleResult.playerHeroesFinal[j]);
          
          // If it is dead, remove it
          if (this.battleResult.playerHeroesFinal[j].currentHealth <= 0) {
            this.playerHeroSprites[i].startDestroy(() => this.onDeathComplete());
          }
        }
      }
    }

    // Update enemy cards
    for (let i = 0; i < this.enemyVillainSprites.length; i++) {
      // Find it in the final fleet
      for (let j = 0; j < this.battleResult.enemyVillainsFinal.length; j++) {
        if (this.enemyVillainSprites[i].card.id === this.battleResult.enemyVillainsFinal[j].id) {
          // Update the stats on it
          this.enemyVillainSprites[i].updateStatsDisplay(this.battleResult.enemyVillainsFinal[j]);

          // If it is dead, remove it
          if (this.battleResult.enemyVillainsFinal[j].currentHealth <= 0) {
            this.enemyVillainSprites[i].startDestroy(() => this.onDeathComplete());
          }
        }
      }
    }

    // Show win / lose
    this.showFinalAnimation();
  }

  // show the final animation
  showFinalAnimation() {
    // Show win / lose animation
    if (this.battleResult.battleWon) {
      this.winAnimation.play("animationWin");
      this.winAnimation.alpha = 1; 
      try {
        this.game.sound.play("win");
      } catch (e) {}
    } else {
      this.loseAnimation.play("animationLose");
      this.loseAnimation.alpha = 1;
      try {
        this.game.sound.play("lose");
      } catch (e) {}
    }
  }

  statusShown() {
    // Card is ready to fire!
    if (this.battleState === "card_show_attacker_wait") {
      this.battleState = "card_fire_target_start";
    } 
  }

  statusHidden() {
    // Cards are reset, next step!
    if (this.battleState === "card_hide_attacker_wait") {
      this.battleState = "start_next_step";
    } 
  }

  moveComplete() {    
    // card is done and ready for the next step
    this.battleState = "start_next_step";    
  }
  
  turnComplete() {
    // This card skipped it's turn   
    
    // Update the stats on the defender + attacker
    this.attackingSprite.updateStatsDisplay(this.attackingCard);
    this.defendingSprite.updateStatsDisplay(this.defendingCard);
    
    // Next step
    this.battleState = "start_next_step";    
  }

  fireAtTarget() {    
    
    // If this is a shield battery "firing" at a friendly trigger it's shields animation
    if (this.attackingCard.initialSpecialName === 'shield_battery' && this.attackingCard.isPlayerCard === this.defendingCard.isPlayerCard) {
      this.defendingSprite.triggerShields();
      this.fireComplete();
      return;
    }

    // What are we firing?
    this.currentWeapon = null;        
    if (this.attackingCard.currentWeaponPower > 0) {
      switch (this.attackingCard.weapon_type) {
        case "energy":            
          this.currentWeapon = this.projectileLaser;   
          this.currentWeapon.play("animationLaser"); 
          
          // Play the sound
          try {
            this.game.sound.play("attack_energy");          
          } catch (e) {}
          break;
        
        default:
          // Play the punch sound
          try {
            this.game.sound.play("attack_melee");          
          } catch (e) {}
          break;
      }
    }
    
    // Make sure we are actually firing something
    if (this.currentWeapon) {
      // Set its starting point
      this.currentWeapon.x = this.attackingSprite.x;
      this.currentWeapon.y = this.attackingSprite.y;

      let angle;        
      if (!this.playerTurn) {
        angle = Phaser.Math.Angle.BetweenY(this.attackingSprite.x,this.attackingSprite.y,this.defendingSprite.x,this.defendingSprite.y);          
        angle *= -1;
      } else {
        angle = Phaser.Math.Angle.BetweenY(this.defendingSprite.x,this.defendingSprite.y,this.attackingSprite.x,this.attackingSprite.y);                
        angle *= -1;
      }
      
      // Rotate the weapon to target the card
      this.currentWeapon.rotation = angle;
      
      // Tween it
      this.scene.tweens.add(
        {
          targets: this.currentWeapon,
          x: this.defendingSprite.x, 
          y: this.defendingSprite.y,				
          ease: 'linear',
          duration: 500, 
          delay: 0,
          onComplete: () => this.weaponHit()  
        }
      );      
    } else {
      // Skip to next move
      this.fireComplete();
    }
  }

  weaponHit() {            
    // Show the shield if needed
    if (this.defendingCard.hitBarrier) {     
      this.defendingSprite.triggerShields();
      
    } else {
      // TODO - show the "blood" animation

      // If this was an energy weapon play the hit sound
      if (this.attackingCard.weapon_type === 'energy') {
        // Play the punch sound
        try {
          this.game.sound.play("attack_melee");          
        } catch (e) {}
      }
    }

    // Remove the sprite
    if (this.currentWeapon) {
      this.currentWeapon.x = -400;
      this.currentWeapon.y = 0;
      this.currentWeapon = null;
    }    

    // Fire complete!
    this.fireComplete();
  }

  fireComplete() {
    // Special case:  if this is an AOE card firing at multiple targets, keep going changing the attacker frame 
    //   - shield drain also hits multiple targets
    if ((this.attackingCard.special_name === 'aoe' || this.attackingCard.special_name === 'chain' || this.attackingCard.special_name === 'shield_debuff') && (this.battleStep + 1) < this.battleResult.battleSteps.length) {
      let nextAttacker = this.battleResult.battleSteps[this.battleStep+1].attackingCard; 
      if (nextAttacker.id === this.attackingCard.id) {        
        this.battleState = "start_next_step";            
      } else {
        // Card has fired, rotate back    
        this.battleState = "ship_rotate_neutral_start";    
      }       
    } else {
      // Card has fired, ready for removing the frames
      this.battleState = "card_hide_attacker_start";    
    }

    // Update the card stats
    this.attackingSprite.updateStatsDisplay(this.attackingCard);
    this.defendingSprite.updateStatsDisplay(this.defendingCard);
    
    // If the card has been destroyed, remove it!
    if (this.defendingCard.currentHealth <= 0) {
      // Wait for the death to complete
      this.battleState = "card_death_wait"; 

      // TODO - play some kind of death animation, grey it out with X?
            
      // Tell the card
      this.defendingSprite.startDestroy(() => this.onDeathComplete()); 
            
      // Play the death sound
      switch (this.defendingCard.death_sound) {
        case "death_monster":
          try {
            this.game.sound.play("death_monster");
          } catch (e) {}
          break;

        case "death_male":
          try {
            this.game.sound.play("death_male");
          } catch (e) {}
          break;

        case "death_female":
          try {
            this.game.sound.play("death_female");
          } catch (e) {}
          break;

        case "death_robot":          
          try {
            this.game.sound.play("death_robot");
          } catch (e) {}
          break;
      }
      
    }
  }

  onDeathComplete() {
    // Card has fired, ready for removing the frames
    this.battleState = "card_hide_attacker_start";  
  }
  
  onAnimationComplete(animationName) {   
    // Fade it out
    this.scene.tweens.add(
      {
        targets: this[animationName],
        alpha: 0,				
        ease: 'linear',
        duration: 100, 
        delay: 0
      }
    ); 
  }

  findSpriteByIndex(bPlayer, card) {
    let sprite = null;
    if (bPlayer) {
      for (let i = 0; i < this.battleResult.playerHeroes.length; i++) {
        if (card.id === this.battleResult.playerHeroes[i].id) {
          sprite = this.playerHeroSprites[i];
        }
      }
    } else {
      for (let i = 0; i < this.battleResult.enemyVillains.length; i++) {
        if (card.id === this.battleResult.enemyVillains[i].id) {
          sprite = this.enemyVillainSprites[i];
        }
      }
    }
    
    return sprite;
  }
  
  fastForward () {
    let stepFound = false;    
    let step = null;
    for (let i = this.battleStep; i < this.battleResult.battleSteps.length; i++) {
      if (this.battleResult.battleSteps[i].defendingCard.currentHealth === 0 || 
        this.battleResult.battleSteps[i].attackingCard.currentHealth === 0 ||
        this.battleResult.battleSteps[i].defendingCard.showSwarm ||
        this.battleResult.battleSteps[i].defendingCard.showDecoy ||
        this.battleResult.battleSteps[i].defendingCard.wasResurrected ||
        this.battleResult.battleSteps[i].attackingCard.slotChanged || 
        this.battleResult.battleSteps[i].attackingCard.showUpgrade ||
        this.battleResult.battleSteps[i].attackingCard.showShieldPair ||
        this.battleResult.battleSteps[i].attackingCard.endHardenedShields ||
        this.battleResult.battleSteps[i].defendingCard.endHardenedShields ||
        this.battleResult.battleSteps[i].attackingCard.showBuildUp) {

        // Found it!

        // Update all the cards to the current state
        for (let j = this.battleStep; j < i; j++) {
          step = this.battleResult.battleSteps[j];
          this.instantUpdateCardStats(step.attackingCard);
          this.instantUpdateCardStats(step.defendingCard);
        }

        // Skip to this step
        this.battleStep = i;
        stepFound = true;
        break;
      }
    }
    
    // If no step found we are done
    if (stepFound === false) {
      this.battleStep = this.battleResult.battleSteps.length;              
    }
  }

  instantUpdateCardStats(card) {
    // If it is a player card
    if (card.isPlayerCard) {
      for (let i = 0; i < this.playerHeroSprites.length; i++) {        
        if (this.playerHeroSprites[i].card.id === card.id) {
          // Update the stats on it
          this.playerHeroSprites[i].updateStatsDisplay(card);
        }        
      }
    } else {
      for (let i = 0; i < this.enemyVillainSprites.length; i++) {        
        if (this.enemyVillainSprites[i].card.id === card.id) {
          // Update the stats on it
          this.enemyVillainSprites[i].updateStatsDisplay(card);
        }        
      }                  
    }  
  }
}

export default BattleDisplay;