import {
  GET_PLAYER_DATA_SUCCESS,
  GET_PLAYER_DATA_FAILURE,
  CLEAR_BATTLE,
  HIDE_BATTLE_LOG,
  SHOW_BATTLE_RESULTS,
  HIDE_BATTLE_RESULTS,
  SHOW_BATTLE_LOG,
  CLEAR_PLAYER_ERROR,
  CLEAR_ANNOUNCEMENT,
  COMMENCE_PRACTICE_SUCCESS,
  COMMENCE_BATTLE_FAILURE,
  COMMENCE_BUYIN_SUCCESS,
  COMMENCE_TOURNAMENT_SUCCESS,
  COMMENCE_SIMULATOR_SUCCESS,
  COMMENCE_SIMULATOR_FAILURE,
  HIDE_SIMULATOR_BATTLE,
  PRESET_SUCCESS
} from "../actions/actionTypes";

import { PlayerState, GameActions } from "../types/playerTypes";

const initialState: PlayerState = {
  player: null,    
  error: "",
  fatalError: "",
  battleResult: null,
  showBattleLog: false,
  showBattleResultsPopup: false,
  showSimulatorResultsPopup: false,
  medalsWon: 0,
  announcement: null,
  practiceHeroes: [],  
  practiceHeroRarities: [],
  practiceVillains: [],
  practiceVillainRarities: [],
  practiceSeasonal: false,
  isSimulatorGame: false,
  showSimulationResults: false,  
  simulatorWins: 0,
  simulatorLosses: 0,
  averageRounds: 0,
  averageSteps: 0
};

export default (state = initialState, action: GameActions) => {
  switch (action.type) {
    case CLEAR_BATTLE:
      return {
        ...state,
        battleResult: null,
        error: ""
      };
    case SHOW_BATTLE_RESULTS:
      return {
        ...state,
        showBattleResultsPopup: true
      };
    case HIDE_BATTLE_RESULTS:
      return {
        ...state,
        showBattleResultsPopup: false
      };
    case SHOW_BATTLE_LOG:
      return {
        ...state,
        showBattleLog: true
      };
    case HIDE_BATTLE_LOG:
        return {
          ...state,
          showBattleLog: false
        };
    case GET_PLAYER_DATA_SUCCESS: 
      return {
        ...state,
        player: action.payload.playerDetails,
        error: "",
        announcement: action.payload.playerDetails.announcement,
        practiceHeroes: [],
        practiceHeroRarities: [],
        practiceVillains: [],
        practiceVillainRarities: []     
      };
    case GET_PLAYER_DATA_FAILURE:    
    case COMMENCE_BATTLE_FAILURE:
    case COMMENCE_SIMULATOR_FAILURE:

      return {
        ...state,        
        error: action.payload.error,
        fatalError: action.payload.fatalError,
        isSimulatorGame: false,        
      };   
    case COMMENCE_TOURNAMENT_SUCCESS:
      return {
        ...state,
        player: action.payload.playerDetails,
        battleResult: action.payload.battleResult, 
        medalsWon: action.payload.medalsWon,           
        error: "",
        practiceHeroes: [],               
        practiceHeroRarities: [],
        practiceVillains: [],
        practiceVillainRarities: [],        
        isSimulatorGame: false          
      };
    case COMMENCE_PRACTICE_SUCCESS:
      return {
        ...state,        
        battleResult: action.payload.battleResult, 
        practiceHeroes: action.payload.heroes,       
        practiceHeroRarities: action.payload.heroRarities,       
        practiceVillains: action.payload.villains,       
        practiceVillainRarities: action.payload.villainRarities,       
        practiceSeasonal: action.payload.practiceSeasonal,
        medalsWon: 0,          
        error: "",
        isSimulatorGame: false,        
      };
    case COMMENCE_SIMULATOR_SUCCESS:
      return {
        ...state,       
        player: action.payload.playerDetails, 
        battleResult: action.payload.battleResult, 
        simulatorWins: action.payload.wins,
        simulatorLosses: action.payload.losses,        
        error: "",
        isSimulatorGame: true,
        showSimulationResults: action.payload.showSimulationResults  ,
        averageRounds: action.payload.averageRounds,
        averageSteps: action.payload.averageSteps
      };    
    case PRESET_SUCCESS:
      return {
        ...state,       
        player: action.payload.playerDetails       
      };
    case COMMENCE_BUYIN_SUCCESS:
      return {
        ...state,
        player: action.payload.playerDetails,
        practiceHeroes: [],     
        practiceHeroRarities: [],
        practiceVillains: [],
        practiceVillainRarities: [],   
        error: ""           
      };
    case CLEAR_PLAYER_ERROR:
      return {
        ...state,
        error: ""
      }; 
    case CLEAR_ANNOUNCEMENT:
      return {
        ...state,
        announcement: null
      }; 
    case HIDE_SIMULATOR_BATTLE:
      return {
        ...state,
        showSimulationResults: false
      };
    default:
      return {
        ...state,
      };    
  }
};