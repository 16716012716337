import classes from './CardDisplay.module.css';
import {ReactComponent as InfoIcon} from '../../../../assets/svg/info.svg';
import heroIcon from '../../../../assets/images/general/icon_hero.png';
import villainIcon from '../../../../assets/images/general/icon_villain.png';
import { ICard } from '../../../../store/types/playerTypes';
import { useEffect, useState } from 'react';
import { rarityToIndex, indexToRarity } from '../../../../hoc/CommonFunctions';

interface CardDisplayProps {
  card:ICard;  
  infoFunction:Function;
  missionFunction:Function;
  currentModule:string;
  currentHeroes:ICard[];
  currentVillains:ICard[];
  initialRarity:string;
}

const CardDisplay = ( {card, infoFunction, missionFunction, currentModule, currentHeroes, currentVillains, initialRarity}:CardDisplayProps ) => {  
  const [selectedRarity, setSelectedRarity] = useState("commmon");
  let localInUse: boolean = false;
  
  // Allow the initial rarity to be passed in
  useEffect(() => {   
    setSelectedRarity(initialRarity);

  }, [ initialRarity ]);
  
  if (currentHeroes && currentHeroes.length > 0) {
    for (let i:number = 0; i < currentHeroes.length; i++ ) {
      if (currentHeroes[i] && currentHeroes[i].id === card.id) {
        localInUse = true;
      }
    }
  }
  if (currentVillains && currentVillains.length > 0) {
    for (let i:number = 0; i < currentVillains.length; i++ ) {
      if (currentVillains[i] && currentVillains[i].id === card.id) {
        localInUse = true;
      }
    }
  }

  const updateRarity = (e:any) => {        
    card.rarity = card.rarities_owned[e.target.selectedIndex];
    setSelectedRarity(card.rarity);
  }

  return (
    <div className={[classes['card-holder'],classes['card-holder-'+card.rarity_stats[rarityToIndex(selectedRarity)].rarity]].join(' ')}>
      <div className={[classes['top-row'],classes['top-row-'+card.rarity_stats[rarityToIndex(selectedRarity)].rarity]].join(' ')}>       
        {card.faction === 'hero' ?
          <img className={classes['faction-hero']} src={heroIcon} alt='Hero' />          
        :  
          <img className={classes['faction-villain']} src={villainIcon} alt='Villain' />
        }
        {card.name.length > 20 ?
          <div className={classes['card-name-small']}>{card.name}</div>          
        :
          <div className={classes['card-name']}>{card.name}</div>          
        }        
        <InfoIcon onClick={() => infoFunction(card)}  className={classes['info-icon']} />      
      </div>
     
      <div className={classes['middle-row']}>
        {card.rarity_stats[rarityToIndex(selectedRarity)].gif ?
          <img className={classes['card-image']} alt="Card"  src={card.rarity_stats[rarityToIndex(selectedRarity)].gif}></img>
        :
          <img className={classes['card-image']} alt="Card"  src={card.rarity_stats[rarityToIndex(selectedRarity)].image}></img>
        }         
      </div>   

      <div className={classes['stat-row']}>        
        <div className={classes['stat-details']}>
          <div className={classes['stat-base-text']}>
            <div className={classes['level-select']} >  
              <select onChange={(e) => updateRarity(e)}>
                {card.rarities_owned.map( rarity => (
                  selectedRarity === rarity ?
                    <option key={rarity} selected value={rarity}>{rarity}</option>
                  :
                    <option key={rarity} value={rarity}>{rarity}</option>
                  
                ))}                 
              </select>
            </div>
          </div>                      
        </div>                              
      </div>     
        
      <div  className={classes['bottom-row']}>            
        <div className={classes['button-row']}>
          { (currentModule === 'battle' && localInUse === false ) ?
            <button className={classes['mission-button']} onClick={() => missionFunction(card)}>Add</button>
          : 
            <button className={classes['mission-button-disabled']}>Add</button>            
          }
        </div>
      </div>
    </div>    
  );
};

export default CardDisplay;
