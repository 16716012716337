import classes from './SimulatorHistoryDetail.module.css';

import {ReactComponent as LoadIcon} from '../../../assets/svg/upload3.svg';
import { ISimulationEntryPopulated } from '../../../store/types/playerTypes';
import BattleCardDisplay from '../../BattleHistory/BattleRow/BattleCardDisplay/BattleCardDisplay';

interface SimulatorHistoryDetailProps {
  entry:ISimulationEntryPopulated;
  loadFunction:Function;
  rowIndex:number;
}

const SimulatorHistoryDetail = ( { entry, loadFunction, rowIndex }:SimulatorHistoryDetailProps ) => {     
    let localBattleDate:Date = new Date(entry.date);   
    
    return (      
      <div className={classes['battle-row']}>
        <div onClick={() => loadFunction(entry)} className={classes['watch-button']}>
            <LoadIcon className={classes['watch-icon']} />  
        </div>                                                       

        <div className={classes['detail-group']}>
          <div className={classes['battle-date']}>{localBattleDate.toLocaleDateString('en-US')}&nbsp;{localBattleDate.toLocaleTimeString()}</div>            
                        
                  
          <div className={classes['battle-fleets']}>
            <div className={classes['battle-fleet']}>
                {entry.friendlyHeroes.map( ( card, index ) => (
                    <BattleCardDisplay key={index} rowIndex={rowIndex} cardIndex={index} 
                        card={card} />
                ))}                      
            </div>
            <div className={classes['vs-text']}>VS</div>
            <div className={classes['battle-fleet']}>
                {entry.enemyVillains.map( ( card, index ) => (
                    <BattleCardDisplay key={index} rowIndex={rowIndex} cardIndex={index} 
                        card={card} />
                ))}                      
            </div>
          </div>                                 
        </div>
      </div>    
    );
};

export default SimulatorHistoryDetail;
