import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getGameStateSelector } from '../../../store/selectors/gameSelectors';
import { getPlayerStateSelector } from '../../../store/selectors/playerSelectors';
import { GameState, ILeaderboard } from '../../../store/types/gameTypes';
import { PlayerState } from '../../../store/types/playerTypes';
import classes from './LeaderboardRules.module.css';
import fglToken from '../../../assets/images/general/fgl-token.png';

interface LeaderboardRulesProps {
  leaderboard:ILeaderboard;
}


const LeaderboardRules = ( { leaderboard }:LeaderboardRulesProps ) => {
  const [timeLeft, setTimeLeft] = useState(0);
  const [formattedTimeLeft, setFormattedTimeLeft] = useState("00:00:00");  
  const [rarityMaxCounts, setRarityMaxCounts] = useState([null,null,null,null]);
  const [signedUp, setSignedUp] = useState(false);
  const [rulesColor, setRulesColor] = useState("");

  const gameState:GameState = useSelector(getGameStateSelector);
  const playerState:PlayerState = useSelector(getPlayerStateSelector);

  useEffect(() => {
    let timer:any = setTimeout(updateTimeRemaining, 1000);
        
    // Clear timeout if the component is unmounted
    return () => {
      if (timer)
        clearTimeout(timer);
    }
  });

  // Keep track of if we have signed up or not
  useEffect(() => {           
    if (gameState.gameMode === 'seasonal' && playerState.player?.boughtInGold) {
      setSignedUp(true); 
    } else if (gameState.gameMode === 'weekly' && playerState.player?.boughtInBronze) {
      setSignedUp(true); 
    } else {
      setSignedUp(false); 
    }

    // Also set the rules color
    let color:string = "";
    
    if (gameState.gameMode === 'seasonal') {
      color = "-gold";
    } else {
      color = "-bronze";
    }
  
    setRulesColor(color);
      
  }, [ gameState.gameMode, playerState.player?.boughtInBronze, playerState.player?.boughtInGold ]);

  // Check if we are signed up
  useEffect(() => {           
    let timeLeft:number = leaderboard.endTime - Date.now();
    if (leaderboard.startTime > Date.now()) {
      timeLeft = leaderboard.startTime - Date.now();
    }    
    setTimeLeft(timeLeft);  
          
  }, [ leaderboard, leaderboard.startTime, leaderboard.endTime, leaderboard.signUpStartTime ]);

  // Update which ships are allowed
  useEffect(() => {               
    let newMaxCounts:any[] = [null,null,null,null,null,null,null,null];

    for (let j = 0; j < leaderboard.cardLimits.length; j++) {
      let rarityIndex: number = 0;    
      switch (leaderboard.cardLimits[j].rarity) {
        case "uncommon":
          rarityIndex = 1;          
          break;
        case "scarce":
          rarityIndex = 2;          
          break;
        case "rare":
          rarityIndex = 3;          
          break;
        case "epic":
          rarityIndex = 4;          
          break;
        case "legendary":
          rarityIndex = 5;          
          break;
        case "mythic":
          rarityIndex = 6;          
          break;
        case "secret":
          rarityIndex = 7;          
          break;
      }
      
      newMaxCounts[rarityIndex] = leaderboard.cardLimits[j].maxCount;
    }

    setRarityMaxCounts(newMaxCounts);
      
  }, [ leaderboard.cardLimits ]);
  
  const updateTimeRemaining = () => {
    if (timeLeft > 0) {

      let timeLeft:number = leaderboard.endTime - Date.now();
      if (leaderboard.startTime > Date.now()) {
        timeLeft = leaderboard.startTime - Date.now();
      }             
      if (timeLeft <= 0) {
        timeLeft = 0;
      }
      setTimeLeft(timeLeft);
      
      setFormattedTimeLeft(
        formatTime(Math.floor((timeLeft / (1000 * 60 * 60))).toString()) + ":" +
        formatTime(Math.floor((timeLeft / 1000 / 60) % 60).toString()) + ":" + 
        formatTime(Math.floor((timeLeft / 1000) % 60).toString())
      )      
    } 
  };

  const formatTime = (time:string) => {
    if (time.length === 1) {
      return "0" + time;
    } else {
      return time;
    }
  }
 
  const getLeaderboardDate = (time:number | undefined) => {
    if (time) {
      let dt:Date = new Date(time);
      return dt.toDateString();
    }
  }

  const getLeaderboardTime = (time:number | undefined) => {
    if (time) {
      let dt:Date = new Date(time);
      return dt.toLocaleTimeString();
    }
  }  
  return (    
    <div className={classes['leaderboard-top-row']}> 
      <fieldset className={classes['details-block' + rulesColor]}>
        <legend className={classes['details-title' + rulesColor]}>Rules</legend>
        
        <div className={classes['details-row']}>
          {leaderboard.cardPoints && leaderboard.cardPoints > 0 ?
            <>
            <div className={classes['detail-title-points']}>Total Card Points: {leaderboard.cardPoints}</div>             
            {rarityMaxCounts[0] === 0 ?
              <div className={classes['detail-value']}>
                <span className={classes['rarity-text-common']}>COMMON</span>: NOT ALLOWED<br/>
              </div>
            :
              <div className={classes['detail-value']}>
                <span className={classes['rarity-text-common']}>COMMON</span>: 1 point                
                {rarityMaxCounts[0] && rarityMaxCounts[0] > 0 ?
                  <span className={classes['detail-value']}>, Max Count {rarityMaxCounts[0]}</span>
                : null }
                <br />
              </div>                                    
            }

            {rarityMaxCounts[1] === 0 ?
              <div className={classes['detail-value']}>
                <span className={classes['rarity-text-uncommon']}>UNCOMMON</span>: NOT ALLOWED<br/>
              </div>
            :
              <div className={classes['detail-value']}>
                <span className={classes['rarity-text-uncommon']}>UNCOMMON</span>: 2 points
                {rarityMaxCounts[1] && rarityMaxCounts[1] > 0 ?
                  <span className={classes['detail-value']}>, Max Count {rarityMaxCounts[1]}</span>
                : null }
                <br />
              </div>                                    
            }

            {rarityMaxCounts[2] === 0 ?
              <div className={classes['detail-value']}>
                <span className={classes['rarity-text-scarce']}>SCARCE</span>: NOT ALLOWED<br/>
              </div>
            :
              <div className={classes['detail-value']}>
                <span className={classes['rarity-text-scarce']}>SCARCE</span>: 3 points
                {rarityMaxCounts[2] && rarityMaxCounts[2] > 0 ?
                  <span className={classes['detail-value']}>, Max Count {rarityMaxCounts[2]}</span>
                : null }
                <br />
              </div>                                    
            }

            {rarityMaxCounts[3] === 0 ?
              <div className={classes['detail-value']}>
                <span className={classes['rarity-text-rare']}>RARE</span>: NOT ALLOWED<br/>
              </div>
            :
              <div className={classes['detail-value']}>
                <span className={classes['rarity-text-rare']}>RARE</span>: 4 points
                {rarityMaxCounts[3] && rarityMaxCounts[3] > 0 ?
                  <span className={classes['detail-value']}>, Max Count {rarityMaxCounts[3]}</span>
                : null }
                <br />
              </div>                                    
            }

            {rarityMaxCounts[4] === 0 ?
              <div className={classes['detail-value']}>
                <span className={classes['rarity-text-epic']}>EPIC</span>: NOT ALLOWED<br/>
              </div>
            :
              <div className={classes['detail-value']}>
                <span className={classes['rarity-text-epic']}>EPIC</span>: 5 points 
                {rarityMaxCounts[4] && rarityMaxCounts[4] > 0 ?
                  <span className={classes['detail-value']}>, Max Count {rarityMaxCounts[4]}</span>
                : null }
                <br />
              </div>                                    
            }

            {rarityMaxCounts[5] === 0 ?
              <div className={classes['detail-value']}>
                <span className={classes['rarity-text-legendary']}>LEGENDARY</span>: NOT ALLOWED<br/>
              </div>
            :
              <div className={classes['detail-value']}>
                <span className={classes['rarity-text-legendary']}>LEGENDARY</span>: 6 points
                {rarityMaxCounts[5] && rarityMaxCounts[5] > 0 ?
                  <span className={classes['detail-value']}>, Max Count {rarityMaxCounts[5]}</span>
                : null }
                <br />
              </div>                                    
            }

            {rarityMaxCounts[6] === 0 ?
              <div className={classes['detail-value']}>
                <span className={classes['rarity-text-mythic']}>MYTHIC</span>: NOT ALLOWED<br/>
              </div>
            :
              <div className={classes['detail-value']}>
                <span className={classes['rarity-text-mythic']}>MYTHIC</span>: 7 points
                {rarityMaxCounts[0] && rarityMaxCounts[0] > 0 ?
                  <span className={classes['detail-value']}>, Max Count {rarityMaxCounts[6]}</span>
                : null }
                <br />
              </div>                                    
            }

            {rarityMaxCounts[7] === 0 ?
              <div className={classes['detail-value']}>
                <span className={classes['rarity-text-secret']}>SECRET</span>: NOT ALLOWED<br/>
              </div>
            :
              <div className={classes['detail-value']}>
                <span className={classes['rarity-text-secret']}>SECRET</span>: 8 points                
                {rarityMaxCounts[7] && rarityMaxCounts[7] > 0 ?
                  <span className={classes['detail-value']}>, Max Count {rarityMaxCounts[7]}</span>
                : null }
                <br />
              </div>                                    
            }                        
                                    
          </>
          :                        
            <>
            <div className={classes['detail-title']}>Card Limits:</div>               
            {leaderboard.cardLimits.map( ( limit, index ) => (
              limit.maxCount === 0 ?
                <div key={'rule'+index} className={classes['detail-value']}>
                  <span className={classes['rarity-text-' + limit.rarity]}>{limit.rarity.toUpperCase()}</span> - Not Allowed<br/>
                </div>
              :
                <div key={'rule'+index} className={classes['detail-value']}>
                  <span className={classes['rarity-text-' + limit.rarity]}>{limit.rarity.toUpperCase()}</span> - <span className={classes['detail-value']}>Max Count {limit.maxCount}</span>                    
                  <br />                                    
                </div>                                    
            ))}              
            </>                                   
          }
        </div>
      </fieldset>
      <fieldset className={classes['details-block' + rulesColor]}>
        <legend className={classes['details-title' + rulesColor]}>Dates</legend>
        {leaderboard.startTime > Date.now() ?    
          signedUp ? 
            <div className={classes['details-row']}>
              <div className={classes['detail-title']}>Entry Fee:</div>
              <div className={classes['detail-value-green']}>SIGNED UP</div>
            </div>
          :
            <>
            <div className={classes['details-row']}>
              <div className={classes['detail-title']}>Entry Fee:</div>                              
              <div className={classes['fee-group']}>                  
                <div className={classes['detail-value']}>{leaderboard.entryFee}</div>
                <img src={fglToken} className={classes['token']} />
              </div>              
            </div>
            
            {leaderboard.signUpStartTime > Date.now() ?
              <div className={classes['details-row']}>
                <div className={classes['detail-title']}>Sign Up Starts On:</div>
                <div className={classes['detail-value']}>{getLeaderboardDate(leaderboard.signUpStartTime)}&nbsp;{getLeaderboardTime(leaderboard.signUpStartTime)}  </div>
              </div>
            :          
              <div className={classes['details-row']}>
                <div className={classes['detail-title']}>Sign Up Time Remaining:</div>
                <div className={classes['detail-value-red']}>{formattedTimeLeft}</div>
              </div>
            }
            </>
        : null }
                                         
        <div className={classes['details-row']}>
          <div className={classes['detail-title']}>Starts On:</div>          
          {leaderboard.startTime < Date.now() ?
            <div className={classes['detail-value-green']}>In Progress!</div>
          :
            <div className={classes['detail-value']}>
              {getLeaderboardDate(leaderboard.startTime)}&nbsp;{getLeaderboardTime(leaderboard.startTime)}
            </div>
          }          
        </div>
        <div className={classes['details-row']}>
          <div className={classes['detail-title']}>Ends On:</div> 
          <div className={classes['detail-value']}>
            {getLeaderboardDate(leaderboard.endTime)}&nbsp;{getLeaderboardTime(leaderboard.endTime)}          
          </div>
        </div>
        { (leaderboard.signUpStartTime < Date.now() && 
            leaderboard.startTime < Date.now() &&
            timeLeft < ( 1000 * 60 * 60 * 24) && timeLeft > 0) ? 
          <div className={classes['details-row']}>          
            <div className={classes['detail-title']}>Time Remaining:</div> 
            <div className={classes['detail-value-red']}>{formattedTimeLeft}</div>
          </div>
        : null }
        {leaderboard.endTime < Date.now() ?
          <div className={classes['details-row']}>
            <div className={classes['detail-title']}>&nbsp;</div> 
            <div className={classes['detail-value-red']}>LEADERBOARD HAS ENDED!</div>
          </div>
        : null}
      </fieldset>
    </div>        
  );
};

export default LeaderboardRules;