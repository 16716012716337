import { useSelector } from "react-redux";
import classes from './MobileRankings.module.css';

import { getGameStateSelector } from "../../../store/selectors/gameSelectors";
import { GameState } from "../../../store/types/gameTypes";
import { getPlayerStateSelector } from "../../../store/selectors/playerSelectors";
import { PlayerState } from "../../../store/types/playerTypes";
import MobileTopNav from "../MobileTopNav/MobileTopNav";
import MobileSideDrawer from "../MobileSideDrawer/MobileSideDrawer";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import MobileLeaderboardDisplay from "../MobileLeaderboardDisplay/MobileLeaderboardDisplay";


const MobileRankings = () => {
  const location = useLocation();
  
  const gameState:GameState = useSelector(getGameStateSelector);
  const playerState:PlayerState = useSelector(getPlayerStateSelector);

  const [sideDrawerIsVisible, setSideDrawerIsVisible] = useState(false);
  const [leaderboardIndex, setLeaderboardIndex] = useState(0);

  // Pick a leaderboard to show based on the game mode
  useEffect(() => {    
    if (gameState.gameMode === 'weekly') {
      // Show the weekly leaderboard
      setLeaderboardIndex(1);
    } else {
      // Show the seasonal leaderboard
      setLeaderboardIndex(0);
    }

  }, [ gameState.gameMode ]);

  const getLeaderboardDate = (time:number | undefined) => {
    if (time) {
      let dt:Date = new Date(time);
      return dt.toDateString();
    }
  }

  const sideDrawerClosedHandler = () => {
    setSideDrawerIsVisible(false);
  };

  const sideDrawerToggleHandler = () => {
    setSideDrawerIsVisible(!sideDrawerIsVisible);
  };

  const clickSponsor = (link:string | undefined) => {   
    if (link) {
      window.open(link);
    }
  }

  
  return (    
    <div className={classes['main-content']}>
      <div className={classes['top-nav-content']}>
        <MobileTopNav drawerToggleClicked={sideDrawerToggleHandler} />  
        <MobileSideDrawer
          isOpen={sideDrawerIsVisible}
          closeFunction={sideDrawerClosedHandler}
        />
      </div>
                       
      <div className={classes['leaderboard-holder']}>          
      
        {gameState.game?.leaderboards[leaderboardIndex].clickLink !== "" ?         
            <>
            <img src={gameState.game?.leaderboards[leaderboardIndex].bannerMobile} 
              onClick={() => clickSponsor(gameState.game?.leaderboards[leaderboardIndex].clickLink)}
              className={classes['banner-mobile-portrait']}
              />    
            <img src={gameState.game?.leaderboards[leaderboardIndex].bannerDesktop} 
              onClick={() => clickSponsor(gameState.game?.leaderboards[leaderboardIndex].clickLink)}
              className={classes['banner-mobile-landscape']}
              />      
            </>
        : null}

        <div className={classes['leaderboard-top']}>                
          <div className={classes['mission-title']}>{gameState.game?.leaderboards[leaderboardIndex].name}</div>
          <div className={classes['leaderboard-right']}>Ends On: {getLeaderboardDate(gameState.game?.leaderboards[leaderboardIndex].endTime)}</div>
        </div>    

        

        <MobileLeaderboardDisplay leaderboard={gameState.game?.leaderboards[leaderboardIndex]} isWeekly={leaderboardIndex > 0 ? true : false}
          playerName={playerState.player?.name} />   

        <div className={classes['version']}>{gameState.buildVersion}</div>  
      </div>                 
    </div>           
  );
};

export default MobileRankings;