import { useSelector } from "react-redux";
import classes from './BattleHistory.module.css';

import LeftNav from "../General/LeftNav/LeftNav";

import {ReactComponent as CheckboxCheckedIcon} from '../../assets/svg/checkbox-checked.svg';
import {ReactComponent as CheckboxUnCheckedIcon} from '../../assets/svg/checkbox-unchecked.svg';
import {ReactComponent as RadioCheckedIcon} from '../../assets/svg/radio-checked.svg';
import {ReactComponent as RadioUnCheckedIcon} from '../../assets/svg/radio-unchecked.svg';

import { useEffect, useState } from "react";

import BattleRow from "./BattleRow/BattleRow";
import { IPlayerBattleLogEntry, PlayerState } from "../../store/types/playerTypes";
import { getPlayerStateSelector } from "../../store/selectors/playerSelectors";
import { GameState } from "../../store/types/gameTypes";
import { getGameStateSelector } from "../../store/selectors/gameSelectors";

const BattleHistory = () => {

  const [modes, setGameModes] = useState<number[] | []>([1,2]);
  const [results, setResults] = useState<string[] | []>(['win','loss']);
  const [types, setTypes] = useState<string[] | []>(['regular','rematch']);
  const [battles, setBattles] = useState<IPlayerBattleLogEntry[] | []>([]);
  
  const playerState:PlayerState = useSelector(getPlayerStateSelector);
  const gameState:GameState = useSelector(getGameStateSelector);
  
  let clicksEnabled = true;

  // Build the name sub list      
  useEffect(() => {   
    let localBattles:IPlayerBattleLogEntry[] = [];  
    let bOK:boolean;

    if (playerState.player) {
      for (let i:number = 0; i < playerState.player?.battleLog.length; i++) {
        bOK = true;

        if ((playerState.player?.battleLog[i].battleType === 1 && !modes.find((element:any) => element === 1)) ||
          (playerState.player?.battleLog[i].battleType === 0 && !modes.find((element:any) => element === 2)))
        {
          bOK = false;
        }

        if ((playerState.player?.battleLog[i].battleType === 3 && !modes.find((element:any) => element === 1)) ||
          (playerState.player?.battleLog[i].battleType === 2 && !modes.find((element:any) => element === 2)))
        {
          bOK = false;
        }
        
        if ((playerState.player?.battleLog[i].bWin && !results.find((element:any) => element === 'win')) ||
          (!playerState.player?.battleLog[i].bWin && !results.find((element:any) => element === 'loss')))
        {
          bOK = false;
        }

        if ((playerState.player?.battleLog[i].bRematch && !types.find((element:any) => element === 'rematch')) ||
          (!playerState.player?.battleLog[i].bRematch && !types.find((element:any) => element === 'regular')))
        {
          bOK = false;
        }


        if (bOK) {
          localBattles.push(playerState.player?.battleLog[i]);
        }
      }
    }

    setBattles(localBattles);

   
  }, [playerState.player?.battleLog, modes, results, types]);

  const toggleMode = (mode:number) => {
    if (clicksEnabled) {
      clicksEnabled = false;
    
      // Does this mode exist in the galaxies?
      let localModes:number[] = modes.slice();    
      let foundIndex:number = -1;
      for (let i:number = 0; i < modes.length; i++) {
        if (modes[i] === mode) {
          foundIndex = i;
        }
      }

      if (foundIndex > -1) {
        // Remove it
        localModes.splice(foundIndex, 1);
      } else {
        // Add it
        localModes.push(mode);
      }

      // Update the filter    
      setGameModes(localModes);  
    }
  }

  const toggleResult = (result:string) => {
    if (clicksEnabled) {
      clicksEnabled = false;
    
      // Does this mode exist in the results?
      let localResults:string[] = results.slice();    
      let foundIndex:number = -1;
      for (let i:number = 0; i < results.length; i++) {
        if (results[i] === result) {
          foundIndex = i;
        }
      }

      if (foundIndex > -1) {
        // Remove it
        localResults.splice(foundIndex, 1);
      } else {
        // Add it
        localResults.push(result);
      }

      // Update the filter    
      setResults(localResults);  
    }
  }

  const toggleType = (type:string) => {
    if (clicksEnabled) {
      clicksEnabled = false;
    
      // Does this mode exist in the results?
      let localTypes:string[] = types.slice();    
      let foundIndex:number = -1;
      for (let i:number = 0; i < types.length; i++) {
        if (types[i] === type) {
          foundIndex = i;
        }
      }

      if (foundIndex > -1) {
        // Remove it
        localTypes.splice(foundIndex, 1);
      } else {
        // Add it
        localTypes.push(type);
      }

      // Update the filter    
      setTypes(localTypes);  
    }
  }
  
  return (    
    <div className={classes['main-content']}>
      <LeftNav />
      <div className={classes['center-content']}>                        
        <div className={classes['mission-holder']}>
          <div className={classes['battle-top']}>Battle History</div>
          <div className={classes['leaderboard-top-row']}> 
            <fieldset className={classes['details-block']}>
              <legend className={classes['details-title']}>Filter</legend>
                                                
              <div className={classes['details-column']}>
                <div className={classes['detail-title']}>Game Mode:</div>
                <div className={classes['detail-value']}>
                  <div onClick={() => toggleMode(1)} className={classes['checkbox-holder']}>
                    {modes.find(element => element === 1) ?
                      <CheckboxCheckedIcon className={classes['checkbox-icon']} />
                    :
                      <CheckboxUnCheckedIcon className={classes['checkbox-icon']} />
                    }                    
                    <div className={classes['checkbox-message']}>Bronze Battles</div>                                  
                  </div>
                  <div onClick={() => toggleMode(2)} className={classes['checkbox-holder']}>
                    {modes.find(element => element === 2) ?
                      <CheckboxCheckedIcon className={classes['checkbox-icon']} />
                    :
                      <CheckboxUnCheckedIcon className={classes['checkbox-icon']} />
                    }                    
                    <div className={classes['checkbox-message']}>Gold Battles</div>                                  
                  </div>                  
                </div>
              </div> 

              <div className={classes['details-column']}>
                <div className={classes['detail-title']}>Results:</div>
                <div className={classes['detail-value']}>
                  <div onClick={() => toggleResult('win')} className={classes['checkbox-holder']}>
                    {results.find(element => element === 'win') ?
                      <CheckboxCheckedIcon className={classes['checkbox-icon']} />
                    :
                      <CheckboxUnCheckedIcon className={classes['checkbox-icon']} />
                    }
                    <div className={classes['checkbox-message']}>Wins</div>              
                  </div>
                  <div onClick={() => toggleResult('loss')} className={classes['checkbox-holder']}>
                    {results.find(element => element === 'loss') ?
                      <CheckboxCheckedIcon className={classes['checkbox-icon']} />
                    :
                      <CheckboxUnCheckedIcon className={classes['checkbox-icon']} />
                    }
                    <div className={classes['checkbox-message']}>Losses</div>              
                  </div>                 
                </div>
              </div>                                      
            </fieldset>
          </div>      
          <div className={classes['stream-display']}>
            {battles.map( ( battle, index ) => (
              <BattleRow key={index} battleResult={battle} rowIndex={index}/>
            ))}          
          </div>  
        </div>                 
      </div>
    </div>           
  );
};

export default BattleHistory;