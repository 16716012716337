import { ICard, PlayerState } from '../../../store/types/playerTypes';
import classes from './MobileCardSection.module.css';
import {ReactComponent as CrossIcon} from '../../../assets/svg/cross.svg';
import MobileCardDisplay from './MobileCardDisplay/MobileCardDisplay';

interface CardSectionProps {
  playerState:PlayerState;  
  infoFunction:Function;
  sendFunction:Function;
  currentModule:string;
  slot:number;  
  currentHeroes:ICard[];
  currentVillains:ICard[];
  cancelFunction:Function;
  showHeroes:boolean;
}

const MobileCardSection = ( { playerState, infoFunction, sendFunction, 
  currentModule, slot, cancelFunction, currentHeroes, currentVillains, showHeroes }:CardSectionProps ) => {    
  
  return (
    <div className={classes['card-section-wrapper']}>
      <div className={classes['top-row']}>
        <div className={classes['card-section-title']}>Choose a Card</div> 
        <div onClick={() => cancelFunction()} className={classes['cross-holder']}>        
          <CrossIcon className={classes['cross-icon']} />        
        </div>             
      </div>
      <div className={classes['player-cards']}>
        {playerState && playerState.player && playerState.player.cardPool &&
          playerState.player?.cardPool.map( card => (
            (card.faction === 'hero' && showHeroes) || (card.faction == 'villain' && showHeroes === false) ?
              <MobileCardDisplay key={card.id} card={card}
                initialRarity={card.rarities_owned[0]}
                missionFunction={sendFunction}
                infoFunction={infoFunction}
                currentModule={currentModule}
                slot={slot}   
                currentHeroes={currentHeroes}
                currentVillains={currentVillains}                         
                />
            : null
          ))
        }
      </div>     
    </div>
  );
};

export default MobileCardSection;
