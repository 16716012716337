import { useDispatch, useSelector } from "react-redux";
import classes from './MobileCardDetail.module.css';
import { getGameStateSelector } from "../../../store/selectors/gameSelectors";
import { GameState } from "../../../store/types/gameTypes";
import { clearCardDetails } from "../../../store/actions/gameActions";

import ReactTooltip from "react-tooltip";

import shipIcon from '../../../assets/images/general/icons/armor.png';
import rarityIcon from '../../../assets/images/general/icons/icon_rarity.png';
import projectileIcon from '../../../assets/images/general/icons/weapon-projectile.png';
import missileIcon from '../../../assets/images/general/icons/weapon-missile.png';
import armorIcon from '../../../assets/images/general/icons/icon_health.png';
import shieldsIcon from '../../../assets/images/general/icons/icon_barrier.png';
import { indexToRarity, rarityToIndex } from "../../../hoc/CommonFunctions";

import heroIcon from '../../../assets/images/general/icon_hero.png';
import villainIcon from '../../../assets/images/general/icon_villain.png';

const MobileCardDetail = () => {
  const dispatch = useDispatch();
  const gameState:GameState = useSelector(getGameStateSelector);
  
  // Close this popup
  const closeCardDetail = () => {
    dispatch(clearCardDetails());
  }

  if (gameState.cardDetails && gameState.cardDetails.name) {
   
    let weaponImage = projectileIcon;
    let weaponTipText = "Common beam weapon";
    switch (gameState.cardDetails.weapon_type) {
      case "physical":
        weaponImage = projectileIcon;
        weaponTipText = "A physical attack";
        break;
      case "energy":        
        weaponImage = missileIcon;
        weaponTipText = "An Energy Weapon";
        break;                  
    }

    return (
      <div onClick={() => closeCardDetail()} className={classes['wrapper']}>
        <div className={classes['popup-content-image']}>
          <div className={classes['popup-top-row']}>                                               
            <div className={classes['left-column']}>                          
              <div  className={classes['ship-image']}>
                <img className={classes['ship-image']} src={gameState.cardDetails.rarity_stats[rarityToIndex(gameState.cardDetails.rarity)].image}></img>
              </div>                                                                             
            </div>
            <div className={classes['right-side']}>
              <div className={classes['stats-block']}>
                <div className={classes['stat-row']}>
                  
                  <div className={classes['stat-details']}>
                   
                    <div className={classes['stat-base-text']}>{gameState.cardDetails.name.toUpperCase()}</div>                      
                  </div>   
                  <ReactTooltip backgroundColor="rgba(113,7,116,0.8)" arrowColor="rgba(113,7,116,0.8)" className={classes['tip-text']} id="nameTip" place="top" effect="solid">
                    The name of the Card
                  </ReactTooltip>               
                </div>                
                <div className={classes['stat-row']}>
                  <img data-tip data-for="rarityTip" className={classes['stat-image']} src={rarityIcon} />
                  <div className={classes['stat-details']}>
                    <div className={[classes['card-rarity'],classes['card-rarity-'+gameState.cardDetails.rarity]].join(' ')}>{gameState.cardDetails.rarity.toUpperCase()}</div>
                  </div>  
                  <ReactTooltip backgroundColor="rgba(113,7,116,0.8)" arrowColor="rgba(113,7,116,0.8)" className={classes['tip-text']} id="rarityTip" place="top" effect="solid">
                    The rarity of the card
                  </ReactTooltip>                
                </div>
              
                <div className={classes['stat-row']}>
                  <img data-tip data-for="weaponTip" className={classes['stat-image']} src={weaponImage} />
                  <div className={classes['stat-details']}>
                    <div className={classes['stat-base-text']}>{gameState.cardDetails.rarity_stats[rarityToIndex(gameState.cardDetails.rarity)].base_attack}</div>  
                    {gameState.cardDetails.avatarUpgrade.weapon > 0 ? 
                      <div className={classes['stat-avatar-text']}>+{gameState.cardDetails.avatarUpgrade.weapon}</div>  
                      : 
                      null
                    }
                  </div>
                  <ReactTooltip backgroundColor="rgba(113,7,116,0.8)" arrowColor="rgba(113,7,116,0.8)" className={classes['tip-text']} id="weaponTip" place="top" effect="solid">
                    {weaponTipText}
                  </ReactTooltip>
                </div>
                <div className={classes['stat-row']}>
                  <img                  
                    data-tip data-for="shieldTip"     
                    className={classes['stat-image']} 
                    src={shieldsIcon} />
                  <div className={classes['stat-details']}>
                    <div className={classes['stat-base-text']}>{gameState.cardDetails.rarity_stats[rarityToIndex(gameState.cardDetails.rarity)].base_barrier}</div>                      
                  </div>
                  <ReactTooltip backgroundColor="rgba(113,7,116,0.8)" arrowColor="rgba(113,7,116,0.8)" className={classes['tip-text']} id="shieldTip" place="top" effect="solid">
                    Barriers block any energy attacks
                  </ReactTooltip>
                </div>
                <div className={classes['stat-row']}>
                  <img 
                    data-tip data-for="armorTip"   
                    className={classes['stat-image']} 
                    src={armorIcon} />
                  <div className={classes['stat-details']}>
                    <div className={classes['stat-base-text']}>{gameState.cardDetails.rarity_stats[rarityToIndex(gameState.cardDetails.rarity)].base_health}</div>                                      
                  </div>
                  <ReactTooltip  backgroundColor="rgba(113,7,116,0.8)" arrowColor="rgba(113,7,116,0.8)" className={classes['tip-text']} id="armorTip" place="top" effect="solid">
                    This character’s health
                  </ReactTooltip>
                </div>
                <div className={classes['special-row']}>                                 
                  <div className={classes['special-title']}>Special:</div>                                                              
                  <div className={classes['stat-details']}>{gameState.cardDetails.rarity_stats[rarityToIndex(gameState.cardDetails.rarity)].special_description}</div>                 
                </div>                              
              </div>    
            </div>     
            <div className={classes['description-text-landscape']}>{gameState.cardDetails.description}</div>           
          </div>  
          <div className={classes['description-text-portrait']}>{gameState.cardDetails.description}</div>        
        </div>        
      </div>  
    );
  } else {
    return (<div>Loading...</div>);
  }
  
};

export default MobileCardDetail;