import { useDispatch, useSelector } from "react-redux";
import classes from './LoginForm.module.css';
import {isMobile} from 'react-device-detect';

import {ReactComponent as CheckboxCheckedIcon} from '../../assets/svg/checkbox-checked.svg';
import {ReactComponent as CheckboxUnCheckedIcon} from '../../assets/svg/checkbox-unchecked.svg';
import { useCallback, useEffect, useState } from "react";
import { setBusyAutoLogin, updateMobileMode } from "../../store/actions/gameActions";
import { GameState } from "../../store/types/gameTypes";
import { getGameStateSelector } from "../../store/selectors/gameSelectors";
import { initializeUAL, verifyUAL } from "../../store/service/ualService";
import { useHistory, useLocation } from "react-router-dom";

const LoginForm = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  
  const gameState:GameState = useSelector(getGameStateSelector);

  const [currentEndpointIndex, setCurrentEndpointIndex] = useState(0);

  const chains = [
    "wax.greymass.com",
    "api.wax.alohaeos.com",
    "api.waxsweden.org",
    "wax.pink.gg",
    "wax.eosphere.io"     
  ];
  
  // Detect the device being used
  useEffect(() => {   
    if (isMobile) {
      dispatch(updateMobileMode(true));
    }  else {
      dispatch(updateMobileMode(false));
    }   
    
    // If we are on the show battle screen, go home
    if (location.pathname === '/show-battle') {
      history.push("/");
    }
  }, []);

  const tryAutoLogin = (e:any, rpcIndex:number) => {   
    e.preventDefault();

    // Show that we are checking for auto log in
    dispatch(setBusyAutoLogin(true));

    // Hide it in 5 secs
    setTimeout(() => hideBusyAutoLogin(), 5000);
    
    // Hide our login button
    const button = document.getElementById("fgl-login-button");  
    if (button) {
      button.style.display = 'none';      
    }  
    
    initializeUAL("", rpcIndex, dispatch); 
  };

  // Hide the busy autologin
  const hideBusyAutoLogin = () => {
    dispatch(setBusyAutoLogin(false));
  }

  // Toggle the checkbox
  const toggleChecked = () => {    
    dispatch(updateMobileMode(!gameState.mobileMode));
  }

  // Cycle through endpoints to try
  const cycleEndpoint = () => { 
    // Go to the next one
    let currentIndex:number = currentEndpointIndex; 
    currentIndex++;

    if (currentIndex >= chains.length) {
      currentIndex = 0;
    }

    setCurrentEndpointIndex(currentIndex);    
  }

  // Go buy some tokens on alcor
  const showMoreInfo = () => {
    window.open("https://fglnft.medium.com/fgl-bc-heroes-team-up-to-bring-you-blockchain-heroes-blitz-f870dc55fe6a")
  };


  return (
      <div className={classes['wrapper']}>
          <div className={classes['login-page']}>            
            <div className={classes['logo-holder']}>
              <img alt="Logo" src={'https://cdn.cosmicclash.io/bchblitz/general/heroes_blitz.png'} className={classes['logo-holder']} />
            </div>

            <div className={classes['login-block']}>
              <div id="login-holder" className={classes['logins']}>
                  <div id="ual-div">
                    <button id="fgl-login-button" onClick={(e) => tryAutoLogin(e, currentEndpointIndex)} className={classes['login-button']} >PLAY</button>
                  </div>                
              </div>   

              <div className={classes['title']}>
                  Blockchain Heroes Blitz currently supports My Cloud Wallet and Anchor Wallet<br/>v{gameState.buildVersion}
              </div>       
            </div>

            <div className={classes['login-block']}>             
              <div className={classes['title-large']}>
                  What is this game?
              </div>  
              <button onClick={(e) => showMoreInfo()} className={classes['info-button']} >More Info</button>     
            </div>
                        
            <div className={classes['login-block']}>          
              <div onClick={() => toggleChecked()} className={classes['confirm-popup-checkbox-holder']}>
                {gameState.mobileMode ?
                  <CheckboxCheckedIcon className={classes['checkbox-icon']} />
                :
                  <CheckboxUnCheckedIcon className={classes['checkbox-icon']} />
                }
                <div className={classes['checkbox-message']}>Run in Mobile Device Mode</div>              
              </div>   
              <div onClick={() => cycleEndpoint()} className={classes['rpc-holder']}>                            
                <div className={classes['rpc-label']}>RPC Endpoint: </div>                       
                <div className={classes['rpc-endpoint']}>{chains[currentEndpointIndex]}</div>              
              </div>       
            </div>     
          </div>
      </div>
  );
};

export default LoginForm;
