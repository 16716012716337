import classes from './BattleCardDisplay.module.css';
import {ReactComponent as CrossIcon} from '../../../../assets/svg/clear.svg';
import ReactTooltip from "react-tooltip";
import { ICard } from '../../../../store/types/playerTypes';
import { rarityToIndex } from '../../../../hoc/CommonFunctions';

interface BattleCardDisplayProps {
  card:ICard;
  rowIndex:number;
  cardIndex:number;
}

const BattleCardDisplay = ( { card, rowIndex, cardIndex }:BattleCardDisplayProps ) => {  
  let imgStyle = {};

  if (card.currentHealth === 0) {
    imgStyle = {
      "max-width": '100%',
      "filter": "grayscale(100%)" 
    };
  }
    
  return (
    <div data-tip data-for={'cardTip'+rowIndex+'_'+cardIndex+'_'+card.isPlayerCard} className={classes['battle-step']}>
      {card  ?
        <div className={classes['battle-side']}>
          <div className={[classes['card-holder'],classes['card-holder-'+card.rarity]].join(' ')}>                
            {card.currentHealth === 0 ? 
              <>
                <div  className={classes['middle-row']}>
                  <img alt="Card" className={classes['card-image-dead']} src={card.rarity_stats[rarityToIndex(card.rarity)].image}></img>
                </div>            
                <div className={classes['cross-holder']}>          
                  <CrossIcon className={classes['cross-icon']} />          
                </div>
              </>
            : 
              <div  className={classes['middle-row']}>
                <img alt="Card" className={classes['card-image']} src={card.rarity_stats[rarityToIndex(card.rarity)].image}></img>
              </div>
            }            
          </div>          
        </div>
      : 
        <div  className={classes['empty-block']}>&nbsp;</div> 
      }     
      <ReactTooltip backgroundColor="rgba(113,7,116,1)" arrowColor="rgba(113,7,116,1)" className={classes['tip-text']} id={'cardTip'+rowIndex+'_'+cardIndex+'_'+card.isPlayerCard} place="top" effect="solid">
        {card.name} 
      </ReactTooltip> 
    </div>
  );
};

export default BattleCardDisplay;
