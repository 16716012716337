import { useDispatch, useSelector } from "react-redux";
import classes from './BattleLog.module.css';
import { getPlayerStateSelector } from "../../store/selectors/playerSelectors";
import { hideBattleLog } from "../../store/actions/playerActions";
import BattleLogStep from "./BattleLogStep";
import { useEffect, useState } from "react";

import {ReactComponent as CheckboxCheckedIcon} from '../../assets/svg/checkbox-checked.svg';
import {ReactComponent as CheckboxUnCheckedIcon} from '../../assets/svg/checkbox-unchecked.svg';
import { IBattleStep } from "../../store/types/playerTypes";
import BattleLogCards from "./BattleLogCards";

const BattleLog = () => {
  const dispatch = useDispatch();
  
  const playerState:any = useSelector(getPlayerStateSelector); 
  const [deathsOnly, setDeathsOnly] = useState(false); 
  
  const [localSteps, setLocalSteps] = useState<IBattleStep[] | []>([]);
  
  // Update the local steps whenever we get new steps or toggle deaths only
  useEffect(() => {   
    if (playerState.battleResult?.battleSteps) {            
      let tempSteps:IBattleStep[] = [];
      for (let i = 0; i < playerState.battleResult.battleSteps.length; i++) {
        if (deathsOnly && playerState.battleResult.battleSteps[i].defendingCard.currentHealth === 0 && 
          !playerState.battleResult.battleSteps[i].attackingCard.slotChanged) {

          tempSteps.push(playerState.battleResult.battleSteps[i]);
        } else if (!deathsOnly) {
          tempSteps.push(playerState.battleResult.battleSteps[i]);
        }
      }

      setLocalSteps(tempSteps);
    }

  }, [ playerState.battleResult?.battleSteps, deathsOnly ]);

  // Close this popup
  const closeLog = () => {
    dispatch(hideBattleLog());
  }

  // Toggle the checkbox
  const toggleChecked = () => {
    setDeathsOnly(!deathsOnly);
  }
  
  return (
    playerState.battleResult ? 
      <div className={classes['wrapper']}>
        <div className={classes['log-holder']}> 
          <div className={classes['log-title']}>Battle Result</div>                        
          <div className={classes['log-players']}>
            <div className={classes['log-caption']}>{playerState.player.name} vs {playerState.battleResult.enemyName}</div>
          </div>            
          <div className={classes['log-contents']}>
            <BattleLogCards cards={playerState.battleResult.enemyVillainsFinal} isPlayer={false} playerName={playerState.battleResult.enemyName} />
            <BattleLogCards cards={playerState.battleResult.playerHeroesFinal} isPlayer={true} playerName={playerState.player.name} />
            {localSteps.map( (step:any, index:number ) => (              
                <BattleLogStep key={index} step={step} deathsOnly={deathsOnly} />            
              ))
            }                  
          </div>
          <div className={classes['button-holder']}>
            <button className={classes['close-button']} onClick={() => closeLog()}>Close</button>
            <div onClick={() => toggleChecked()} className={classes['checkbox-holder']}>
              {deathsOnly ?
                <CheckboxCheckedIcon className={classes['checkbox-icon']} />
              :
                <CheckboxUnCheckedIcon className={classes['checkbox-icon']} />
              }
              <div className={classes['checkbox-message']}>Show Deaths Only</div>              
            </div>
          </div>
        </div>
      </div>
    : null
  );  
};

export default BattleLog;