import classes from './MobileBattleConfirmation.module.css';
import {ReactComponent as CrossIcon} from '../../../assets/svg/cross.svg';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { clearBattleResults, commencePracticeBattle, commenceTournamentBattle } from '../../../store/actions/playerActions';
import { useEffect, useState } from 'react';
import { hideBattleConfirmation, setGeneralBusyMessage } from '../../../store/actions/gameActions';

import {ReactComponent as CheckboxCheckedIcon} from '../../../assets/svg/checkbox-checked.svg';
import {ReactComponent as CheckboxUnCheckedIcon} from '../../../assets/svg/checkbox-unchecked.svg';
import { GameState } from '../../../store/types/gameTypes';
import { getGameStateSelector } from '../../../store/selectors/gameSelectors';
import { PlayerState } from '../../../store/types/playerTypes';
import { getPlayerStateSelector } from '../../../store/selectors/playerSelectors';

const MobileBattleConfirmation = () => {    
  const dispatch = useDispatch();
  let history = useHistory();

  const gameState:GameState = useSelector(getGameStateSelector);
  const playerState:PlayerState = useSelector(getPlayerStateSelector);
  const [skipChecked, setSkipChecked] = useState(true);

  let clicksEnabled = true;

  // Check local storage for the skipChecked setting
  useEffect(() => {   
    let skipSimulation:string | null = window.localStorage.getItem('skipSimulation');
    if (skipSimulation && skipSimulation === 'true') {
      setSkipChecked(true);
    } else if (skipSimulation) {
      setSkipChecked(false);
    }    

  }, []);
  
  // TO BATTLE!
  const clickConfirmBattle = () => {
    if (clicksEnabled){
      clicksEnabled=false;

      // Show a "waiting" message until we receive a response
      dispatch(setGeneralBusyMessage("Validating Cards..."));

      // Clear the previous battle
      dispatch(clearBattleResults());
        
      // Do the pre-flight check
      if (gameState.practice) {
        dispatch(commencePracticeBattle(gameState.heroes, gameState.heroRarities, gameState.villains, gameState.villainRarities, skipChecked, history, gameState.gameMode === 'weekly' ? true : false));
      } else {       
        // Show a "waiting" message until we receive a response
        dispatch(setGeneralBusyMessage("Deploying Cards..."));

        // Send it!
        dispatch(commenceTournamentBattle(gameState.heroes, gameState.heroRarities, gameState.villains, gameState.villainRarities, gameState.rematch, 
          skipChecked, history, gameState.gameMode === 'seasonal' ? true : false));        
      }
      
      // Hide this popup
      dispatch(hideBattleConfirmation());    

      // Save this skip value to local storage
      window.localStorage.setItem('skipSimulation', skipChecked ? 'true' : 'false');
    }
  }

  // Close this popup
  const closePopup = () => {
    dispatch(hideBattleConfirmation());
  }

  // Toggle the checkbox
  const toggleChecked = () => {
    setSkipChecked(!skipChecked);
  }
    
  return (
    <div className={classes['main-content']}>           
      <div className={classes['popup-content-image']}>                                    
        {gameState.practice ?
          <div className={classes['popup-title']}>Practice Battle</div>                       
        :
          <div className={classes['popup-title']}>Battle Preparation</div>
        }                     
                
        {gameState.rematch ? 
          <div className={classes['message-details-row']}>
            <div className={classes['message-details-title']}>Rematch:</div>
            {gameState.gameMode === 'seasonal' ?
              <div className={classes['message-details-value']}>{playerState.player?.rematch_name}</div>
            :
              <div className={classes['message-details-value']}>{playerState.player?.rematch_name_weekly}</div>
            }
          </div>
        : null }
                
        <div className={classes['confirm-popup-button-holder']}>
          <button className={classes['popup-button-cancel']} onClick={() => closePopup()}>Cancel</button>
          <div onClick={() => toggleChecked()} className={classes['confirm-popup-checkbox-holder']}>
            {skipChecked ?
              <CheckboxCheckedIcon className={classes['checkbox-icon']} />
            :
              <CheckboxUnCheckedIcon className={classes['checkbox-icon']} />
            }
            <div className={classes['checkbox-message']}>Skip Simulation</div>              
          </div>

          <button className={classes['popup-button']} onClick={() => clickConfirmBattle()}>Battle</button>
        </div>
        
        <div className={classes['confirm-popup-button-holder-portrait']}>
          <div onClick={() => toggleChecked()} className={classes['confirm-popup-checkbox-holder']}>
            {skipChecked ?
              <CheckboxCheckedIcon className={classes['checkbox-icon']} />
            :
              <CheckboxUnCheckedIcon className={classes['checkbox-icon']} />
            }
            <div className={classes['checkbox-message']}>Skip Simulation</div>              
          </div>
          <button className={classes['popup-button']} onClick={() => clickConfirmBattle()}>Battle</button>
          <button className={classes['popup-button-cancel']} onClick={() => closePopup()}>Cancel</button>                    
        </div>
      </div>      
    </div>
  );  
}

export default MobileBattleConfirmation;