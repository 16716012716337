import classes from './MobileCardsBlock.module.css';
import {ReactComponent as RocketIcon} from '../../../../assets/svg/user-secret.svg';
import {ReactComponent as InfoIcon} from '../../../../assets/svg/info.svg';
import {ReactComponent as RemoveIcon} from '../../../../assets/svg/cancel-circle.svg';
import { GameState } from '../../../../store/types/gameTypes';
import arrowImage from '../../../../assets/images/general/arrow-left.png';
import arrowImageWhite from '../../../../assets/images/general/arrow-left-white.png';
import { ICard } from '../../../../store/types/playerTypes';
import { rarityToIndex } from '../../../../hoc/CommonFunctions';

interface ActiveCardsBlockProps {
  card:ICard;  
  slotIndex:number;
  removeFunction:Function;
  moveFunction:Function;
  infoFunction:Function;
  gameState:GameState;
  configuration:string;
  chooseFunction:Function;
  updateRarityFunction:Function; 
  rarities:string[];
}

const MobileCardsBlock = ( { card, slotIndex, removeFunction, moveFunction, 
  infoFunction, gameState, configuration, chooseFunction, updateRarityFunction, 
  rarities}:ActiveCardsBlockProps ) => {  
  
    let imgStyle = {};  
    let holderClasses = "";
    let currentRarity:string = "";   
     if (rarities && rarities[slotIndex]) {
      currentRarity = rarities[slotIndex];
    }
  
    if (card && card.id > 0 && gameState.game) {        
      holderClasses = [classes['card-holder'],classes['card-holder-'+currentRarity]].join(' ');
      if (configuration === 'home') {
        holderClasses = [classes['battle-holder'],classes['card-holder-'+currentRarity]].join(' ');
      }    
    }
  
    return (
      card && card.id > 0 ?  
      <div className={holderClasses}>
      <div className={classes['holder-portrait']}>
        <div className={[classes['left-column'],classes['left-column-'+card.rarity]].join(' ')}>          
          <InfoIcon onClick={() => infoFunction(card)} className={classes['info-icon']} />                                              
          <RemoveIcon onClick={() => removeFunction(slotIndex, card)} className={classes['remove-icon']} />       
          
          <div className={classes['card-name']}>{card.name}</div>            
          <img src={arrowImage} alt="Move Up"  className={classes['move-button-left']} onClick={() => moveFunction(slotIndex, true, card)} />
          <img src={arrowImage} alt="Move Down"  className={classes['move-button-right']} onClick={() => moveFunction(slotIndex, false, card)} />          
        </div>                              
        <img className={classes['card-image']} src={card.rarity_stats[rarityToIndex(card.rarity)].image}></img>  
      </div>   
      <div className={classes['holder-landscape']}>
        <div className={[classes['top-row'],classes['top-row-'+card.rarity]].join(' ')}>              
          <InfoIcon onClick={() => infoFunction(card)} className={classes['info-icon']} />              
          <div className={classes['card-name']}>{card.name}</div>         
          <RemoveIcon onClick={() => removeFunction(slotIndex, card)} className={classes['remove-icon']} />                 
        </div>                         
        <img className={classes['card-image']} src={card.rarity_stats[rarityToIndex(card.rarity)].image}></img>                      
        <div className={classes['bottom-row']}>
          <img src={arrowImageWhite} alt="Move Left"  className={classes['move-button-left']} onClick={() => moveFunction(slotIndex, true, card)} />
          <img src={arrowImageWhite} alt="Move Left"  className={classes['move-button-right']} onClick={() => moveFunction(slotIndex, false, card)} />          
        </div>                    
      </div>   
    </div>  
    :
      <div className={classes['slot-empty']}>
        <div className={classes['slot-empty-left']}>
          <div className={classes['slot-empty-text']}>- Unassigned -</div>
          <button className={classes['choose-button']} onClick={() => chooseFunction(slotIndex)}>Add Card</button>               
        </div>
        <div className={classes['rocket-holder']}>
          <RocketIcon  className={classes['rocket-icon']} />
        </div>
      </div>
  );
};

export default MobileCardsBlock;
