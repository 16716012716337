import { Route, Switch, withRouter } from 'react-router-dom';
import PlayBattle from './components/PlayBattle/PlayBattle';
import ShowBattle from './components/ShowBattle/ShowBattle';
import Layout from "./hoc/Layout";
import PlayRankings from './components/PlayRankings/PlayRankings';
import { GameState } from './store/types/gameTypes';
import { useSelector } from 'react-redux';
import { getGameStateSelector } from './store/selectors/gameSelectors';
import MobileRankings from './components/Mobile/MobileRanking/MobileRankings';
import MobileBattle from './components/Mobile/MobileBattle/MobileBattle';
import BattleHistory from './components/BattleHistory/BattleHistory';
import MobileBattleHistory from './components/Mobile/MobileBattleHistory/MobileBattleHistory';
import Simulator from './components/Simulator/Simulator';
import { PlayerState } from './store/types/playerTypes';
import { getPlayerStateSelector } from './store/selectors/playerSelectors';
import MobileSimulator from './components/Mobile/MobileSimulator/MobileSimulator';
import CardStats from './components/CardStats/CardStats';
import MobileMyCards from './components/Mobile/MobileMyCards/MobileMyCards';
import MobileCardStats from './components/Mobile/MobileCardStats/MobileCardStats';

const App = () => {  
  const playerState:PlayerState = useSelector(getPlayerStateSelector);
  const gameState:GameState = useSelector(getGameStateSelector);

  return (
    <Layout>        
      {gameState.mobileMode === false ?
        <Switch>                              
          <Route exact path="/" component={PlayBattle} />          
          <Route exact path="/battle" component={PlayBattle} />
          <Route exact path="/rankings" component={PlayRankings} />          
          <Route exact path="/show-battle" component={ShowBattle} />   
          <Route exact path="/stats" component={CardStats} />  
          <Route exact path="/history" component={BattleHistory} />  
          <Route exact path="/simulator" component={Simulator} />            
          <Route path="/" component={PlayBattle} />
        </Switch>        
      : 
        <Switch>                                                  
          <Route exact path="/battle" component={MobileBattle} /> 
          <Route exact path="/rankings" component={MobileRankings} /> 
          <Route exact path="/rankings-weekly" component={MobileRankings} />
          <Route exact path="/show-battle" component={ShowBattle} />   
          <Route exact path="/my-cards" component={MobileMyCards} />             
          <Route exact path="/stats" component={MobileCardStats} />  
          <Route exact path="/history" component={MobileBattleHistory} />           
          <Route exact path="/" component={MobileBattle} /> 
        </Switch>     
      }
    </Layout>
  );
}

export default withRouter(App);