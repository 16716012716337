import classes from './GeneralBusyModal.module.css';
import spinnerImage from '../../../assets/images/general/loading-animation.png';

interface GeneralBusyProps {
  message: string;
}

const GeneralBusyModal = ( { message }:GeneralBusyProps ) => {
    return (
        <div className={classes['wrapper']}>
            <div className={classes['section']}>                
                <div className={classes['spritesheet-container']}>
                  <img alt="Busy" className={classes['spritesheet']} src={spinnerImage}></img>
                </div>
                <div className={classes['description']}>{message}</div>
            </div>  
        </div>                  
    );
};

export default GeneralBusyModal;