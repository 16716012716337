import classes from './MobileBattleLogStep.module.css';
import { ICard } from '../../../store/types/playerTypes';
import {ReactComponent as CrossIcon} from '../../../assets/svg/clear.svg';
import ReactTooltip from "react-tooltip";
import { rarityToIndex } from '../../../hoc/CommonFunctions';

interface BattleLogCardsProps {
  cards: ICard[],
  isPlayer: boolean,
  playerName: string
}

const MobileBattleLogCards = ( {cards, isPlayer, playerName}:BattleLogCardsProps ) => {  
  let styles:any[] = [];  

  for (let i:number = 0; i < cards.length; i++) {
    if (cards[i] && cards[i].id > 0) {
      // Figure out the style of this card      
      if (cards[i].currentHealth <= 0) {
        styles.push({
          "max-height": '100%',
          "filter": "grayscale(100%)"
        });
      } else {
        styles.push({
          "max-height": '100%'          
        });
      }      
    }
  }  

  return (
    <div className={classes['battle-step-fleet']}>
      <div className={classes['battle-fleet-title']}>{playerName}</div>
      {cards.map( (card:any, index:number ) => (    
        card ?
          <div data-tip data-for={"cardTip"+isPlayer+card.id} key={index} className={classes['battle-side-fleet']}>
            <div className={[classes['card-holder'],classes['card-holder-'+card.rarity]].join(' ')}>                
              <div  className={classes['middle-row']}>
                <img alt="Card" style={styles[index]} src={card.rarity_stats[rarityToIndex(card.rarity)].image}></img>
              </div>
              {card.currentHealth === 0 ? 
                <div className={classes['cross-holder']}>          
                  <CrossIcon className={classes['cross-icon']} />          
                </div>
                : null
              }
            </div>     
            <ReactTooltip backgroundColor="rgba(113,7,116,0.8)" arrowColor="rgba(113,7,116,0.8)" className={classes['tip-text']} id={"cardTip"+isPlayer+card.id} place="top" effect="solid">
              {card.name}
            </ReactTooltip>        
          </div>
        : 
          <div key={index} className={classes['empty-block']}>&nbsp;</div>         
      ))}      
    </div>
  );
};

export default MobileBattleLogCards;
