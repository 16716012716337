import {
  USER_LOGIN,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAILURE,
  USER_RESET_ERROR,
  CLEAR_WAX_ERROR
} from "./actionTypes";
import {  
  UserLogin,
  UserLoginSuccess,
  UserLoginSuccessPayload,
  UserLoginFailure,
  UserLoginFailurePayload,
  UserResetError,
  ClearWaxErrorRequest
} from "../types/waxTypes";

export const login = (): UserLogin => ({
  type: USER_LOGIN,
});

export const loginSuccess = (
  payload: UserLoginSuccessPayload
): UserLoginSuccess => ({
  type: USER_LOGIN_SUCCESS,
  payload,
});

export const loginFailure = (
  payload: UserLoginFailurePayload
): UserLoginFailure => ({
  type: USER_LOGIN_FAILURE,
  payload,
});

export const resetError = (): UserResetError => ({
  type: USER_RESET_ERROR
});

// Clear wax state error
export const clearWaxError = (): ClearWaxErrorRequest => ({
  type: CLEAR_WAX_ERROR
});