import classes from './ActiveCardsBlock.module.css';
import {ReactComponent as RocketIcon} from '../../../assets/svg/user-secret.svg';
import {ReactComponent as InfoIcon} from '../../../assets/svg/info.svg';
import {ReactComponent as RemoveIcon} from '../../../assets/svg/cancel-circle.svg';
import { ICard } from '../../../store/types/playerTypes';
import { GameState } from '../../../store/types/gameTypes';
import arrowImage from '../../../assets/images/general/arrow-left-white.png';
import { rarityToIndex } from '../../../hoc/CommonFunctions';

interface ActiveCardsBlockProps {
  card:ICard;  
  slotIndex:number;
  removeFunction:Function;
  moveFunction:Function;
  infoFunction:Function;
  gameState:GameState;
  configuration:string;
  updateRarityFunction:Function; 
  rarities:string[];
}

const ActiveCardsBlock = ( { card, slotIndex, removeFunction, moveFunction, infoFunction, gameState, configuration, updateRarityFunction, rarities }:ActiveCardsBlockProps ) => {    
  return (
    card && card.id > 0 ?  
      <div className={[classes['card-holder'],classes['card-holder-'+card.rarity]].join(' ')}>
        <div className={[classes['top-row'],classes['top-row-'+card.rarity]].join(' ')}>              
          <InfoIcon onClick={() => infoFunction(card)} className={classes['info-icon']} />              
          <div className={classes['ship-name']}>{card.name}</div>  
          {configuration === 'battle' ?
            <RemoveIcon onClick={() => removeFunction(slotIndex, card)} className={classes['remove-icon']} />       
          : null
          }
        </div>   
                  
        <div  className={classes['card-image']}>
          {card.rarity_stats[rarityToIndex(card.rarity)].gif ?
            <img className={classes['card-image']} alt="Card"  src={card.rarity_stats[rarityToIndex(card.rarity)].gif}></img>
          :
            <img className={classes['card-image']} alt="Card"  src={card.rarity_stats[rarityToIndex(card.rarity)].image}></img>
          }          
        </div>        
           
        <div className={classes['bottom-row']}>
          <img src={arrowImage} alt="Move Left"  className={classes['move-button-left']} onClick={() => moveFunction(slotIndex, true, card)} />
          <img src={arrowImage} alt="Move Left"  className={classes['move-button-right']} onClick={() => moveFunction(slotIndex, false, card)} />
        </div> 
                          
      </div>    
    :
      <div className={classes['slot-empty']}>
        <div className={classes['slot-empty-text']}>- Unassigned -</div>
        <RocketIcon  className={classes['rocket-icon']} />
      </div>
  );
};

export default ActiveCardsBlock;
