import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import classes from './MobileSideDrawer.module.css';
import {ReactComponent as CrossIcon} from '../../../assets/svg/cross.svg';
import simulatorImage from '../../../assets/images/general/icons/simulator.png';
import orbImage from '../../../assets/images/game/orbatroid.png';
import towerDefense from '../../../assets/images/game/tower_defense.png';
import prisonBreak from '../../../assets/images/game/orbatroid2_prison_nobg.png';
import zoneCapture from '../../../assets/images/game/target_acquired.png';
import starfish from '../../../assets/images/game/starfish.png';
import packImage from '../../../assets/images/general/radiance_pack.png';
import avatar69 from '../../../assets/images/general/avatar_69.png';

import Backdrop from '../../General/Misc/Backdrop';
import { GameState } from '../../../store/types/gameTypes';
import { getGameStateSelector } from '../../../store/selectors/gameSelectors';

interface MobileSideDrawerProps {  
  isOpen: boolean;
  closeFunction: Function;
}

const MobileSideDrawer = ( { isOpen, closeFunction }:MobileSideDrawerProps ) => {
  const gameState:GameState = useSelector(getGameStateSelector);
  
  let attachedClasses = [classes['side-drawer'], classes['close']];
  if (isOpen) {
      attachedClasses = [classes['side-drawer'], classes['open']];
  }

  // Go to the atomic hub for this collection
  const buyCards = () => {
    window.open('https://wax.atomichub.io/market?collection_name=officialhero&data.rarity=%5B"common"%2C"uncommon"%2C"scarce"%2C"rare"%2C"epic"%2C"legendary"%2C"mythic"%2C"secret"%5D&order=asc&primary_chain=wax-mainnet&schema_name=series3.x&sort=price&symbol=WAX');
  };

  // Go buy some tokens on alcor
  const buyTokens = () => {
    window.open("https://wax.alcor.exchange/trade/fgl-fgltokenmgmt_wax-eosio.token")
  };

  // Whitepaper
  const viewWhitepaper = () => {
    window.open("https://www.youtube.com/watch?v=EvbYgL8qdHc")
  };

  // Help
  const clickHelp = () => {
    window.open("https://discord.com/invite/XPcBsVHY8W")
  };


  
  const [timeLeft, setTimeLeft] = useState(0);
  const [formattedTimeLeft, setFormattedTimeLeft] = useState("00:00:00");

  useEffect(() => {
    let timer:any = setTimeout(updateTimeRemaining, 1000);
        
    // Clear timeout if the component is unmounted
    return () => {
      if (timer)
        clearTimeout(timer);
    }
  });

  // If the start / end dates change reset the time
  useEffect(() => {           
    let left:number = 1659182400000 - Date.now();    
    setTimeLeft(left);  
      
  }, []);
  
  const updateTimeRemaining = () => {
    if (timeLeft > 0) {
      
      let newTime:number = 1659182400000 - Date.now();  
      if (newTime <= 0) {
        newTime = 0;
      }
      setTimeLeft(newTime);
      
      setFormattedTimeLeft(        
        formatTime(Math.floor((newTime / (1000 * 60 * 60))).toString()) + ":" +
        formatTime(Math.floor((newTime / 1000 / 60) % 60).toString()) + ":" + 
        formatTime(Math.floor((newTime / 1000) % 60).toString())
      )      
    } 
  };

  const formatTime = (time:string) => {
    if (time.length === 1) {
      return "0" + time;
    } else {
      return time;
    }
  }

  return (
    <>            
      <Backdrop show={isOpen} clicked={closeFunction}/>
      <div className={attachedClasses.join(' ')}>                          
        <div onClick={() => closeFunction()} className={classes['top-row']}>
          <CrossIcon className={classes['close-button']} />
        </div>
        <div className={classes['content-column']}>                   
          <Link className={classes['link']} to={{pathname: '/battle'}}>
            <div onClick={() => closeFunction()} className={classes['button-group']}>              
              <div className={classes['button-label']} >Battle</div>
            </div>
          </Link>
          <Link className={classes['link']} to={{pathname: '/my-cards'}}>
            <div onClick={() => closeFunction()} className={classes['button-group']}>              
              <div className={classes['button-label']} >My Cards</div>
            </div>
          </Link>        
          <Link className={classes['link']} to={{pathname: '/rankings'}}>
            <div onClick={() => closeFunction()} className={classes['button-group']}>              
              <div className={classes['button-label']} >Ranking</div>
            </div>
          </Link>     
          <Link className={classes['link']} to={{pathname: '/stats'}}>
            <div onClick={() => closeFunction()} className={classes['button-group']}>              
              <div className={classes['button-label']} >Card Stats</div>
            </div>
          </Link>    
          <Link className={classes['link']} to={{pathname: '/history'}}>
            <div onClick={() => closeFunction()} className={classes['button-group']}>              
              <div className={classes['button-label']} >History</div>
            </div>
          </Link> 
          <Link className={classes['link']} to={{pathname: '/simulator'}}>
            <div onClick={() => clickHelp()} className={classes['button-group']}>              
              <div className={classes['button-label']} >Help</div>
            </div>
          </Link>
         
          

          <div className={classes['button-group-bottom']} >   
            
            <div onClick={() => viewWhitepaper()} className={classes['button-group']}>            
              <div className={classes['button-label']} >Tutorial</div>
            </div>
 
            <div className={classes['button-divider']} >|</div>                            
            
            <div onClick={() => buyCards()} className={classes['button-group']}>            
              <div className={classes['button-label']} >Buy Cards</div>
            </div>
            <div className={classes['button-divider']} >|</div>
            <div onClick={() => buyTokens()} className={classes['button-group']}>            
              <div className={classes['button-label']} >Buy Tokens</div>
            </div>                   
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileSideDrawer;