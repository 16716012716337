import classes from './CardFilter.module.css';
import {ReactComponent as CheckboxCheckedIcon} from '../../../assets/svg/checkbox-checked.svg';
import {ReactComponent as CheckboxUnCheckedIcon} from '../../../assets/svg/checkbox-unchecked.svg';
import { useState } from 'react';

interface CardFilterProps {
  setFilterFunction:Function;
  showOwnership:boolean;
}


const CardFilter = ( { setFilterFunction, showOwnership }:CardFilterProps ) => {    
  const [ownedArray, setOwnedArray] = useState<string[] | []>(['yes','no']);
  const [factions, setFactions] = useState<string[] | []>(['hero','villain']);
  const [weaponTypes, setWeaponTypes] = useState<string[] | []>(['physical','energy']);
 
  const toggleOwned = (owned:string) => {
    // Does this type exist in the types?
    let localOwned:string[] = ownedArray.slice();
    let foundIndex:number = -1;
    for (let i:number = 0; i < localOwned.length; i++) {
      if (localOwned[i] === owned) {
        foundIndex = i;
      }
    }

    if (foundIndex > -1) {
      // Remove it
      localOwned.splice(foundIndex, 1);
    } else {
      // Add type
      localOwned.push(owned);
    }

    // Update the filter
    setFilterFunction(factions, localOwned, weaponTypes);
    setOwnedArray(localOwned);
  }

  const toggleFaction = (faction:string) => {
    // Does this faction exist in the factions?
    let localFactions:string[] = factions.slice();
    let foundIndex:number = -1;
    for (let i:number = 0; i < factions.length; i++) {
      if (factions[i] === faction) {
        foundIndex = i;
      }
    }

    if (foundIndex > -1) {
      // Remove it
      localFactions.splice(foundIndex, 1);
    } else {
      // Add it
      localFactions.push(faction);
    }

    // Update the filter
    setFilterFunction(localFactions, ownedArray, weaponTypes);
    setFactions(localFactions);
  }

  const toggleWeaponType = (type:string) => {
    // Does this type exist in the weapons?
    let localWeapons:string[] = weaponTypes.slice();
    let foundIndex:number = -1;
    for (let i:number = 0; i < weaponTypes.length; i++) {
      if (weaponTypes[i] === type) {
        foundIndex = i;
      }
    }

    if (foundIndex > -1) {
      // Remove it
      localWeapons.splice(foundIndex, 1);
    } else {
      // Add it
      localWeapons.push(type);
    }

    // Update the filter
    setFilterFunction(factions,ownedArray, localWeapons);
    setWeaponTypes(localWeapons);
  }
    
  return (    
    <div className={classes['leaderboard-top-row']}> 
      <fieldset className={classes['details-block']}>
        <legend className={classes['details-title']}>Filter</legend>

        <div className={classes['details-column']}>
          <div className={classes['detail-title']}>Faction:</div>
          <div className={classes['detail-value']}>
            <div onClick={() => toggleFaction('hero')} className={classes['checkbox-holder']}>
              {factions.find(element => element === 'hero') ?
                <CheckboxCheckedIcon className={classes['checkbox-icon']} />
              :
                <CheckboxUnCheckedIcon className={classes['checkbox-icon']} />
              }
              <div className={classes['checkbox-message']}>Heroes</div>              
            </div>
            <div onClick={() => toggleFaction('villain')} className={classes['checkbox-holder']}>
              {factions.find(element => element === 'villain') ?
                <CheckboxCheckedIcon className={classes['checkbox-icon']} />
              :
                <CheckboxUnCheckedIcon className={classes['checkbox-icon']} />
              }
              <div className={classes['checkbox-message']}>Villains</div>              
            </div>
          </div>
        </div>

        <div className={classes['details-column']}>
          <div className={classes['detail-title']}>Weapon Type:</div>
          <div className={classes['detail-value']}>
            <div onClick={() => toggleWeaponType('physical')} className={classes['checkbox-holder']}>
              {weaponTypes.find(element => element === 'physical') ?
                <CheckboxCheckedIcon className={classes['checkbox-icon']} />
              :
                <CheckboxUnCheckedIcon className={classes['checkbox-icon']} />
              }
              <div className={classes['checkbox-message']}>Physical</div>              
            </div>
            <div onClick={() => toggleWeaponType('energy')} className={classes['checkbox-holder']}>
              {weaponTypes.find(element => element === 'energy') ?
                <CheckboxCheckedIcon className={classes['checkbox-icon']} />
              :
                <CheckboxUnCheckedIcon className={classes['checkbox-icon']} />
              }
              <div className={classes['checkbox-message']}>Energy</div>              
            </div>
          </div>
        </div>
                        
        {showOwnership ? 
          <div className={classes['details-column']}>
            <div className={classes['detail-title']}>Ownership:</div>
            <div className={classes['detail-value']}>
              <div onClick={() => toggleOwned('yes')} className={classes['checkbox-holder']}>
                {ownedArray.find(element => element === 'yes') ?
                  <CheckboxCheckedIcon className={classes['checkbox-icon']} />
                :
                  <CheckboxUnCheckedIcon className={classes['checkbox-icon']} />
                }
                <div className={classes['checkbox-message']}>Show Owned</div>              
              </div>
              <div onClick={() => toggleOwned('no')} className={classes['checkbox-holder']}>
                {ownedArray.find(element => element === 'no') ?
                  <CheckboxCheckedIcon className={classes['checkbox-icon']} />
                :
                  <CheckboxUnCheckedIcon className={classes['checkbox-icon']} />
                }
                <div className={classes['checkbox-message']}>Show Not Owned</div>              
              </div>            
            </div>
          </div>
        : null }
      </fieldset>
    </div>        
  );
};

export default CardFilter;