import {
  GET_GAME_DATA_SUCCESS,
  GET_GAME_DATA_FAILURE,
  VIEW_CARD_DETAILS,
  CLEAR_CARD_DETAILS,
  SET_GENERAL_ERROR,
  SET_GENERAL_BUSY_MESSAGE,
  SHOW_BATTLE_CONFIRMATION,
  HIDE_BATTLE_CONFIRMATION,
  SET_WAX_ERROR,
  UPDATE_MOBILE_MODE,
  SET_SIGNING_TRANSACTION,
  SET_BUSY_AUTO_LOGIN,
  CLEAR_GAME_ERROR,
  SET_GAME_MODE,
  SHOW_BUYIN_CONFIRMATION,
  HIDE_BUYIN_CONFIRMATION
} from "../actions/actionTypes";

import { GameState, GameActions } from "../types/gameTypes";

const initialState: GameState = {
  game: null,
  error: "",
  fatalError: "",
  cardDetails: null,
  generalError: "",
  generalBusyMessage: "",
  signingTransaction: "",
  showBattleConfirmationPopup: false,
  heroes: [],
  heroRarities: [],
  villains: [],
  villainRarities: [],  
  rematch: false,
  waxErrorMessage: "",
  mobileMode: false,
  buildVersion: "0.8",
  busyAutoLogin: false,
  gameMode: "seasonal",
  practice: false,
  showBuyinConfirmationPage: false
};

export default (state = initialState, action: GameActions) => {
  switch (action.type) {
    case GET_GAME_DATA_SUCCESS:
      return {
        ...state,
        game: action.payload.gameDetails
      };
    case GET_GAME_DATA_FAILURE:
      return {
        ...state,        
        error: action.payload.error,
        fatalError: action.payload.fatalError,
      };
    case VIEW_CARD_DETAILS:
      return {
        ...state,
        cardDetails: action.payload
      };
    case CLEAR_CARD_DETAILS:
      return {
        ...state,
        cardDetails: null
      };
    case SHOW_BATTLE_CONFIRMATION:
      return {
        ...state,
        showBattleConfirmationPopup: true,
        heroes: action.heroes,
        heroRarities: action.heroRarities,
        villains: action.villains,
        villainRarities: action.villainRarities,
        rematch: action.rematch,        
        practice: action.practice
      };
    case HIDE_BATTLE_CONFIRMATION:
      return {
        ...state,
        showBattleConfirmationPopup: false
      };
    case SHOW_BUYIN_CONFIRMATION:
      return {
        ...state,
        showBuyinConfirmationPage: true,
        heroes: action.heroes,
        heroRarities: action.heroRarities,
        villains: action.villains,
        villainRarities: action.villainRarities,
      };
    case HIDE_BUYIN_CONFIRMATION:
      return {
        ...state,
        showBuyinConfirmationPage: false
      };   
    case SET_GENERAL_ERROR:
      return {
        ...state,
        generalError: action.payload
      };
    case SET_GENERAL_BUSY_MESSAGE:
      return {
        ...state,
        generalBusyMessage: action.payload
      };  
    case SET_SIGNING_TRANSACTION:
      return {
        ...state,
        signingTransaction: action.payload
      }; 
    case UPDATE_MOBILE_MODE:
      return {
        ...state,
        mobileMode: action.payload
      };
    case SET_BUSY_AUTO_LOGIN:
      return {
        ...state,
        busyAutoLogin: action.payload
      };
    case SET_WAX_ERROR:
      return {
        ...state,
        waxErrorMessage: action.payload
      };    
    case CLEAR_GAME_ERROR:
      return {
        ...state,
        error: ""
      };  
    case SET_GAME_MODE:
      return {
        ...state,
        gameMode: action.payload
      };        
    default:
      return {
        ...state,
      };
  }
};