import classes from './BattleLogStep.module.css';
import { IBattleStep, ICard } from '../../store/types/playerTypes';
import armorImage from '../../assets/images/general/icons/armor.png';
import shieldsImage from '../../assets/images/general/icons/shields.png';
import {ReactComponent as CrossIcon} from '../../assets/svg/clear.svg';
import { rarityToIndex } from '../../hoc/CommonFunctions';


interface BattleLogStepProps {
  step: IBattleStep,
  deathsOnly: boolean
}

const BattleLogStep = ( {step, deathsOnly}:BattleLogStepProps ) => {  
  let leftCard:ICard | null;
  let rightCard:ICard | null;
  if (step.attackingCard.isPlayerCard) {
    leftCard = step.attackingCard;
    rightCard = step.defendingCard;  

    // Clear the idling flag on the defender
    rightCard.cardIdled = false;
  } else {
    leftCard = step.defendingCard;
    rightCard = step.attackingCard;

    // Clear the idling flag on the defender
    leftCard.cardIdled = false;
  }

  

  // If left idled, right ship should be blank, and vice versa
  if (leftCard.cardIdled) {
    rightCard = null;
  } else if (rightCard.cardIdled) {
    leftCard = null;
  } else if (leftCard.slotChanged) {
    rightCard = null;
  } else if (rightCard.slotChanged) {
    leftCard = null;
  } else {
    // If it was friendly targeting, show the target instead
    if (leftCard.isPlayerCard === rightCard.isPlayerCard) {
      if (leftCard.isPlayerCard) {
        // It is the players turn, so show the defender on the left, and nothing right
        leftCard = step.defendingCard;
        rightCard = null;
      } else {
        // It is the opponent turn, show defender on right + nothing left
        rightCard = step.defendingCard;
        leftCard = null;
      }
    }
  }
    
  let leftImgStyle = {};
  let leftImage = "";  
  if (leftCard && leftCard.id > 0) {
    // Scale things up a bit
    let scale:number = 1;
    
    leftImgStyle = {
      "max-height": (scale*100) + '%'
    };

    leftImage = leftCard.rarity_stats[rarityToIndex(leftCard.rarity)].image; 

    if (leftCard.currentHealth === 0) {
      leftImgStyle = {
        "max-height": (scale*100) + '%',
        "filter": "grayscale(100%)"
      };
    }
  }

  let rightImgStyle = {};
  let rightImage = "";  
  if (rightCard && rightCard.id > 0) {
    // Scale things up a bit
    let scale:number = 1;
    
    rightImgStyle = {
      "max-height": (scale*100) + '%'
    };

    rightImage = rightCard.rarity_stats[rarityToIndex(rightCard.rarity)].image;   
    
    if (rightCard.currentHealth === 0) {
      rightImgStyle = {
        "max-height": (scale*100) + '%',
        "filter": "grayscale(100%)" 
      };
    }
  }

  

  return (
    <div className={classes['battle-step']}>
      {leftCard  ?
        <div className={classes['battle-side']}>
          <div className={[classes['card-holder'],classes['card-holder-'+leftCard.rarity]].join(' ')}>                
            <div  className={classes['middle-row']}>
              <img alt="Card" style={leftImgStyle} src={leftImage}></img>
            </div>
            {leftCard.currentHealth === 0 ? 
              <div className={classes['cross-holder']}>          
                <CrossIcon className={classes['cross-icon']} />          
              </div>
              : null
            }
          </div> 
          <div className={classes['side-row']}>
            <div className={classes['stat-row']}>
              <img alt="Shield" className={classes['stat-image']} src={shieldsImage} />
              <div className={classes['stat-base-text']}>{leftCard.currentBarrier}</div>  
            </div>  
            <div className={classes['stat-row']}>
              <img alt="Armor" className={classes['stat-image']} src={armorImage} />
              <div className={classes['stat-base-text']}>{leftCard.currentHealth}</div>  
            </div> 
          </div>  
        </div>
      : 
        <div  className={classes['empty-block']}>&nbsp;</div> 
      }
      <div className={classes['battle-description']}>{step.description}</div>
      {rightCard  ?
        <div className={classes['battle-side']}>
          <div className={classes['side-row']}>
            <div className={classes['stat-row']}>
              <img alt="Shields" className={classes['stat-image']} src={shieldsImage} />
              <div className={classes['stat-base-text']}>{rightCard.currentBarrier}</div>  
            </div>  
            <div className={classes['stat-row']}>
              <img alt="Armor" className={classes['stat-image']} src={armorImage} />
              <div className={classes['stat-base-text']}>{rightCard.currentHealth}</div>  
            </div>                       
          </div>
          <div className={[classes['card-holder'],classes['card-holder-'+rightCard.rarity]].join(' ')}>                 
            <div  className={classes['middle-row']}>
              <img alt="Card" style={rightImgStyle} src={rightImage}></img>
            </div>      
            {rightCard.currentHealth === 0 ? 
              <div className={classes['cross-holder']}>          
                <CrossIcon className={classes['cross-icon']} />          
              </div>
              : null
            }                      
          </div>             
        </div> 
      : 
        <div  className={classes['empty-block']}>&nbsp;</div>       
      }
    </div>
  );
};

export default BattleLogStep;
