import { useSelector } from "react-redux";
import classes from './CardStats.module.css';

import { getGameStateSelector } from "../../store/selectors/gameSelectors";
import { GameState } from "../../store/types/gameTypes";
import { getPlayerStateSelector } from "../../store/selectors/playerSelectors";
import LeftNav from "../General/LeftNav/LeftNav";
import { ICard, PlayerState } from "../../store/types/playerTypes";
import { useEffect, useState } from "react";
import CardFilter from "../General/CardFilter/CardFilter";
import CardStatsDetail from "./CardStatsDetail/CardStatsDetail";


const CardStats = () => {
  const gameState:GameState = useSelector(getGameStateSelector);
  const playerState:PlayerState = useSelector(getPlayerStateSelector);

  const [mergedCardList, setMergedCardList] = useState<ICard[] | []>([]);
  
  const [factions, setFactions] = useState<string[] | []>(['hero','villain']);
  const [ownedArray, setOwnedArray] = useState<string[] | []>(['yes','no']);
  const [weaponTypes, setWeaponTypes] = useState<string[] | []>(['physical','energy']);

  // Merge together list of cards + the ones I own
  useEffect(() => {   
    let localCards:ICard[] = [];
    if (gameState.game?.cardDefinitions && playerState.player?.cardPool) {
      for (let i:number = 0; i < gameState.game?.cardDefinitions.length; i++) {
        let card:ICard = gameState.game?.cardDefinitions[i];
        
        // Do we own any of these cards?       
        let bOwned:boolean = false;
        for (let k:number = 0; k < playerState.player?.cardPool.length; k++) {
          if (card.id === playerState.player?.cardPool[k].id) {            
            bOwned = true;
          }
        }
        
        // Does it pass all the filters?               
        let passFaction:any = factions.find(element => element === card.faction);
        let passType:any = weaponTypes.find(element => element === card.weapon_type);
        let passOwned:boolean = false;        
        if ((bOwned && ownedArray.find(element => element === 'yes')) || 
            (!bOwned && ownedArray.find(element => element === 'no'))) {
            
            passOwned = true;
        }

        // Add this card if it passes all the filters
        if (passOwned && passFaction && passType) {
          localCards.push(card);
        }
      }      
    }
    
    setMergedCardList(localCards);

  }, [ gameState.game?.cardDefinitions, playerState.player?.cardPool, ownedArray, factions, weaponTypes ]);

  const clickFilterChange = (factionsSelected:string[], owned:string[], weapons:string[]) => {           
    setOwnedArray(owned);
    setFactions(factionsSelected);
    setWeaponTypes(weapons);
  }

  return (    
    <div className={classes['main-content']}>
      <LeftNav />
      <div className={classes['center-content']}>  
        <div className={classes['stats-title']}>Card Stats</div>  
        <CardFilter setFilterFunction={clickFilterChange} showOwnership={true} />
        <div className={classes['card-holder']}>                  
          {mergedCardList.map( ( card:ICard, index:number)  => (
            <CardStatsDetail key={index} card={card} game={gameState.game} />
          ))}
        </div>
      </div>
    </div>           
  );
};

export default CardStats;