import { useDispatch, useSelector } from "react-redux";
import classes from './MobileMyCards.module.css';

import { getGameStateSelector } from "../../../store/selectors/gameSelectors";
import { GameState } from "../../../store/types/gameTypes";
import { getPlayerStateSelector } from "../../../store/selectors/playerSelectors";
import { ICard, PlayerState } from "../../../store/types/playerTypes";
import MobileTopNav from "../MobileTopNav/MobileTopNav";
import MobileSideDrawer from "../MobileSideDrawer/MobileSideDrawer";
import { useState } from "react";
import { viewCardDetails } from "../../../store/actions/gameActions";
import MobileCardDisplay from "../MobileCardSection/MobileCardDisplay/MobileCardDisplay";


const MobileMyCards = () => {  
  const dispatch = useDispatch();
  
  const gameState:GameState = useSelector(getGameStateSelector);
  const playerState:PlayerState = useSelector(getPlayerStateSelector);

  const [sideDrawerIsVisible, setSideDrawerIsVisible] = useState(false);

  // Show detailed info about a card
  const showInfo = ( card:ICard ) => {    
    dispatch(viewCardDetails(card));
  }

  const sideDrawerClosedHandler = () => {
    setSideDrawerIsVisible(false);
  };

  const sideDrawerToggleHandler = () => {
    setSideDrawerIsVisible(!sideDrawerIsVisible);
  };

  const dummyFunction = () => {

  }
  
  return (    
    <div className={classes['main-content']}>
      <div className={classes['top-nav-content']}>
        <MobileTopNav drawerToggleClicked={sideDrawerToggleHandler} />  
        <MobileSideDrawer
          isOpen={sideDrawerIsVisible}
          closeFunction={sideDrawerClosedHandler}
        />
      </div>
                       
      <div className={classes['fleet-holder']}>          
        <div className={classes['fleet-top']}>                
          <div className={classes['fleet-title']}>My Cards</div>          
        </div>        

        <div className={classes['ship-section-wrapper']}>          
          <div className={classes['player-fleet']}>
            {playerState && playerState.player && playerState.player.cardPool &&
              playerState.player?.cardPool.map( card => (
                <MobileCardDisplay key={card.id} card={card} 
                  initialRarity={card.rarities_owned[0]}
                  missionFunction={dummyFunction}
                  infoFunction={showInfo}
                  currentModule="myfleet"
                  slot={0}            
                  currentHeroes={[]}      
                  currentVillains={[]}                  
                  />
              ))
            }
          </div>     
        </div>    
      </div>                 
    </div>           
  );
};

export default MobileMyCards;