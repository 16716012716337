export const USER_LOGIN = "USER_LOGIN";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAILURE = "USER_LOGIN_FAILURE";
export const USER_RESET_ERROR = "USER_RESET_ERROR";

export const UPDATE_MOBILE_MODE = "UPDATE_MOBILE_MODE";

export const GET_PLAYER_DATA_REQUEST = "GET_PLAYER_DATA_REQUEST";
export const GET_PLAYER_DATA_SUCCESS = "GET_PLAYER_DATA_SUCCESS";
export const GET_PLAYER_DATA_FAILURE = "GET_PLAYER_DATA_FAILURE";

export const SET_GAME_MODE = "SET_GAME_MODE";

export const GET_GAME_DATA_REQUEST = "GET_GAME_DATA_REQUEST";
export const GET_GAME_DATA_SUCCESS = "GET_GAME_DATA_SUCCESS";
export const GET_GAME_DATA_FAILURE = "GET_GAME_DATA_FAILURE";
export const VIEW_CARD_DETAILS = "VIEW_CARD_DETAILS";
export const CLEAR_CARD_DETAILS = "CLEAR_CARD_DETAILS";
export const SET_GENERAL_ERROR = "SET_GENERAL_ERROR";
export const SET_GENERAL_BUSY_MESSAGE = "SET_GENERAL_BUSY_MESSAGE";
export const SET_BUSY_AUTO_LOGIN = "SET_BUSY_AUTO_LOGIN";
export const SET_SIGNING_TRANSACTION = "SET_SIGNING_TRANSACTION";
export const SET_WAX_ERROR = "SET_WAX_ERROR";

export const CLEAR_BATTLE = "CLEAR_BATTLE";
export const SHOW_BATTLE_LOG = "SHOW_BATTLE_LOG";
export const HIDE_BATTLE_LOG = "HIDE_BATTLE_LOG";
export const SHOW_BATTLE_RESULTS = "SHOW_BATTLE_RESULTS";
export const HIDE_BATTLE_RESULTS = "HIDE_BATTLE_RESULTS";

export const SHOW_BATTLE_CONFIRMATION = "SHOW_BATTLE_CONFIRMATION";
export const HIDE_BATTLE_CONFIRMATION = "HIDE_BATTLE_CONFIRMATION";

export const UPDATE_PLAYER_DATA_REQUEST = "UPDATE_PLAYER_DATA";
export const UPDATE_PLAYER_DATA_SUCCESS = "UPDATE_PLAYER_DATA_COMPLETE";
export const UPDATE_PLAYER_DATA_FAILURE = "UPDATE_PLAYER_DATA_COMPLETE";

export const CLEAR_GAME_ERROR = "";
export const CLEAR_PLAYER_ERROR = "";
export const CLEAR_WAX_ERROR = "";

export const COMMENCE_BATTLE_FAILURE = "COMMENCE_BATTLE_FAILURE";

export const COMMENCE_PRACTICE_BATTLE = "COMMENCE_PRACTICE_BATTLE";
export const COMMENCE_PRACTICE_SUCCESS = "COMMENCE_PRACTICE_SUCCESS";
export const COMMENCE_PRACTICE_FAILURE = "COMMENCE_PRACTICE_FAILURE";

export const SHOW_BUYIN_CONFIRMATION = "SHOW_BUYIN_CONFIRMATION";
export const HIDE_BUYIN_CONFIRMATION = "HIDE_BUYIN_CONFIRMATION";

export const COMMENCE_BUYIN_PREFLIGHT = "COMMENCE_BUYIN_PREFLIGHT";
export const COMMENCE_BUYIN_PREFLIGHT_SUCCESS = "COMMENCE_BUYIN_PREFLIGHT_SUCCESS";
export const COMMENCE_BUYIN_PREFLIGHT_FAILURE = "COMMENCE_BUYIN_PREFLIGHT_FAILURE";

export const COMMENCE_BUYIN = "COMMENCE_BUYIN";
export const COMMENCE_BUYIN_SUCCESS = "COMMENCE_BUYIN_SUCCESS";
export const COMMENCE_BUYIN_FAILURE = "COMMENCE_BUYIN_FAILURE";

export const COMMENCE_TOURNAMENT_BATTLE = "COMMENCE_TOURNAMENT_BATTLE";
export const COMMENCE_TOURNAMENT_SUCCESS = "COMMENCE_TOURNAMENT_SUCCESS";
export const COMMENCE_TOURNAMENT_FAILURE = "COMMENCE_TOURNAMENT_FAILURE";

export const CLEAR_ANNOUNCEMENT = "CLEAR_ANNOUNCEMENT";

export const SHOW_SIMULATOR_BATTLE = "SHOW_SIMULATOR_BATTLE";
export const HIDE_SIMULATOR_BATTLE = "HIDE_SIMULATOR_BATTLE";
export const COMMENCE_SIMULATOR_BATTLE = "COMMENCE_SIMULATOR_BATTLE";
export const COMMENCE_SIMULATOR_SUCCESS = "COMMENCE_SIMULATOR_SUCCESS";
export const COMMENCE_SIMULATOR_FAILURE = "COMMENCE_SIMULATOR_FAILURE";

export const PRESET_SAVE = "PRESET_SAVE";
export const PRESET_DELETE = "PRESET_DELETE";
export const PRESET_SUCCESS = "PRESET_SUCCESS";