import Phaser from "phaser";
import { useDispatch, useSelector } from "react-redux";
import classes from './ShowBattle.module.css';

import { getPlayerStateSelector } from "../../store/selectors/playerSelectors";
import { useEffect, useState } from "react";
import Card from "./Card";

import BattleDisplay from "./BattleDisplay";
import { showBattleResults } from "../../store/actions/playerActions";
import { viewCardDetails } from "../../store/actions/gameActions";
import { getGameStateSelector } from "../../store/selectors/gameSelectors";
import { rarityToIndex } from "../../hoc/CommonFunctions";


const ShowBattlePhaser = () => {  
  const dispatch = useDispatch();
  
  const [phaserInitialized, setPhaserInitialized] = useState(false);  
  const playerState = useSelector(getPlayerStateSelector);  
  const gameState = useSelector(getGameStateSelector);  
    
  let game;
  let playerHeroes;
  let enemyVillains;
  let battleWasStarted = false;
  let battleCompleted = false;  
      
  // Instantiate Phaser when the component loads
  useEffect(() => {   
    if (!phaserInitialized && playerState.battleResult) {
      setPhaserInitialized(true);

      // temp code
      if (game) {      
        game.destroy(true, false);
      }
      
      const config = {
        type: Phaser.AUTO,      
        scale: {
          mode: Phaser.Scale.RESIZE,
          parent: 'phaser-root',
          width: '100%',
          height: '100%'
        },
        physics: {
          default: 'arcade',
          arcade: {
            debug: false,
          },
        },
        width: 800,
        height: 600,
        scene: {
          preload: preload,
          create: create,
          update: update
        },
        transparent: true,
        autoResize: true
      };
      
      game = new Phaser.Game(config);

      function preload() {         
        let effectsVolume = 0.1;
        let musicVolume = 0.1;        
   
        // Preload the cards
        for (let i = 0; i < playerState.battleResult.playerHeroes.length; i++) {           
          this.load.image("playerHero" + playerState.battleResult.playerHeroes[i].id, playerState.battleResult.playerHeroes[i].rarity_stats[rarityToIndex(playerState.battleResult.playerHeroes[i].rarity)].image);                    

          // Load the music for this hero too
          this.load.audio('hero_'+(i+1), ['https://cdn.cosmicclash.io/bchblitz/sounds/hero_'+playerState.battleResult.playerHeroes[i].id.toString().padStart(2,'0')+'.mp3',
          'https://cdn.cosmicclash.io/bchblitz/sounds/hero_'+playerState.battleResult.playerHeroes[i].id.toString().padStart(2,'0')+'.ogg'], {volume: effectsVolume});
        }
        for (let i = 0; i < playerState.battleResult.enemyVillains.length; i++) {                    
          this.load.image("enemyVillain" + playerState.battleResult.enemyVillains[i].id, playerState.battleResult.enemyVillains[i].rarity_stats[rarityToIndex(playerState.battleResult.enemyVillains[i].rarity)].image); 
        }
          
        // Load the stat displays
        this.load.multiatlas(
          'card_display_common', 
          'https://cdn.cosmicclash.io/bchblitz/general/card_display_common.json', 
          'https://cdn.cosmicclash.io/bchblitz/general/'); 
        this.load.multiatlas(
          'card_display_uncommon', 
          'https://cdn.cosmicclash.io/bchblitz/general/card_display_uncommon.json', 
          'https://cdn.cosmicclash.io/bchblitz/general/');
        this.load.multiatlas(
          'card_display_scarce', 
          'https://cdn.cosmicclash.io/bchblitz/general/card_display_scarce.json', 
          'https://cdn.cosmicclash.io/bchblitz/general/');
        this.load.multiatlas(
          'card_display_rare', 
          'https://cdn.cosmicclash.io/bchblitz/general/card_display_rare.json', 
          'https://cdn.cosmicclash.io/bchblitz/general/');
        this.load.multiatlas(
          'card_display_epic', 
          'https://cdn.cosmicclash.io/bchblitz/general/card_display_epic.json', 
          'https://cdn.cosmicclash.io/bchblitz/general/');
        this.load.multiatlas(
          'card_display_legendary', 
          'https://cdn.cosmicclash.io/bchblitz/general/card_display_legendary.json', 
          'https://cdn.cosmicclash.io/bchblitz/general/');
        this.load.multiatlas(
          'card_display_mythic', 
          'https://cdn.cosmicclash.io/bchblitz/general/card_display_mythic.json', 
          'https://cdn.cosmicclash.io/bchblitz/general/');
        this.load.multiatlas(
          'card_display_secret', 
          'https://cdn.cosmicclash.io/bchblitz/general/card_display_secret.json', 
          'https://cdn.cosmicclash.io/bchblitz/general/');

                          
        // Load the weapons + explosions
        this.load.multiatlas(
          'general_battle_animations', 
          'https://cdn.cosmicclash.io/general/battle_general_animations_v7.json', 
          'https://cdn.cosmicclash.io/general/');  
        
        // Load the win / lose animations
        this.load.multiatlas(
          'win_lose_animations', 
          'https://cdn.cosmicclash.io/general/win_lose_animations.json', 
          'https://cdn.cosmicclash.io/general/'); 

        // Load the battle controls
        this.load.multiatlas(
          'battle_controls', 
          'https://cdn.cosmicclash.io/general/battle_controls_v3.json', 
          'https://cdn.cosmicclash.io/general/');  
        
        // Various BCH stuff         
        // Load battle frames
        this.load.multiatlas(
          'bch_battle_animations', 
          'https://cdn.cosmicclash.io/bchblitz/general/frames-v3.json', 
          'https://cdn.cosmicclash.io/bchblitz/general/');  

        // Slash animation
        this.load.multiatlas(
          'bch_slash', 
          'https://cdn.cosmicclash.io/bchblitz/general/slash.json', 
          'https://cdn.cosmicclash.io/bchblitz/general/');  
        
        // Load in all our sounds
        
        // Energy attack
        this.load.audio('attack_energy', ['https://cdn.cosmicclash.io/audio/dark_laser.mp3', 'https://cdn.cosmicclash.io/audio/dark_laser.ogg'], {volume: effectsVolume});        
        
        // Melee attack
        this.load.audio('attack_melee', ['https://cdn.cosmicclash.io/bchblitz/sounds/punch.mp3', 'https://cdn.cosmicclash.io/bchblitz/sounds/punch.ogg'], {volume: effectsVolume});

        // Barrier hit
        this.load.audio('barrier', ['https://cdn.cosmicclash.io/audio/sci-fi-force-field-impact-13.mp3', 'https://cdn.cosmicclash.io/audio/sci-fi-force-field-impact-13.ogg'], {volume: effectsVolume});        

        // Death sounds
        this.load.audio('death_robot', ['https://cdn.cosmicclash.io/audio/explosion-space-01.mp3', 'https://cdn.cosmicclash.io/audio/explosion-space-01.ogg'], {volume: effectsVolume});
        this.load.audio('death_female', ['https://cdn.cosmicclash.io/bchblitz/sounds/death_female.mp3', 'https://cdn.cosmicclash.io/bchblitz/sounds/death_female.ogg'], {volume: effectsVolume});
        this.load.audio('death_male', ['https://cdn.cosmicclash.io/bchblitz/sounds/death_male.mp3', 'https://cdn.cosmicclash.io/bchblitz/sounds/death_male.ogg'], {volume: effectsVolume});
        this.load.audio('death_monster', ['https://cdn.cosmicclash.io/bchblitz/sounds/death_monster.mp3', 'https://cdn.cosmicclash.io/bchblitz/sounds/death_monster.ogg'], {volume: effectsVolume});

        // Basics
        this.load.audio('win', ['https://cdn.cosmicclash.io/audio/035929054-short-brass-winner-fanfare-000.mp3', 'https://cdn.cosmicclash.io/audio/035929054-short-brass-winner-fanfare-000.ogg'], {volume: effectsVolume});
        this.load.audio('lose', ['https://cdn.cosmicclash.io/audio/042686236-game-over-7-horns.mp3', 'https://cdn.cosmicclash.io/audio/042686236-game-over-7-horns.ogg'], {volume: effectsVolume});
        this.load.audio('music', ['https://cdn.cosmicclash.io/bchblitz/sounds/theme.mp3', 'https://cdn.cosmicclash.io/bchblitz/sounds/theme.ogg'], {loop:true, volume: musicVolume});
        this.load.audio('superpower', ['https://cdn.cosmicclash.io/bchblitz/sounds/power_sound.mp3', 'https://cdn.cosmicclash.io/bchblitz/sounds/power_sound.ogg'], {volume: effectsVolume});

        // Avatars        
        if (playerState.player && playerState.player.avatarId && playerState.player.avatarId > 0) {
          this.load.image('playerAvatar', 'https://cdn.cosmicclash.io/avatars/avatar_' + playerState.player.avatarId + '.png');
        }
        if (playerState.battleResult.enemyAvatarId && playerState.battleResult.enemyAvatarId > 0) {
          this.load.image('opponentAvatar', 'https://cdn.cosmicclash.io/avatars/avatar_' + playerState.battleResult.enemyAvatarId + '.png');
        }
      }
            
      function create() {                       
        //console.log("Screen Dims: " + this.game.canvas.clientWidth + " x " + this.game.canvas.clientHeight);
        
        // Show the card across the full width
        this.cardWidth = this.game.canvas.clientWidth / 5;

        //console.log("Card Width: " + this.cardWidth);
        
        // Make sure the card width isn't more than 1/5 of the the screen height        
        if (this.cardWidth > this.game.canvas.clientHeight / 4) {
          this.cardWidth = this.game.canvas.clientHeight / 4;
        }        

        //console.log("Card Width: " + this.cardWidth);

        let cardSpacer = (this.game.canvas.clientWidth - (this.cardWidth * 5)) / 5;        
        //cardSpacer = 0;
        let baseScale = this.cardWidth / 200;
        this.baseScale = baseScale;
        let textSize = Math.floor(16 * baseScale);

        //console.log("baseScale", baseScale);
        //console.log("cardSpacer", cardSpacer);

        let timeScale = 1;

        // Control variables
        this.isFastForward = false;
        this.isPaused = false;
        this.isMuted = false;
        
        // Add player name texts
        let fontSize = Math.floor(baseScale * 80);
        let floatingFontSize = Math.floor(fontSize / 1.5); 
        let superFontSize = Math.floor(fontSize / 1.5); 
        if (fontSize > 48) {
          fontSize = 48;
          floatingFontSize = 32;          
          superFontSize = 32;
        }
        fontSize = fontSize + "px";   
        floatingFontSize = floatingFontSize + "px";
        superFontSize = superFontSize + "px";

        if (gameState.mobileMode) {
          fontSize = "20px";
          floatingFontSize = "16px";
          superFontSize = "16px";
        }

        // Render an opponent avatar
        let avatarSpacer = 0;
        if (playerState.battleResult.enemyAvatarId && playerState.battleResult.enemyAvatarId > 0) {          
          this.opponentAvatarImage = this.add.image(baseScale*10, 0, 'opponentAvatar').setOrigin(0,0);
          this.opponentAvatarImage.scale = baseScale / 10;
          avatarSpacer = baseScale*60;
        }
                        
        this.opponentText = this.add.text(baseScale*10 + avatarSpacer, baseScale*10, playerState.battleResult.enemyName, { fontFamily: 'TitilliumWeb-Bold', fontSize: fontSize, fontWeight: 800 });
        this.opponentText.alpha = 0;
        
        // Render a player avatar?
        avatarSpacer = 0;
        if (playerState.player && playerState.player.avatarId && playerState.player.avatarId > 0) {          
          this.playerAvatarImage = this.add.image(this.game.canvas.clientWidth - baseScale*10, this.game.canvas.clientHeight - this.opponentText.height - (baseScale*20), 'playerAvatar').setOrigin(1,0.5);
          this.playerAvatarImage.scale = baseScale / 10;
          avatarSpacer = baseScale*60;
        }
        
        this.playerText = this.add.text(this.game.canvas.clientWidth - baseScale*10 - avatarSpacer, this.game.canvas.clientHeight - this.opponentText.height, playerState.player.name, 
          { align: 'right', fontFamily: 'TitilliumWeb-Bold', fontSize: fontSize, fontWeight: 800, boundsAlignH: 'right'}).setOrigin(1);              
        this.playerText.alpha = 0;
                
        // Add all the ships
        playerHeroes = [];
        let tempX = 0;
        let shieldSprite;        
        let armorSprite;
        let shieldText;
        let armorText;
        let shieldDisplaySprite;        
        let floatingShieldText;
        let floatingArmorText;
        let attackerFrame;
        let targetFrame;
        let floatingSuperpowerText;
        let slashSprite;
        for (let i = 0; i < playerState.battleResult.playerHeroes.length; i++) {
          // Create a shield + an armor sprite for this ship
          shieldSprite = this.add.sprite(-200, 0, "card_display_" + playerState.battleResult.playerHeroes[i].rarity, 
            "barrier_" + playerState.battleResult.playerHeroes[i].rarity + "_10.png");          
          shieldSprite.scale = baseScale * 0.2;          
          armorSprite = this.add.sprite(-200, 0, "card_display_" + playerState.battleResult.playerHeroes[i].rarity,  
            "health_" + playerState.battleResult.playerHeroes[i].rarity + "_10.png");          
          armorSprite.scale = baseScale * 0.2;        
                  
          // Create texts as well
          shieldText = this.add.text(-200, 0, '000', { fontFamily: 'TitilliumWeb-Bold', fontSize: textSize + 'px' });
          armorText = this.add.text(-200, 0, '000', { fontFamily: 'TitilliumWeb-Bold', fontSize: textSize + 'px' });

          // Create a shield display sprite
          shieldDisplaySprite = this.add.sprite(-200, 0, "shieldFriendly" + playerState.battleResult.playerHeroes[i].id); 
          
          // Add an attacker + targe frame          
          attackerFrame = this.add.sprite(-200, 0, 'bch_battle_animations','attacking.png');  
          attackerFrame.scale = baseScale * 1.1;      
          targetFrame = this.add.sprite(-200, 0, 'bch_battle_animations','target.png');        
          targetFrame.scale = baseScale * 1.1;      

          tempX = (.5 * this.cardWidth) + (i * this.cardWidth) + ((i+1) * cardSpacer);
                              
          let card = new Card(
            {
              scene: this,
              key: "playerHero" + playerState.battleResult.playerHeroes[i].id,
              x: tempX,
              y: this.game.canvas.clientHeight + this.cardWidth,
              frame: 0
            }, 
            this.cardWidth, cardSpacer,
            playerState.battleResult.playerHeroes[i], baseScale, false, 
            this.game.canvas.clientHeight-(this.game.canvas.clientHeight/3.5),
            shieldSprite, armorSprite, shieldText, armorText,shieldDisplaySprite, timeScale, this.game, attackerFrame, targetFrame
          );

          // And a slash sprite
          slashSprite = this.add.sprite(0, 0, "slashFriendly" + playerState.battleResult.playerHeroes[i].id); 
          card.addSlashSprite(slashSprite);

          // Add some floating text for damage
          floatingShieldText = this.add.text(0,0, "", { fontFamily: 'TitilliumWeb-Bold', fontSize: floatingFontSize, fontWeight: 800, strokeThickness: 2 });
          floatingArmorText = this.add.text(0,0, "", { fontFamily: 'TitilliumWeb-Bold', fontSize: floatingFontSize, fontWeight: 800, strokeThickness: 2 });
          floatingSuperpowerText = this.add.text(0,0, "", { fontFamily: 'TitilliumWeb-Bold', fontSize: superFontSize, fontWeight: 800, strokeThickness: 2 }).setOrigin(0.5,0.5);
          floatingSuperpowerText.stroke = '#000000';
          floatingSuperpowerText.strokeThickness = 8;
          
          card.addFloatingTexts(floatingShieldText, floatingArmorText, floatingSuperpowerText);

          // Let it be clicked to show info
          card.setInteractive({ useHandCursor: true })
          card.on('pointerdown', () => showCardInfo(playerState.battleResult.playerHeroes[i]));

          playerHeroes.push(card);
        }

        enemyVillains = [];
        for (let i = 0; i < playerState.battleResult.enemyVillains.length; i++) {
          // Create a shield + an armor sprite for this ship
          shieldSprite = this.add.sprite(-200, 0, "card_display_" + playerState.battleResult.enemyVillains[i].rarity, 
            "barrier_" + playerState.battleResult.enemyVillains[i].rarity + "_10.png");          
          shieldSprite.scale = baseScale * 0.2;          
          armorSprite = this.add.sprite(-200, 0, "card_display_" + playerState.battleResult.enemyVillains[i].rarity,  
            "health_" + playerState.battleResult.enemyVillains[i].rarity + "_10.png");          
          armorSprite.scale = baseScale * 0.2;   

          // Create texts as well
          shieldText = this.add.text(-200, 0, '000', { fontFamily: 'TitilliumWeb-Bold', fontSize: textSize + 'px' });
          armorText = this.add.text(-200, 0, '000', { fontFamily: 'TitilliumWeb-Bold', fontSize: textSize + 'px' });
          
          // Create a shield display sprite
          shieldDisplaySprite = this.add.sprite(-200, 0, "shieldOpponent" + playerState.battleResult.enemyVillains[i].id);  
          
          // Add an attacker + target frame          
          attackerFrame = this.add.sprite(-200, 0, 'bch_battle_animations','attacking.png');      
          attackerFrame.scale = baseScale * 1.1;  
          targetFrame = this.add.sprite(-200, 0, 'bch_battle_animations','target.png');     
          targetFrame.scale = baseScale * 1.1;  
                    
          tempX = (.5 * this.cardWidth) + (i * this.cardWidth) + ((i+1) * cardSpacer);
          
          let card = new Card(
            {
              scene: this,
              key: "enemyVillain" + playerState.battleResult.enemyVillains[i].id,
              x: tempX,
              y: 0 - this.cardWidth,
              frame: 0
            }, 
            this.cardWidth, cardSpacer,
            playerState.battleResult.enemyVillains[i], baseScale, true, this.game.canvas.clientHeight/3.5,
            shieldSprite, armorSprite, shieldText, armorText, shieldDisplaySprite, timeScale, this.game, attackerFrame, targetFrame
          );

          // And a slash sprite
          slashSprite = this.add.sprite(0, 0, "slashOpponent" + playerState.battleResult.enemyVillains[i].id); 
          card.addSlashSprite(slashSprite);
        
          // Add some floating text for damage
          floatingShieldText = this.add.text(0,0, "", { fontFamily: 'TitilliumWeb-Bold', fontSize: floatingFontSize, fontWeight: 800, strokeThickness: 2 });
          floatingArmorText = this.add.text(0,0, "", { fontFamily: 'TitilliumWeb-Bold', fontSize: floatingFontSize, fontWeight: 800, strokeThickness: 2 });
          floatingSuperpowerText = this.add.text(0,0, "", { fontFamily: 'TitilliumWeb-Bold', fontSize: superFontSize, fontWeight: 800, strokeThickness: 2 }).setOrigin(0.5,0.5);          
          floatingSuperpowerText.stroke = '#000000';
          floatingSuperpowerText.strokeThickness = 8;
          card.addFloatingTexts(floatingShieldText, floatingArmorText, floatingSuperpowerText);
          
          // Let it be clicked to show info
          card.setInteractive({ useHandCursor: true })
          card.on('pointerdown', () => showCardInfo(playerState.battleResult.enemyVillains[i]));

          enemyVillains.push(card);
        }

        // Move cards to their proper slot
        for (let i = 0; i < playerHeroes.length; i++) {          
          playerHeroes[i].initSlot();   
        }
        for (let i = 0; i < enemyVillains.length; i++) {          
          enemyVillains[i].initSlot();  
        }

        // Build the animations we need        
        this.anims.create({
          key: 'animationLaser',
          frames: this.anims.generateFrameNames("general_battle_animations", {
            start: 1, end: 12, zeroPad: 2,
            prefix: 'laser_bolt_', suffix: '.png'
          }),
          frameRate: 10,
          repeat: -1
        });        
        this.anims.create({
          key: 'animationExplosionLarge',
          frames: this.anims.generateFrameNames("general_battle_animations", {
            start: 1, end: 10, zeroPad: 2,
            prefix: 'explosion_large_', suffix: '.png'
          }),
          frameRate: 10,
          repeat: 0
        });         
        this.anims.create({
          key: 'animationShieldFadeIn',
          frames: this.anims.generateFrameNames("general_battle_animations", {
            start: 1, end: 15, zeroPad: 2,
            prefix: 'shield_fade_in_', suffix: '.png'
          }),
          frameRate: 30,
          repeat: 0
        });
        this.anims.create({
          key: 'animationShieldFadeOut',
          frames: this.anims.generateFrameNames("general_battle_animations", {
            start: 1, end: 15, zeroPad: 2,
            prefix: 'shield_fade_out_', suffix: '.png'
          }),
          frameRate: 30,
          repeat: 0
        });       
        this.anims.create({
          key: 'animationWin',
          frames: this.anims.generateFrameNames("win_lose_animations", {
            start: 1, end: 23, zeroPad: 2,
            prefix: 'win_', suffix: '.png'
          }),
          frameRate: 10,
          repeat: 0
        });
        this.anims.create({
          key: 'animationLose',
          frames: this.anims.generateFrameNames("win_lose_animations", {
            start: 1, end: 23, zeroPad: 2,
            prefix: 'lose_', suffix: '.png'
          }),
          frameRate: 10,
          repeat: 0
        });
        this.anims.create({
          key: 'superFrame',
          frames: this.anims.generateFrameNames("bch_battle_animations", {
            start: 1, end: 10, zeroPad: 2,
            prefix: 'super_', suffix: '.png'
          }),
          frameRate: 30,
          repeat: -1
        });
        this.anims.create({
          key: 'animationSlash',
          frames: this.anims.generateFrameNames("bch_slash", {
            start: 1, end: 6, zeroPad: 2,
            prefix: 'slash_', suffix: '.png'
          }),
          frameRate: 10,
          repeat: 0
        });
               
        // Set some state variables
        this.allFadedIn = false;
        this.startCards = false;
        this.bReady = false;
        this.fadeInCards = true;

        // Initialize the battle displayer        
        this.battleDisplay = new BattleDisplay(this, this.game, playerHeroes, enemyVillains, 
          playerState.battleResult, baseScale, timeScale, () => battleComplete());
              
        // Add a start button
        this.startButton = this.add.sprite(this.cameras.main.centerX, this.cameras.main.centerY, 'battle_controls','button_start_battle_default.png')        
          .setInteractive({ useHandCursor: true })
          .on('pointerdown', () => clickStart(this))
          .on('pointerover', () => this.startButton.setFrame('button_start_battle_hover.png'))
          .on('pointerout', () => this.startButton.setFrame('button_start_battle_default.png'));
        this.startButton.scale = baseScale / 2; 
        this.startButton.alpha = 0;
                                
        // Tween it all in
        this.tweens.add(
          {
            targets: [this.opponentText,this.playerText,this.startButton],
            alpha: 1,				
            ease: 'linear',
            duration: 1000, 
            delay: 0  
          }
        );   

        // Start playing the first music clip
        this.currentMusicClip = 1;
        this.bgMusic1 = this.sound.add('hero_1');
        this.bgMusic1.on('complete', () => musicComplete(this));                
        this.bgMusic2 = this.sound.add('hero_2');
        this.bgMusic2.on('complete', () => musicComplete(this));                
        this.bgMusic3 = this.sound.add('hero_3');
        this.bgMusic3.on('complete', () => musicComplete(this));                
        this.bgMusic4 = this.sound.add('hero_4');
        this.bgMusic4.on('complete', () => musicComplete(this));                
        this.bgMusic5 = this.sound.add('hero_5');
        this.bgMusic5.on('complete', () => musicComplete(this));                        
      }    
                 
      function update() {       
        // Wait for the start click
        if (this.startCards) {                  
          // Fade in all our cards
          if (this.fadeInCards) {
            this.fadeInCards = false;
          
            for (let i = 0; i < playerHeroes.length; i++) {                      
              this.tweens.add(
                {
                  targets: [playerHeroes[i]],
                  alpha: 1,				
                  ease: 'linear',
                  duration: 1000, 
                  delay: 0,
                  onComplete: () => cardsFadedIn(this)
                }
              );  
            }
            for (let i = 0; i < enemyVillains.length; i++) {          
              this.tweens.add(
                {
                  targets: [enemyVillains[i]],
                  alpha: 1,				
                  ease: 'linear',
                  duration: 1000, 
                  delay: 0
                }
              );  
            }
          }

          if (this.bReady && this.allFadedIn === false) {            
            this.allFadedIn = true;
            this.battleDisplay.battleStarted = true;

            // Show the stats displays on all cards
            for (let i = 0; i < playerHeroes.length; i++) {          
              playerHeroes[i].showStatsDisplays();
            }
            for (let i = 0; i < enemyVillains.length; i++) {          
              enemyVillains[i].showStatsDisplays();
            }            
          }

          // Call the update routine on the battle display
          if (!this.isPaused) {
            this.battleDisplay.update();
          }
        }       
      }     
    }
    
  }, [ playerState.battleResult ]);

  function cardsFadedIn (phaserRef) {    
    phaserRef.bReady = true;
  }

  function clickStart (phaserRef) {          
    if (phaserRef.startCards === false) {   
      // Start the ships
      phaserRef.startCards = true;  
      startTheCards();
     
      // Start the music
      try {
        
        phaserRef.bgMusic1.play();
        //phaserRef.game.sound.play("music");  
      } catch (e) {}
          
      // Hide start button 
      phaserRef.tweens.add(
        {
          targets: [phaserRef.startButton],
          alpha: 0,				
          ease: 'linear',
          duration: 1000, 
          delay: 0,
          onComplete: () => destroyStart(phaserRef)
        }
      );  

      // Placement variables
      let nextX = phaserRef.baseScale*37;
      let nextY = (phaserRef.game.canvas.clientHeight - (phaserRef.baseScale*37));

      // Create a control panel rectangle
      phaserRef.controlPanel = phaserRef.add.rectangle(0, nextY, phaserRef.baseScale*525, phaserRef.baseScale*75,0x000000, 0.6);
      
      // Add a pause/play button
      phaserRef.pauseButton = phaserRef.add.sprite(nextX, nextY, 'battle_controls','control_panel_pause.png')        
        .setInteractive({ useHandCursor: true })
        .on('pointerdown', () => clickPause(phaserRef))
        .on('pointerover', () => phaserRef.pauseButton.setFrame('control_panel_pause_hover.png'))
        .on('pointerout', () => phaserRef.pauseButton.setFrame('control_panel_pause.png'));    
      phaserRef.pauseButton.scale = phaserRef.baseScale/2; 
      phaserRef.pauseButton.alpha = 0;
    
      // Add a fast forward button
      nextX = nextX + phaserRef.baseScale*62;
      phaserRef.ffButton = phaserRef.add.sprite(nextX, nextY, 'battle_controls','control_panel_forward.png')        
        .setInteractive({ useHandCursor: true })
        .on('pointerdown', () => clickFastForward(phaserRef))
        .on('pointerover', () => phaserRef.ffButton.setFrame('control_panel_forward_hover.png'))
        .on('pointerout', () => phaserRef.ffButton.setFrame('control_panel_forward.png'));     
      phaserRef.ffButton.scale = phaserRef.baseScale/2; 
      phaserRef.ffButton.alpha = 0;

      // Add a skip button
      nextX = nextX + phaserRef.baseScale*62;
      phaserRef.skipButton = phaserRef.add.sprite(nextX, nextY, 'battle_controls','control_panel_skip.png')        
        .setInteractive({ useHandCursor: true })
        .on('pointerdown', () => clickSkip(phaserRef))
        .on('pointerover', () => phaserRef.skipButton.setFrame('control_panel_skip_hover.png'))
        .on('pointerout', () => phaserRef.skipButton.setFrame('control_panel_skip.png'));  
      phaserRef.skipButton.scale = phaserRef.baseScale/2; 
      phaserRef.skipButton.alpha = 0;
          
      // Add a mute button
      nextX = nextX + phaserRef.baseScale*62;
      phaserRef.muteButton = phaserRef.add.sprite(nextX, nextY, 'battle_controls','control_panel_mute.png')        
        .setInteractive({ useHandCursor: true })
        .on('pointerdown', () => clickMute(phaserRef))
        .on('pointerover', () => phaserRef.muteButton.setFrame('control_panel_mute_hover.png'))
        .on('pointerout', () => phaserRef.muteButton.setFrame('control_panel_mute.png'));      
      phaserRef.muteButton.scale = phaserRef.baseScale/2; 
      phaserRef.muteButton.alpha = 0;

      // Set mute if we were previously muted
      let startMuted = window.localStorage.getItem('muteSimulation');
      if (startMuted && startMuted === 'true') {
        phaserRef.isMuted = true;
        phaserRef.game.sound.mute = true;
        phaserRef.muteButton.setFrame('control_panel_sound.png');
        phaserRef.muteButton.on('pointerover', () => phaserRef.muteButton.setFrame('control_panel_sound_hover.png'))
        phaserRef.muteButton.on('pointerout', () => phaserRef.muteButton.setFrame('control_panel_sound.png')); 
      }    
        
      // Tween all buttons in
      phaserRef.tweens.add(
        {
          targets: [phaserRef.pauseButton,phaserRef.ffButton,phaserRef.skipButton,phaserRef.muteButton],
          alpha: 1,				
          ease: 'linear',
          duration: 1000, 
          delay: 0  
        }
      );
    }      
  }

  function clickFastForward (phaserRef) {      
    if (phaserRef.isFastForward) {
      phaserRef.isFastForward = false;
      phaserRef.ffButton.setFrame('control_panel_forward.png');
      phaserRef.ffButton.on('pointerover', () => phaserRef.ffButton.setFrame('control_panel_forward_hover.png'))
      phaserRef.ffButton.on('pointerout', () => phaserRef.ffButton.setFrame('control_panel_forward.png')); 
    } else {
      phaserRef.isFastForward = true;
      phaserRef.ffButton.setFrame('control_panel_stop.png');
      phaserRef.ffButton.on('pointerover', () => phaserRef.ffButton.setFrame('control_panel_stop_hover.png'))
      phaserRef.ffButton.on('pointerout', () => phaserRef.ffButton.setFrame('control_panel_stop.png')); 
    }    
  }

  function clickSkip (phaserRef) {
    phaserRef.battleDisplay.skipToEnd();
    battleComplete();    
  }

  function clickMute (phaserRef) {
    if (phaserRef.isMuted) {
      phaserRef.isMuted = false;
      phaserRef.game.sound.mute = false;
      phaserRef.muteButton.setFrame('control_panel_mute.png');
      phaserRef.muteButton.on('pointerover', () => phaserRef.muteButton.setFrame('control_panel_mute_hover.png'))
      phaserRef.muteButton.on('pointerout', () => phaserRef.muteButton.setFrame('control_panel_mute.png')); 
      window.localStorage.setItem('muteSimulation', 'false');

    } else {
      phaserRef.isMuted = true;
      phaserRef.game.sound.mute = true;
      phaserRef.muteButton.setFrame('control_panel_sound.png');
      phaserRef.muteButton.on('pointerover', () => phaserRef.muteButton.setFrame('control_panel_sound_hover.png'))
      phaserRef.muteButton.on('pointerout', () => phaserRef.muteButton.setFrame('control_panel_sound.png')); 
      window.localStorage.setItem('muteSimulation', 'true');
    }
  }

  function clickPause (phaserRef) {
    if (phaserRef.isPaused) {
      phaserRef.isPaused = false;
      phaserRef.pauseButton.setFrame('control_panel_pause.png');
      phaserRef.pauseButton.on('pointerover', () => phaserRef.pauseButton.setFrame('control_panel_pause_hover.png'))
      phaserRef.pauseButton.on('pointerout', () => phaserRef.pauseButton.setFrame('control_panel_pause.png'));
    } else {
      phaserRef.isPaused = true;
      phaserRef.pauseButton.setFrame('control_panel_play.png');
      phaserRef.pauseButton.on('pointerover', () => phaserRef.pauseButton.setFrame('control_panel_play_hover.png'))
      phaserRef.pauseButton.on('pointerout', () => phaserRef.pauseButton.setFrame('control_panel_play.png'));
    }    
  }

  function musicComplete (phaserRef) {
    // Tell the next one to play
    phaserRef.currentMusicClip++;
    if (phaserRef.currentMusicClip > 5) {
      phaserRef.currentMusicClip = 1;
    }
    phaserRef['bgMusic'+phaserRef.currentMusicClip].play();    
  }

  function destroyStart(phaserRef) {
    // Kill the start button
    phaserRef.startButton.destroy();
  }

  function startTheCards () {    
    battleWasStarted = true;
  }

  function battleComplete () {
    battleCompleted = true;    
    if (battleWasStarted) {
      setTimeout(() => continueComplete(),5000);    
    } else {
      continueComplete();
    }    
  }

  function showCardInfo(card) { 
    if (battleCompleted === false) {   
      dispatch(viewCardDetails(card));
    }
  }

  function continueComplete() {
    dispatch(showBattleResults());
    
    game.sound.stopAll();
  }

  return (    
    <div className={classes['main-content']}>            
      {playerState.battleResult ? 
        <div className={classes['battle-content']}>          
          <div id="phaser-root"></div>           
        </div>   
      :
        <div className={classes['waiting-content']}>Initiating Battle...</div>
      }
    </div>
  );
};

export default ShowBattlePhaser;