import classes from './MobileBattleRow.module.css';
import { useState } from 'react';

import {ReactComponent as WatchIcon} from '../../../../assets/svg/eye.svg';
import { IPlayerBattleLogEntry } from '../../../../store/types/playerTypes';
import fglToken from '../../../../assets/images/general/fgl-token.png';
import projectileIcon from '../../../../assets/images/general/icons/weapon-projectile.png';
import armorIcon from '../../../../assets/images/general/icons/armor.png';
import shieldsIcon from '../../../../assets/images/general/icons/shields.png';

import {ReactComponent as ArrowUp} from '../../../../assets/svg/arrow-up.svg';
import {ReactComponent as ArrowDown} from '../../../../assets/svg/arrow-down.svg';

import goldIcon from '../../../../assets/images/general/512x512-gold-animated.gif';
import bronzeIcon from '../../../../assets/images/general/512x512 bronze.png';

interface BattleRowProps {
  battleResult:IPlayerBattleLogEntry;   
  rowIndex:number;
}

const MobileBattleRow = ( { battleResult, rowIndex }:BattleRowProps ) => {     
    
    let localBattleDate:Date = new Date(battleResult.date);

    const watchBattle = () => {        
        window.open("https://battles.cosmicclash.io/find-battle/" + battleResult.battleId)
    }
 
    return (
        <>
        <div className={classes['battle-row-landscape']}>     
            <div className={classes['battle-row-inner-top']}>       
                {battleResult.battleType === 0 ?
                    <div className={classes['battle-type']}>W</div>
                : battleResult.battleType === 1 ?
                    <div className={classes['battle-type']}>S</div>
                : battleResult.battleType === 2 ?
                    <img alt="Gold" src={goldIcon} className={classes['token-image']} />                  
                : battleResult.battleType === 3 ?
                    <img alt="Bronze" src={bronzeIcon} className={classes['token-image']} /> 
                : null }
                                                    
                {battleResult.bPaid ?
                    <img alt="FGL Tokens" src={fglToken} className={classes['token-image']} />  
                    :
                    <img alt="FGL Tokens" src={fglToken} className={classes['token-image-bw']} />  
                }   

                {battleResult.powerBuff === 3 ?
                    <img alt="Damage" src={projectileIcon} className={classes['token-image']} />  
                : battleResult.powerBuff === 2 ?
                    <img alt="Shields" src={shieldsIcon} className={classes['token-image']} />  
                : battleResult.powerBuff === 1 ?
                    <img alt="Armor" src={armorIcon} className={classes['token-image']} />  
                : <div className={classes['placeholder']}>&nbsp;</div>
                }   
                
                <div className={classes['battle-date']}>{localBattleDate.toLocaleDateString('en-US')}<br />{localBattleDate.toLocaleTimeString()}</div>            
                
            
                <div className={classes['simple-view']}>
                    <div className={classes['battle-vs']}>vs</div>
                    {battleResult.defenderAvatar > 0 ?
                        <img src={'https://cdn.cosmicclash.io/avatars/avatar_' + battleResult.defenderAvatar + '.png'} className={classes['token-image']} />
                    : null }    
                    <div className={classes['battle-name']}>              
                        {battleResult.defender}                
                    </div>
                    {battleResult.bRematch ?
                        <div className={classes['battle-rematch']}>R</div>
                    : null
                    }
                </div>
            </div>
            <div className={classes['battle-row-inner-bottom']}>                
                <div className={classes['battle-right']}>
                    {battleResult.originalRank - battleResult.newRank < 0 ?
                        <div className={classes['battle-result']}>
                            <ArrowDown className={classes['arrow-down']} />
                            {Math.abs(battleResult.originalRank - battleResult.newRank)}
                        </div>
                    : battleResult.originalRank - battleResult.newRank > 0 ?
                        <div className={classes['battle-result']}>
                            <ArrowUp className={classes['arrow-up']} />
                            {Math.abs(battleResult.originalRank - battleResult.newRank)}
                        </div>
                    :
                        <div className={classes['battle-result']}>No Change</div>
                    }
                    {battleResult.bWin ?
                        <div className={classes['battle-win']}>WIN</div>
                    :
                        <div className={classes['battle-loss']}>LOSS</div>
                    }                                                                     
                </div>    
            </div>                    
        </div>
        <div className={classes['battle-row-portrait']}>     
            <div className={classes['battle-row']}>       
                {battleResult.battleType === 0 ?
                    <div className={classes['battle-type']}>W</div>
                : battleResult.battleType === 1 ?
                    <div className={classes['battle-type']}>S</div>
                : battleResult.battleType === 2 ?
                    <img alt="Gold" src={goldIcon} className={classes['token-image']} />                  
                : battleResult.battleType === 3 ?
                    <img alt="Bronze" src={bronzeIcon} className={classes['token-image']} /> 
                : null }
                                                    
                {battleResult.bPaid ?
                    <img alt="FGL Tokens" src={fglToken} className={classes['token-image']} />  
                    :
                    <img alt="FGL Tokens" src={fglToken} className={classes['token-image-bw']} />  
                }   

                {battleResult.powerBuff === 3 ?
                    <img alt="Damage" src={projectileIcon} className={classes['token-image']} />  
                : battleResult.powerBuff === 2 ?
                    <img alt="Shields" src={shieldsIcon} className={classes['token-image']} />  
                : battleResult.powerBuff === 1 ?
                    <img alt="Armor" src={armorIcon} className={classes['token-image']} />  
                : <div className={classes['placeholder']}>&nbsp;</div>
                }   
                
                <div className={classes['battle-date']}>{localBattleDate.toLocaleDateString('en-US')}&nbsp;{localBattleDate.toLocaleTimeString()}</div>            
            </div> 
            <div className={classes['battle-row']}>    
                <div className={classes['simple-view']}>
                    <div className={classes['battle-vs']}>vs</div>
                    {battleResult.defenderAvatar > 0 ?
                        <img src={'https://cdn.cosmicclash.io/avatars/avatar_' + battleResult.defenderAvatar + '.png'} className={classes['token-image']} />
                    : null }    
                    <div className={classes['battle-name']}>              
                        {battleResult.defender}                
                    </div>
                    {battleResult.bRematch ?
                        <div className={classes['battle-rematch']}>R</div>
                    : null
                    }
                </div>
            </div>
            <div className={classes['battle-row']}>                
                <div className={classes['battle-right']}>
                    {battleResult.originalRank - battleResult.newRank < 0 ?
                        <div className={classes['battle-result']}>
                            <ArrowDown className={classes['arrow-down']} />
                            {Math.abs(battleResult.originalRank - battleResult.newRank)}
                        </div>
                    : battleResult.originalRank - battleResult.newRank > 0 ?
                        <div className={classes['battle-result']}>
                            <ArrowUp className={classes['arrow-up']} />
                            {Math.abs(battleResult.originalRank - battleResult.newRank)}
                        </div>
                    :
                        <div className={classes['battle-result']}>No Change</div>
                    }
                    {battleResult.bWin ?
                        <div className={classes['battle-win']}>WIN</div>
                    :
                        <div className={classes['battle-loss']}>LOSS</div>
                    }                                                                       
                </div>    
            </div>                    
        </div>
    </>
    );
};

export default MobileBattleRow;
