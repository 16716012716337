import { useSelector } from "react-redux";
import LoginForm from '../components/LoginForm/LoginForm';
import Modal from '../components/General/Misc/Modal';

import { getUserSelector } from "../store/selectors/userSelectors";
import ErrorModal from "../components/General/Misc/ErrorModal";
import { WaxState } from "../store/types/waxTypes";
import { getPlayerStateSelector } from "../store/selectors/playerSelectors";
import { GameState } from "../store/types/gameTypes";
import { getGameStateSelector } from "../store/selectors/gameSelectors";
import GeneralErrorModal from "../components/General/Misc/GeneralErrorModal";
import GeneralBusyModal from "../components/General/Misc/GeneralBusyModal";
import FatalErrorModal from "../components/General/Misc/FataErrorModal";
import BattleLog from "../components/BattleLog/BattleLog";
import BattleResults from "../components/BattleResults/BattleResults";
import BattleConfirmation from "../components/BattleConfirmation/BattleConfirmation";
import WaxErrorModal from "../components/General/Misc/WaxErrorModal";
import MobileErrorModal from "../components/Mobile/General/MobileErrorModal";
import MobileGeneralErrorModal from "../components/Mobile/General/MobileGeneralErrorModal";
import MobileWaxErrorModal from "../components/Mobile/General/MobileWaxErrorModal";
import MobileFatalErrorModal from "../components/Mobile/General/MobileFataErrorModal";
import MobileBattleConfirmation from "../components/Mobile/MobileBattleConfirmation/MobileBattleConfirmation";
import MobileBattleResults from "../components/Mobile/MobileBattleResults/MobileBattleResults";
import MobileBattleLog from "../components/Mobile/MobileBattleLog/MobileBattleLog";
import SigningTransactionModal from "../components/General/Misc/SigningTransactionModal";
import BusyAutoLoginModal from "../components/General/Misc/BusyAutoLoginModal";
import Announcement from "../components/General/Announcement/Announcement";
import BuyinConfirmation from "../components/BuyinConfirmation/BuyinConfirmation";
import SimulatorResultsPopup from "../components/Simulator/SimulatorResultsPopup/SimulatorResultsPopup";
import { useLocation } from "react-router-dom";
import MobileSimulatorResultsPopup from "../components/Mobile/MobileSimulator/MobileSimulatorResultsPopup/MobileSimulatorResultsPopup";
import MobileCardDetail from "../components/Mobile/MobileCardDetail/MobileCardDetail";
import CardDetail from "../components/CardDetail/CardDetail";

const Layout = (props:any) => {
  const user:WaxState = useSelector(getUserSelector);
  const playerState:any = useSelector(getPlayerStateSelector);
  const gameState:GameState = useSelector(getGameStateSelector);
  const location = useLocation();

  return (
    <>           
      <Modal show={user.pending} >    
        <GeneralBusyModal message="Logging In..." />        
      </Modal>

      <Modal show={user.error || user.fatalError ? true : false} >
        {gameState.mobileMode ?
          <MobileErrorModal />
        :
          <ErrorModal />
        }
      </Modal>

      <Modal show={((gameState.game && playerState.player) || !user.loggedIn) ? false : true} >        
        <GeneralBusyModal message="Retrieving Data..." />
      </Modal>
      
      <Modal show={gameState.cardDetails ? true : false} >        
        {gameState.mobileMode ?
          <MobileCardDetail />          
        :
          <CardDetail />
        }          
      </Modal>
      
      <Modal show={gameState.generalError !== "" ? true : false} >
        {gameState.mobileMode ?
          <MobileGeneralErrorModal message={gameState.generalError} />
        : 
          <GeneralErrorModal message={gameState.generalError} />
        }
      </Modal>

      <Modal show={gameState.generalBusyMessage !== "" ? true : false} >        
        <GeneralBusyModal message={gameState.generalBusyMessage} />
      </Modal>

      <Modal show={gameState.signingTransaction !== "" ? true : false} >        
        <SigningTransactionModal message={"Signing Transaction..."} />
      </Modal>

      <Modal show={gameState.waxErrorMessage !== "" ? true : false} >
        {gameState.mobileMode ?
          <MobileWaxErrorModal message={gameState.waxErrorMessage} />
        :
          <WaxErrorModal message={gameState.waxErrorMessage} />
        }
      </Modal>

      <Modal show={gameState.error !== "" ? true : false} >
        {gameState.mobileMode ?
          <MobileGeneralErrorModal message={gameState.error} />
        :
          <GeneralErrorModal message={gameState.error} />
        }
      </Modal>

      <Modal show={gameState.fatalError !== "" ? true : false} >
        {gameState.mobileMode ?
          <MobileFatalErrorModal message={gameState.fatalError} />
        :
          <FatalErrorModal message={gameState.fatalError} />
        }
      </Modal>

      <Modal show={playerState.error ? true : false} >
        {gameState.mobileMode ?
          <MobileGeneralErrorModal message={playerState.error} />
        :
          <GeneralErrorModal message={playerState.error} />
        }
      </Modal>

      <Modal show={playerState.fatalError ? true : false} >
        {gameState.mobileMode ?
          <MobileFatalErrorModal message={playerState.fatalError} />
        :
          <FatalErrorModal message={playerState.fatalError} />
        }
      </Modal>
           
      <Modal show={playerState.showBattleResultsPopup ? true : false} >
        {gameState.mobileMode ?
          <MobileBattleResults showResult={playerState.showBattleResultsPopup} 
            battleResult={playerState.battleResult} 
            medalsWon={playerState.medalsWon} 
            />
        :
          <BattleResults showResult={playerState.showBattleResultsPopup} 
            battleResult={playerState.battleResult} 
            medalsWon={playerState.medalsWon} 
            />
        }
      </Modal>

      <Modal show={playerState.showSimulationResults ? true : false} >
        {gameState.mobileMode ?
          <MobileSimulatorResultsPopup 
            wins={playerState.simulatorWins}
            losses={playerState.simulatorLosses}
          />        
        :
          <SimulatorResultsPopup 
            wins={playerState.simulatorWins}
            losses={playerState.simulatorLosses}
            averageRounds={playerState.averageRounds}
            averageSteps={playerState.averageSteps}
          />        
        }
      </Modal>

      <Modal show={playerState.showBattleLog ? true : false} >
        {gameState.mobileMode ?
          <MobileBattleLog />
        :
          <BattleLog />
        }
      </Modal>

      <Modal show={gameState.showBattleConfirmationPopup ? true : false} >
        {gameState.mobileMode ?
          <MobileBattleConfirmation />
        :
          <BattleConfirmation />
        }
      </Modal>

      <Modal show={gameState.showBuyinConfirmationPage ? true : false} >        
        <BuyinConfirmation />        
      </Modal>
     
      <Modal show={gameState.busyAutoLogin} >        
        <BusyAutoLoginModal />
      </Modal>

      <Modal show={playerState.announcement} >        
        <Announcement />
      </Modal>

      {user.loggedIn ?                                 
        <main>          
          {props.children}
        </main>
      :         
        <LoginForm />        
      }
    </>    
  );
};

export default Layout;
