import { useDispatch, useSelector } from 'react-redux';
import { PlayerState } from '../../../store/types/playerTypes';
import { getPlayerStateSelector } from '../../../store/selectors/playerSelectors';
import classes from './MobileTopNav.module.css';
import fglToken from '../../../assets/images/general/fgl-token.png';
import medalImage from '../../../assets/images/general/icons/icon_trophy.png';
import profileImage from '../../../assets/images/general/icons/profile.png';
import { getPlayerDataRequest } from '../../../store/actions/playerActions';
import {ReactComponent as RefreshIcon} from '../../../assets/svg/loop2.svg';
import { useHistory } from 'react-router';
import {ReactComponent as LogoutIcon} from '../../../assets/svg/exit.svg';
import { logout } from '../../../store/service/ualService';
import { setGameMode, setGeneralBusyMessage } from '../../../store/actions/gameActions';
import {ReactComponent as WeeklyMedalIcon} from '../../../assets/svg/medal.svg';
import { GameState } from '../../../store/types/gameTypes';
import { getGameStateSelector } from '../../../store/selectors/gameSelectors';


interface MobileTopNavProps {  
  drawerToggleClicked: Function;  
}

const MobileTopNav = ( { drawerToggleClicked }:MobileTopNavProps ) => { 
  const dispatch = useDispatch();
  const history = useHistory();

  const gameState:GameState = useSelector(getGameStateSelector);
  const playerState:PlayerState = useSelector(getPlayerStateSelector);

  let clicksEnabled:boolean = true;

  const refresh = () => {
    if (clicksEnabled){
      clicksEnabled=false;

      // Set a busy message
      dispatch(setGeneralBusyMessage("Synching data..."));

      dispatch(getPlayerDataRequest());
    }
  }

  // Log the user out
  const clickLogout = () => {    
    // Call the logout function
    logout(history);    
  }

  // Toggle the game mode
  const toggleGameMode = () => {    
    if (clicksEnabled){
      clicksEnabled=false;
      
      if (gameState.gameMode === 'seasonal') {
        dispatch(setGameMode('weekly'));
      } else {
        dispatch(setGameMode('seasonal'));
      }
    }
  }


  return (
    <div className={classes['top-nav']}>
      
      <div className={classes['player-block']}>
        {playerState.player && playerState.player.avatarId > 0 ?
          <img alt="Profile" src={'https://cdn.cosmicclash.io/avatars/avatar_' + playerState.player.avatarId + '.png'} className={classes['nav-icon-avatar']} />
        :
          <img alt="Profile" src={profileImage} className={classes['nav-icon']} />
        }
        <div className={classes['player-info']}>
          <div className={classes['player-name-box']}>
            <div className={classes['player-name']}>{playerState.player?.name}</div>
            <LogoutIcon onClick={() => clickLogout()} className={classes['logout-icon']} />
            {/*
           
              gameState.gameMode === 'seasonal' ?
                <div onClick={() => toggleGameMode()} className={classes['game-mode-button-gold']}>
                  {gameState.gameMode === 'seasonal' ? 'GOLD' : 'BRONZE' }
                </div>            
              :
                <div onClick={() => toggleGameMode()} className={classes['game-mode-button-bronze']}>
                  {gameState.gameMode === 'seasonal' ? 'GOLD' : 'BRONZE' }
                </div>            
            */}
          </div>
          <div className={classes['player-stats']}>
            <div className={classes['player-stats-box']}>            
              <img alt="FGL Tokens" src={fglToken} className={classes['token-image']} />                        
              {(playerState.player?.tokens)?.toFixed(0)}              
            </div>
            {gameState.gameMode === 'seasonal' ?
              <div className={classes['player-stats-box']}>                          
                <img src={medalImage} className={classes['medal-image']} />            
                {playerState.player?.medals}&nbsp;<span className={classes['rank-text']}>(#{playerState.player?.seasonalRank})</span>
              </div> 
            :
              <div className={classes['player-stats-box']}>                          
                <WeeklyMedalIcon className={classes['medal-icon']} />                
                {playerState.player?.weeklyMedals}&nbsp;<span className={classes['rank-text']}>(#{playerState.player?.weeklyRank})</span>
              </div> 
            }
            <div onClick={() => refresh()} className={classes['player-stats-box']}>                          
              <RefreshIcon className={classes['refresh-icon']} />
            </div> 
          </div>
        </div>
      </div>
      
      <div className={classes['toggle']}>
        <div className={classes['toggle-lines']} onClick={() => drawerToggleClicked()}>
          <div></div>
          <div></div>          
        </div>        
      </div>         
    </div>
  );
};

export default MobileTopNav;
