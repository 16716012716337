import classes from './SigningTransactionModal.module.css';
import spinnerImage from '../../../assets/images/general/loading-animation.png';
import { useHistory } from 'react-router';
import { setGeneralBusyMessage, setSigningTransaction } from '../../../store/actions/gameActions';
import { useDispatch } from 'react-redux';

interface GeneralBusyProps {
  message: string;
}

const SigningTransactionModal = ( { message }:GeneralBusyProps ) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const cancelClick = () => {
    // Clear this popup, go home
    dispatch(setSigningTransaction(""));
    dispatch(setGeneralBusyMessage(""));
    history.push("/");
  }

  return (
      <div className={classes['wrapper']}>
          <div className={classes['section']}>                
              <div className={classes['spritesheet-container']}>
                <img alt="Busy" className={classes['spritesheet']} src={spinnerImage}></img>
              </div>
              <div className={classes['description']}>{message}</div>
              <div className={classes['cancel-button']} onClick={() => cancelClick()}>Cancel</div>
          </div>  
      </div>                  
  );
};

export default SigningTransactionModal;