import {
  USER_LOGIN,  
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAILURE,
  USER_RESET_ERROR,
  CLEAR_WAX_ERROR,
} from "../actions/actionTypes";

import { WaxActions, WaxState } from "../types/waxTypes";

const initialState: WaxState = {
  user: null,
  loggedIn: false,
  pending: false,
  error: null,
  fatalError: null,
  errorCode: 0
};

export default (state = initialState, action: WaxActions) => {
  switch (action.type) {
    case USER_LOGIN:
      return {
        ...state,
        error: null,
        pending: true,
        loggedIn: false
      };
    case USER_LOGIN_SUCCESS:
      return {
        ...state,
        pending: false,
        user: {
          userAccount: action.payload.userAccount
        },
        error: null,
        loggedIn: true
      };
    case USER_LOGIN_FAILURE:
      return {
        ...state,
        pending: false,
        loggedIn: false,
        user: null,
        error: action.payload.error,
        fatalError: action.payload.fatalError,
        errorCode: action.payload.errorCode
      };
    case USER_RESET_ERROR:
      return {
        ...state,                
        error: null,
        errorCode: null
      };
    case CLEAR_WAX_ERROR:
      return {
        ...state,
        error: ""
      }; 
    default:
      return {
        ...state,
      };
  }
};